import React, { useState, useRef, useEffect } from 'react';

interface AudioPlayerProps {
    src: string;
    disabled?: boolean;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ src }) => {
    const [hideIcon, setHideIcon] = useState(false);

    const [audioCurrentMinutes, setAudioCurrentMinutes] = useState('00');
    const [audioCurrentSeconds, setAudioCurrentSeconds] = useState('00');
    const audioRef = useRef<HTMLAudioElement>(null);
    const trackLineRef = useRef<HTMLInputElement>(null);


    useEffect(() => {
        resetAudio();

    }, [src]);

    useEffect(() => {
        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
            }
        };
    }, []);

    const resetAudio = () => {
        if (audioRef.current) {
            audioRef.current.currentTime = 0;
            setAudioCurrentMinutes('00');
            setAudioCurrentSeconds('00');
            updateTimeline();
        }
    };

    const play = () => {
        if (audioRef.current) {
            if (audioRef.current.readyState < 4) return;
            audioRef.current.play();
            setHideIcon(true);
        }
    };

    const pause = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            setHideIcon(false);
        }
    };

    const currentProgress = () => {
        if (audioRef.current) {
            return (audioRef.current.currentTime / audioRef.current.duration) * 100;
        }
        return 0;
    }

    const updateTimeline = () => {
        if (audioRef.current && trackLineRef.current) {
            trackLineRef.current.max = audioRef.current.duration.toString();
            trackLineRef.current.value = audioRef.current.currentTime.toString();
            setAudioCurrentMinutes(formatMinutes(audioRef.current.currentTime));
            setAudioCurrentSeconds(formatSeconds(audioRef.current.currentTime));
        }
    };

    const updateAudio = () => {
        if (audioRef.current && trackLineRef.current) {
            updateTimeline();
        }
    };


    const formatMinutes = (timeLength: number): string => {
        if (!timeLength) return '00';
        const minutes = Math.floor(timeLength / 60);
        return minutes >= 10 ? minutes.toString() : '0' + minutes;
    };

    const formatSeconds = (timeLength: number): string => {
        if (!timeLength) return '00';
        const seconds = Math.floor(timeLength % 60);
        return seconds >= 10 ? seconds.toString() : '0' + seconds;
    };

    const endAudio = () => {
        pause();
        resetAudio();
    };

    const handleTimelineChange = () => {
        if (audioRef.current && trackLineRef.current) {
            audioRef.current.currentTime = parseFloat(trackLineRef.current.value);
            updateTimeline();
        }
    };

    const formatAudioDuration = () => {
        if(audioRef.current) {
            const minutes = formatMinutes(audioRef.current?.duration);
            const seconds = formatSeconds(audioRef.current?.duration);
            return minutes + ':' + seconds;
        }
    }


    return (
        src &&
        <div onClick={event => event.stopPropagation()} className={`player-wrapper p-3 bg-white `}>
            <div className="player-content text-gray-900 flex gap-3 ">
                <div className="controls rounded-full  bg-primary-400 min-h-8 min-w-8 flex justify-center items-center " >

                    <i onClick={pause} className={` material-icons  player-icon cursor-pointer text-white ${!hideIcon ? 'hidden' : ''}`}>pause</i>
                    <i onClick={play} className={`material-icons  player-icon cursor-pointer text-white ${hideIcon ? 'hidden' : ''}`}>play_arrow</i>

                </div>
                <div className="timeline w-full">

                    <input
                        ref={trackLineRef}
                        type="range"
                        min="0"
                        max="100"
                        step="0.000001"
                        className="timeline-input w-full h-1 bg-primary-400 appearance-none"
                        onChange={handleTimelineChange}
                        style={{
                            background: `linear-gradient(to right, #6133EA ${currentProgress()}%, #DFE0E2 ${currentProgress()}%)`
                        }}
                    />
                </div>
                <div className="controls-wrapper flex justify-between items-center ">

                    <div className="time text-gray-600">
                        {Boolean(audioCurrentSeconds === '00') &&
                        <span>{formatAudioDuration()}</span>
                         }
                         {Boolean(audioCurrentSeconds !== '00') &&
                        <span>{audioCurrentMinutes}:{audioCurrentSeconds}</span>

                         }
                    </div>
                </div>
            </div>
            <audio ref={audioRef} src={src} onTimeUpdate={updateAudio} onEnded={endAudio} className="hidden" />
        </div>
    );
};

export default AudioPlayer;

import {useEffect, useRef} from "react";
import {throttle} from "lodash";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import {selectTheme} from "../../store/features/ThemeSwitcher/themeSwitcherSlice";
import {useSelector} from "react-redux";
import {useState} from "react";
import CallDetails from "./CallDetails";
import OngoingCalls from "./onGoingCalls.tsx";
import {Call, useFetchOngoingCallQuery} from "../../store/features/calls/callsService";
import {selectUserId} from "../../store/features/Auth/authSlice";
import CallRow from "./CallRow";
import listEmpty from "../../assets/list-empty.svg";


interface RecentCallsProps {
    groupedCalls: { [key: string]: Call[] };
    reachedEnd: Function;
    recentCallsCount: number;

}

const RecentCalls = ({groupedCalls, reachedEnd}: RecentCallsProps) => {

    const [selectedCall, setSelectedCall] = useState<Call | undefined>(undefined);
    const [initialized, setInitialized] = useState(false);

    const userId = useSelector(selectUserId);

    const {
        data: ongoingCalls = [],

    } = useFetchOngoingCallQuery(userId, {pollingInterval: 5000});

    const handleCallClick = (call: any) => {
        setSelectedCall(call);
    };

    const isMobile = useMediaQuery('(max-width: 960px)');

    const theme = useSelector(selectTheme);
    const listContainerRef = useRef<HTMLDivElement | null>(null);
    if (!groupedCalls) return null;
    const sortedGroups = Object.keys(groupedCalls).sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

    useEffect(() => {
        if (!initialized && groupedCalls && sortedGroups.length > 0) {
            const firstCall = groupedCalls[sortedGroups[0]]?.[0];
            if (firstCall) {
                setSelectedCall(firstCall);
                setInitialized(true);
            }
        }
    }, [groupedCalls, sortedGroups, initialized]);

    const reachedEndThrottled = throttle(() => {
        reachedEnd();
    }, 2000);

    const handleScroll = (e: any) => {
        const bottom = (e.target.scrollHeight - (e.target.scrollTop + 5)) <= e.target.clientHeight;
        if (bottom) {

            reachedEndThrottled();
        }
    };

    useEffect(() => {
        const listContainer = listContainerRef.current;
        if (listContainer) {
            listContainer.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (listContainer) {
                listContainer.removeEventListener('scroll', handleScroll);
            }
        };

    }, []);

    return (
<>
        <div  className={`flex border-t border-black h-full w-full`}>
            <div className={'w-full overflow-y-auto  h-[calc(100vh-63px-var(--header-height))]'}  ref={listContainerRef}>

            <OngoingCalls calls={ongoingCalls} isMobile={isMobile}/>
            {sortedGroups.map(date => (
                <div className=' overflow-y-auto' key={date}>
                    {groupedCalls[date].map((call: Call) => (
                        <>

                            <div>
                                <CallRow call={call} isMobile={isMobile} handleCallClick={handleCallClick}
                                         selectedCall={selectedCall}/>

                            </div>

                        </>

                    ))}

                </div>

            ))}
            {sortedGroups.length === 0 &&
                    <div className='flex text-center  justify-center '>
                    <div className='w-[450px]  m-14'>
                        <div className='flex  justify-center w-full'>
                            <img className='m-4' src={listEmpty}/>
                        </div>
                        <h1 className='font-bold'>
                            No calls yet!
                        </h1>
                        <div className='mb-6 mt-2'>
                            Start making calls and they'll show up here.
                        </div>
                    </div>
                </div>


                }

            </div>

            

            <div >
                {selectedCall &&
                    <CallDetails selectedCall={selectedCall} theme={theme}/>

                }
            </div>
        </div>

    </>
    );
};


export default RecentCalls;

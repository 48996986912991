import { createApi } from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../../baseQuery";
import {Msisdn} from "../Msisdns/msisdnService";


interface MsisdnSettingsInfo {
    msisdn: Msisdn;
    users: any[];
    groups: any[];
    emailsSetting: {
        enabled: boolean;
        email: string | null;
    };
    callRecordingEnabled: boolean;
    assigmentScope?: string;
}

export interface WorkingHoursInterface {
    type: string;
    enabled: boolean;
    timeZone: any;
    workingHours: Array<{
        day: string;
        from: string;
        to: string;
        enabled: boolean;
    }>;
}
export interface CallRulesDTO {
    msisdnId?: string;
    workingForwardEnabled: boolean;
    workingForwardMsisdn: number | null;
    workingWelcomeMessageEnabled: boolean;
    nonWorkingForwardEnabled: boolean;
    nonWorkingForwardMsisdn: number | null;
    nonWorkingDontAcceptCalls: boolean;
    nonWorkingAfterHoursMessageEnabled: boolean;
    nonWorkingAfterHoursMessageOriginalName: string | null | undefined;
    nonWorkingAfterHoursMessageBucketName: string | null | undefined
    nonWorkingWelcomeMessageBucketName: string | null | undefined,
    workingWelcomeMessageOriginalName: string | null | undefined,
    workingWelcomeMessageBucketName: string | null | undefined,
    queueEnabled: boolean;
    queueId: number | null;
}
export interface CallRules {
    msisdnId?: string;
    workingWelcomeMessageUrl?: string;
    workingWelcomeMessageEnabled: boolean;
    workingForwardEnabled?: boolean;
    workingForwardMsisdn?: number | null;
    nonWorkingWelcomeMessageUrl?: string;
    nonWorkingDontAcceptCalls: boolean;
    nonWorkingForwardEnabled?: boolean;
    nonWorkingForwardMsisdn?: number | null;
    nonWorkingAfterHoursMessageEnabled: boolean;
    nonWorkingAfterHoursMessageUrl?: string;
    nonWorkingAfterHoursMessageOriginalName: string | null | undefined;
    nonWorkingAfterHoursMessageBucketName: string | null | undefined;
    nonWorkingWelcomeMessageBucketName: string | null | undefined;
    workingWelcomeMessageOriginalName: string | null | undefined;
    workingWelcomeMessageBucketName: string | null | undefined;
    queueEnabled: boolean;
    queueId: number | null;
}

export interface AudioFilesURL {
    workingWelcomeMessageUrl: string | undefined;
    nonWorkingWelcomeMessageUrl: string | undefined;

}


export const msisdnSettingsApi = createApi({
    reducerPath: 'msisdnSettingsApi',
    baseQuery,
    tagTypes: ['MsisdnSettingsInfo', 'MsisdnWorkingHours', 'MsisdnCallRules'],
    endpoints: (builder) => ({
        fetchMsisdns: builder.query<{ data: Msisdn[]; totalCount: number }, { phrase: string | null, userId: string | null }>({
            query: ({phrase, userId}) => ({
                url: `/msisdn/user/${userId}`,
                params: {phrase},
            }),
            transformResponse: (response: Msisdn[], meta) => {
                const totalCount = meta?.response?.headers.get('x-total-count');
                return {
                    data: response,
                    totalCount: totalCount ? parseInt(totalCount, 10) : 0
                };
            },
            providesTags: ['MsisdnSettingsInfo'],
        }),
        fetchMsisdnSettingsInfo: builder.query<MsisdnSettingsInfo, string>({
            query: (id) => `/msisdn/settings/info/${id}`,
            providesTags: (id) => [{type: 'MsisdnSettingsInfo', msisdnId: id}],
        }),

        fetchMsisdnWorkingHours: builder.query<WorkingHoursInterface, string>({
            query: (id) => `/msisdn/settings/wh/${id}`,
            providesTags: (id) => [{type: 'MsisdnWorkingHours', msisdnId: id}],
        }),

        fetchCallRules: builder.query<CallRules, string>({
            query: (id) => `/msisdn/settings/cr/${id}`,
            providesTags: (id) => [{type: 'MsisdnCallRules', msisdnId: id}],
        }),

        updateWorkingHours: builder.mutation<void, { id: string; data: WorkingHoursInterface }>({
            query: ({id, data}) => ({
                url: `/msisdn/settings/wh/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: (_, __, {id}) => [{type: 'MsisdnWorkingHours', id}],
            async onQueryStarted({id, data}, {dispatch, queryFulfilled}) {
                let patchResult; // Declare outside
                try {
                    patchResult = dispatch(
                        msisdnSettingsApi.util.updateQueryData('fetchMsisdnWorkingHours', id, (draft) => {
                            Object.assign(draft, data);
                        })
                    );

                    await queryFulfilled;
                } catch {
                    if (patchResult) {
                        patchResult.undo();
                    }
                }
            },
        }),

        updateCallRules: builder.mutation<CallRules, { id: string; data: CallRules }>({
            query: ({id, data}) => ({
                url: `/msisdn/settings/cr/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: (_, __, {id}) => [{type: 'MsisdnCallRules', id}],
            async onQueryStarted({id, data}, {dispatch, queryFulfilled}) {
                let patchResult; // Declare outside
                try {
                    patchResult = dispatch(
                        msisdnSettingsApi.util.updateQueryData('fetchCallRules', id, (draft) => {
                            Object.assign(draft, data);
                        })
                    );

                    await queryFulfilled;
                } catch {
                    if (patchResult) {
                        patchResult.undo();
                    }
                }
            },
        }),

        updateMsisdnSettingsInfo: builder.mutation<MsisdnSettingsInfo, { id: string; data: MsisdnSettingsInfo }>(
            {
                query: ({id, data}) => ({
                    url: `/msisdn/settings/info/${id}`,
                    method: 'PUT',
                    body: data,
                }),
                invalidatesTags: (_, __, {id}) => [
                    {type: 'MsisdnSettingsInfo', id},
                    {type: 'MsisdnSettingsInfo'},
                ],
                async onQueryStarted({id, data}, {dispatch, queryFulfilled}) {
                    let patchResult; // Declare outside
                    try {
                        patchResult = dispatch(
                            msisdnSettingsApi.util.updateQueryData('fetchMsisdnSettingsInfo', id, (draft) => {
                                Object.assign(draft, data);
                            })
                        );

                        await queryFulfilled;
                    } catch {
                        if (patchResult) {
                            patchResult.undo();
                        }
                    }
                },
            }),

        postRecordingPreview: builder.mutation<any, { id: string; data: FormData; audioURL: string }>({
            query: ({id, data}) => ({
                url: `/msisdn/settings/cr/${id}/message`,
                method: 'PUT',
                body: data,
            }),
            transformResponse: (response: any, _meta: any, arg: { id: string; data: FormData; audioURL: string }) => {
                return {
                    ...response,
                    audioURL: arg.audioURL,
                };
            },
        }),


        getRecording: builder.query<{ data: string; type: string }, { id: string; type: string }>({
            query: ({ id, type }) => ({
                url: `/msisdn/settings/cr/${id}/message`,
                params: { type },
                responseHandler: async (response: any) => {
                    const arrayBuffer = await response.arrayBuffer();
                    return {
                        buffer: arrayBuffer,
                        headers: response.headers,
                    };
                },
            }),
            transformResponse: (response: { buffer: ArrayBuffer; headers: Headers }) => {
                const contentType = response.headers.get('content-type') || 'audio/mpeg';
                const blob = new Blob([response.buffer], { type: contentType });
                const audioUrl = URL.createObjectURL(blob);

                return {
                    data: audioUrl,
                    type: contentType,
                };
            },
            keepUnusedDataFor: 0,
        }),
        synthetizeText: builder.mutation<any, { text: string, gender: string, language: string }>({
            query: ({ text, gender, language }) => ({
                url: `/api/v1/tts-speech`,
                method: 'POST',
                body: { text, gender, language },
                responseHandler: async (response: any) => {
                    const arrayBuffer = await response.arrayBuffer();
                    return {
                        buffer: arrayBuffer,
                        headers: response.headers,
                    };
                },
            }),
            transformResponse: (response: { buffer: ArrayBuffer; headers: Headers }) => {
                const contentType = response.headers.get('content-type') || 'audio/mpeg';
                const blob = new Blob([response.buffer], {type: contentType});
                const audioUrl = URL.createObjectURL(blob);

                return {
                    data: audioUrl,
                    type: contentType,
                };
            },
        }),
        uploadSynthetizeText: builder.mutation<any, { text: string, gender: string, language: string }>({
            query: ({text, gender, language}) => ({
                url: `/api/v1/msisdn-settings/1/cr-tts-message`,
                method: 'PUT',
                body: {text, gender, language},
            }),
        }),
        fetchMsisdnsAdmin: builder.query<{ data: Msisdn[]; totalCount: number }, { phrase:string | null}>({
            query: ({phrase}) => ({
                url: `/api/admin/v1/phone-numbers`,
                params: { phrase },
            }),
            transformResponse: (response: Msisdn[], meta) => {
                const totalCount = meta?.response?.headers.get('x-total-count');
                return {
                    data: response,
                    totalCount: totalCount ? parseInt(totalCount, 10) : 0
                };
            },
            providesTags: ['MsisdnSettingsInfo'],
        }),
    }),
});

export const {
    useFetchMsisdnsQuery,
    useFetchMsisdnSettingsInfoQuery,
    useFetchMsisdnWorkingHoursQuery,
    useFetchCallRulesQuery,
    useUpdateWorkingHoursMutation,
    useUpdateCallRulesMutation,
    useUpdateMsisdnSettingsInfoMutation,
    usePostRecordingPreviewMutation,
    useSynthetizeTextMutation,
    useUploadSynthetizeTextMutation,
    useGetRecordingQuery,
    useFetchMsisdnsAdminQuery,
} = msisdnSettingsApi;

import {Avatar, Button, ChInput, ChTooltip, Icon} from "ch3-ui-lib";
import {Link} from "react-router-dom";
import { formatDateDayMonth, groupCallsByDate} from "../../utilis/timeUtilis";
import {useState} from "react";
import RecentCallsDetails from "./RecentCallDetails";
import {FlagImage} from "react-international-phone";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {Colors} from "../../utilis/types/Types";
import { parsePhoneNumber, PhoneNumber} from 'libphonenumber-js';
import {ClickToCall} from "../../components/ClickToCall/ClickToCall";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import {useGetContactByIdQuery} from "../../store/features/Contacts/contactsService";
import {useFetchContactHistoryQuery} from "../../store/features/calls/callsService";
import {User} from "../../store/features/Users/userService";
import {useIntl} from "react-intl";
import { borderStyles } from "./helpers.tsx";
import { clsxMerge } from "../../utilis/classNameUtils.ts";
import {Group} from "../../store/features/groups/groupsService";
import pipeDriveLogo from "../../assets/pipedrive-logo.svg";


const ContactDetails = () => {
    const { id } = useParams<{ id: string }>();
    const intl = useIntl();
    const isMobile = useMediaQuery('(max-width: 960px)');
    const theme = useSelector((state: any) =>state.themeSwitcher.theme);

    const [filter, setFilter] = useState('');

    const { data: contactData, isLoading: contactLoading } = useGetContactByIdQuery(Number(id));
    const { data: callsData = [], isLoading: callsLoading } = useFetchContactHistoryQuery({contactId: Number(id), phrase: filter});


    const phoneNumbers = contactData?.phoneNumbers?.map((phoneNumber: { msisdn: number, main: boolean }) =>
        parsePhoneNumber(`+${phoneNumber.msisdn.toString()}`));

    type ContactTypeKey = keyof typeof ContactType;
    enum ContactType {
        PRIVATE = 'Private',
        PUBLIC = 'Public',
        SHARED = 'Shared'
    }



    const groupedCalls = groupCallsByDate(callsData, intl);


    if (contactLoading || callsLoading) {
        return <div>Loading...</div>;
    }

    return (
         contactData &&
        <>
        <div className={`w-full h-[calc(100vh-63px-var(--header-height))] ` }>

            <div className={'h-[100vh]'}>
            <div className={`flex h-full ` + `${isMobile ? ' px-4 flex-col' : 'flex-row'}`}>
                <div className=" w-[50%] min-w-[50%] flex-col justify-start items-center inline-flex ">
                    <div className=" w-full flex-col justify-start items-center flex p-8 pb-6 border-b border-grey-75 relative">
                        <Avatar className='mb-4' size={"xxl"} type='initials' color={contactData?.iconColor ?? Colors.auto} name={`${contactData?.firstName + " " + contactData?.lastName}`}/>
                        {contactData.contactType.type === 'EXTERNAL'  &&
                            <div className="absolute inset-y-1/2 ml-10"><img className='ml-2' src={pipeDriveLogo}/></div>
                        }
                        <div className="flex-col justify-start items-center gap-2 flex">
                            <div
                                className=" text-center  text-xl  leading-7">{`${contactData?.firstName + " " + contactData?.lastName}`}</div>
                        </div>
                    </div>
                    <div className="self-stretch flex-col justify-start items-start flex p-6 w-full">
                        <div className="flex w-full pb-5 border-b border-grey-75 items-center">
                        <div className=" text-base shrink-0 text-sm text-grey-700  leading-normal w-[150px]">Shared for</div>
                        <div className="  flex items-center justify-start font-semibold">

                                                        {Boolean(contactData?.availability === 'PRIVATE') &&
                            <div className="text-sm ">You</div>
                            }
                            {Boolean(contactData?.availability === 'PUBLIC') &&
                            <div className="text-sm ">Everyone</div>
                            }

                            {Boolean(contactData?.availability === 'SHARED') &&
                            <div className="justify-start flex">

                            {[...contactData?.sharedForUsers ?? [], ...contactData?.sharedForGroups ?? []].slice(0,8).map((item, index) => {

                                const isUser = (item as User).firstName !== undefined;
                                const isGroup = (item as Group).name !== undefined;

                                return (
                                    <ChTooltip
                                        key={index}
                                        alignArrow='start'
                                        trigger={
                                            <div

                                                style={{ right: `${index * 20}px` }}

                                                className="p-[2px] bg-white relative"
                                            >
                                                <Avatar
                                                    size="md"
                                                    type={isUser ? 'initials' : 'icon'}
                                                    name={isUser ? `${(item as User).firstName} ${(item as User).lastName}` : (isGroup ? (item as Group).name : '')}
                                                    icon={!isUser ? 'home' : undefined}
                                                    color={item.iconColor ?? Colors.auto}
                                                />
                                            </div>
                                        }
                                        content={
                                            isUser
                                                ? `${(item as User).firstName} ${(item as User).lastName}`
                                                : isGroup
                                                    ? (item as Group).name
                                                    : 'Unknown'
                                        }
                                        theme='dark'
                                    />
                                );
                            })}

                        {
                            [...contactData?.sharedForUsers ?? [], ...contactData?.sharedForGroups ?? []].length > 8 &&

                            <div
                            style={{right: `170px`}}
                            className={`p-[2px] bg-white relative `}
                        >
                            <Avatar
                                color={Colors.auto}
                                size="md"
                                type='initials'
                                name={`+ ${[...contactData?.sharedForUsers ?? [], ...contactData?.sharedForGroups ?? []].length - 8}`}

                            />
                        </div>

                            
                        }           
                            </div>
                            }
                            </div>
                            </div>
                        

                        
                        <div
                            className={` py-5 flex items-center border-b border-grey-75 w-full`}>
                            <div className=" text-base  text-sm text-grey-700  leading-normal w-[150px] leading-normal">Phone numbers</div>
                            {phoneNumbers?.map((phoneNumber: PhoneNumber, index: number) => (
                                <div key={index} className="justify-start items-center  inline-flex font-semibold">
                                    <FlagImage iso2={phoneNumber.country?.toLowerCase() ?? 'us'} className='w-4 h-3 mr-2'/>
                                    <ClickToCall className="text-sm" noPrefix={true} msisdn={Number(phoneNumber.number)} icon={true} />

                                </div>))
                            }
                        </div>

                        <div
                            className={` border-b border-grey-75 py-5 flex items-center w-full`}>
                            <div className=" text-base text-sm text-grey-700  w-[150px]  leading-normal">Email</div>
                            <div className="text-sm font-semibold leading-tight flex items-center">{contactData?.email ?? '---'} 
                                {Boolean(contactData?.email) &&
                                <Icon iconName="mail_outline"></Icon>}
                                </div>
                        </div>

                        <div
                            className={`border-b border-grey-75 py-5 flex w-full`}>
                            <div className=" text-base text-sm text-grey-700  w-[150px]  leading-normal">Company</div>
                            <div className=" text-sm font-semibold leading-tight">{contactData?.company ?? '---'}</div>
                        </div>

                        <div
                            className={` border-b border-grey-75 py-5 flex w-full mb-5`}>
                            <div className=" text-base text-sm text-grey-700  w-[150px] leading-normal">Contact availability
                            </div>
                            <div className=" text-sm font-semibold leading-tight">{ContactType[contactData?.availability as ContactTypeKey]}</div>
                        </div>
                        {contactData.contactType.type === 'INTERNAL' &&
                        <Link to={`../contacts/${id}`}> <Button  className='mb-4 p-0' onClick={() => console.log('test')} size={'small'} 
                                                        buttonType='textPrimary' label={'Edit Contact'}/></Link>
                        }
                        {contactData.contactType.type === 'EXTERNAL'  &&
                            <div className="flex text-grey-700 text-xs mb-1 items-center">External Contact
                                <div><img className='ml-2' src={pipeDriveLogo}/></div>
                            </div>
                        }
                        {contactData.contactType.type === 'INTERNAL' &&
                        <div

                            className= {`self-stretch flex-col justify-start items-start flex ` + clsxMerge(borderStyles({theme}))}>
                            <div className={`text-xs  text-grey-700` }>Created by {' ' + contactData?.owner.firstName + ' ' + contactData?.owner.lastName }, {' ' +formatDateDayMonth(new Date(contactData?.createdAt))}

                            </div>
                        </div>
                        }
                        {contactData.contactType.type === 'EXTERNAL' &&
                        <div

                            className= {`self-stretch flex-col justify-start items-start flex mb-4 ` + clsxMerge(borderStyles({theme}))}>
                            <div className={`text-xs  text-grey-700` }>Imported on {' ' +formatDateDayMonth(new Date(contactData?.createdAt))}

                            </div>
                        </div>
                        }
                        {contactData.contactType.type === 'EXTERNAL' &&
                        <Link to={contactData.url} target="_blank"> <Button  className='mb-4 p-0' onClick={() => console.log('test')} size={'small'} 
                                                        buttonType='textPrimary' rightIcon={'launch'} label={'Edit in source system'}/></Link>
                        }
                    </div>
                </div>
                <div className={` border-l w-[50%] overflow-y-auto  flex-col justify-start items-center inline-flex  w-full ` + clsxMerge(borderStyles({theme}))}>

                    <div className='min-w-full  justify-start'>
                        <ChInput value={filter} className=' min-w-full  py-2.5 px-6 !border-none' clearButton={true}  prefixIcon={'search'}
                                 callback={(value: string) => setFilter(value)} placeholder='Search'/>

                    </div>
                    {groupedCalls ? <RecentCallsDetails groupedCalls={groupedCalls}/> : <div>loading...</div>}
                </div>
            </div>
            </div>

        </div>
        </>
    );
}


export default ContactDetails;

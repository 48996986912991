import {Avatar, Button, ChSearch} from "ch3-ui-lib";
import {Colors, selectorItem} from "../../utilis/types/Types";
import {useCallback, useState} from "react";
import {debounce} from "lodash";
import { User, useSearchUsersQuery} from "../../store/features/Users/userService";
import {
    FetchGroupsResponse,
    Group,
    useFetchGroupsQuery
} from "../../store/features/groups/groupsService";
import {useSelector} from "react-redux";
import {selectUserId} from "../../store/features/Auth/authSlice";
import {useLocation} from "react-router-dom";

interface AddContactModalProps {
    setUsers: Function;
    setGroups: Function;
    selectedUsers: User[];
    selectedGroups: Group[];
}
const AddContactModal = (({ selectedUsers, selectedGroups, setUsers, setGroups}:AddContactModalProps) => {


    const [phrase, setPhrase] = useState('');

    const userId = useSelector(selectUserId)
    const { data: users } = useSearchUsersQuery(phrase);
    const { data: groupsData } = useFetchGroupsQuery({phrase: phrase, userId});

    console.log(users,'users')
    const groups = (groupsData?.groups)?.map((group: FetchGroupsResponse) => ({
        groupId: group.group?.groupId,
        iconColor: group?.group.iconColor,
        name: group?.group.name,
    })) as Group[];




    const [selectedUserList, setSelectedUserList] = useState(selectedUsers);
    const [selectedGroupList, setSelectedGroupList] = useState(selectedGroups);

    const filteredUsers = users?.filter(user => !selectedUserList.find(selectedUser => selectedUser.userId === user.userId));
    const filteredGroups = groups?.filter(group => !selectedGroupList.find(selectedGroup => selectedGroup.groupId === group.groupId));


    const userItems = filteredUsers?.map((user: User) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.userId.toString(),
        key: `user${user.userId}`,
        prefixElement: (
            <div className='ml-2'>
                <Avatar size={'sm'} type={'initials'} color={user.iconColor ?? Colors.auto} name={`${user.firstName} ${user.lastName}`} />
            </div>
        ),
    })) || [];

    const groupItems = filteredGroups?.map((group: Group) => ({
        label: group.name,
        value: group.groupId?.toString(),
        key: `group${group.groupId}`,
        prefixElement: (
            <div className='ml-2'>
                <Avatar size={'sm'} icon={'home'} type={'icon'} color={Colors.orange} name={group.name} />
            </div>
        ),
    })) || [];
    let list = [...userItems, ...groupItems] as selectorItem[];

    const handlePhraseChange = useCallback(
        debounce((phrase: string) => {
            setPhrase(phrase);
        }, 500),
        []
    );


    const handleChange = (item: selectorItem) => {

       list = list.filter((i) => i.value !== item.value);
        if(item.key.includes('user')) {
            setSelectedUserList((prevUsers: User[]) => [...prevUsers, users?.find((user: User) => user.userId == Number(item.value)) as User]);
                setUsers([...selectedUserList, users?.find((user: User) => user.userId == Number(item.value)) ] );

        } else {
           setSelectedGroupList((prevGroups: Group[]) => {
                return [...prevGroups, groups.find((group: Group ) => group.groupId == Number(item.value)) as Group]
           });
           setGroups([...selectedGroupList, groups.find((group: Group ) => group.groupId == Number(item.value))] );
        }
        list = list.filter((i) => i.value !== item.value);

    };

    const handleRemove = (indexToRemove: number, type: string) => {
        if (type === 'user') {
            setSelectedUserList((prevUsers: User[]) => prevUsers.filter((_, index: number) => index !== indexToRemove));

            const user = selectedUserList[indexToRemove];
            list  =[...list, {
                label: user.firstName + ' ' + user.lastName,
                value: user.userId,
                key:`user${user.userId}`,
                prefixElement: <div className='ml-2'><Avatar size={'sm'} type={'initials'} color={user.iconColor ?? Colors.auto} name={user.firstName + ' ' + user.lastName}/></div>}] as selectorItem[];
            setUsers((prevUsers: User[]) => prevUsers.filter((_, index: number) => index !== indexToRemove));
        } else if (type === 'group') {
            const group = selectedGroupList[indexToRemove];
            list  = [...list, {
                label: group.name,
                value: group.groupId,
                key:`group${group.groupId}`,
                prefixElement: <div className='ml-2'><Avatar size={'sm'} icon={'home'} type={'icon'} color={Colors.orange} name={group.name}/></div>}] as selectorItem[];
            setSelectedGroupList((prevGroups: Group[]) => prevGroups.filter((_, index: number) => index !== indexToRemove));
            setGroups((prevGroups: Group[]) => prevGroups.filter((_, index: number) => index !== indexToRemove));

        }
    };


    const location = useLocation();
    const isContactsPath = location.pathname.includes('contacts');

    return (
        <div className="w-[400px]">
            <div className='text-grey-500 text-sm pb-5'>{isContactsPath? 'The following users and groups have access to this contact' : 'The following users and groups have access to this phone number'}
            </div>
            <div>
                <ChSearch onInputChange={handlePhraseChange} onChange={(item) => handleChange(item)} type={'text'} disabled={false} value={''} fit
                          label='Add users or group' prefixIcon='search' placeholder='search' items={list}></ChSearch>
            </div>
            <div className='grid font-semibold gap-3 mb-8'>
                {Boolean(selectedUserList.length) && <>
                    <div className={'p-4 text-sm'}>
                        USERS
                    </div>
                    <div className="max-h-36 overflow-y-auto">
                        {selectedUserList?.map((user, index:number) => (
                            <div className='flex justify-between items-center' key={index}>
                                <div className={'flex gap-3 items-center'}>
                                    <Avatar size={'sm'} type={'initials'} color={user.iconColor ?? Colors.auto} name={user.firstName + ' ' + user.lastName}/>
                                    <div>
                                        {user.firstName + ' ' + user.lastName}
                                    </div>
                                </div>
                                <Button label={'Remove'} buttonType={'textError'}
                                        onClick={() => handleRemove(index, 'user')}/>
                            </div>
                        ))}
                    </div>
                </>
                }
                {Boolean(selectedGroupList?.length) &&
                    <>
                        <div className={'p-4 text-sm'}>
                            GROUPS
                        </div>
                        <div className="max-h-36 overflow-y-auto">
                            {selectedGroupList?.map((group, index:number) => (
                                <div className='flex justify-between items-center ' key={index}>

                                    <div className={'flex gap-3 items-center'}>
                                        <Avatar size={'sm'} type={'icon'} color={group.iconColor  ?? Colors.orange} icon={'home'}/>
                                        <div className="w-[250px] truncate">
                                            {group.name }
                                        </div>
                                    </div>
                                    <Button label={'Remove'} buttonType={'textError'}
                                            onClick={() => handleRemove(index, 'group')}/>

                                </div>
                            ))}
                        </div>
                    </>
                }
            </div>
        </div>
    )
});
export default AddContactModal;

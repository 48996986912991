import {Link} from "react-router-dom";
import {DateFormatter, duration} from "../../utilis/timeUtilis";
import {FlagImage} from "react-international-phone";
import {getFlag, getFormatedPhoneNumber, regionNames, getCountryCode} from "../../utilis/msisdnUtilis";
import {Avatar, Button} from "ch3-ui-lib"
import {Colors} from "../../utilis/types/Types";
import {getCallDescription, getCallType} from "../../utilis/callsUtilis";
import {ClickToCall} from "../../components/ClickToCall/ClickToCall";
import {useFetchCallRecordQuery, Call} from "../../store/features/calls/callsService";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";
import {clsxMerge} from "../../utilis/classNameUtils.ts";
import {borderStyles, textStyles, boxStyles} from "./helpers.tsx";
import {useEffect, useState} from "react";

interface CallDetailsProps {
    selectedCall: Call,
    theme: any,

}

const CallDetails = ({selectedCall, theme}: CallDetailsProps) => {

    const [recordingUrl, setRecordingUrl] = useState<string | null>(null);
    const { data: fetchedRecordingUrl } = useFetchCallRecordQuery(Number(selectedCall.recordingId), {skip: !selectedCall.recordingId});

    useEffect(() => {
        if(!selectedCall.recordingId) {
            setRecordingUrl(null);
            return;
        }
        if (fetchedRecordingUrl) {
            setRecordingUrl(fetchedRecordingUrl);
        }
    }, [selectedCall, fetchedRecordingUrl]);


    return (



        <div className={` w-[360px] h-full border-l border-black bg-white overflow-hidden`}>
            <div className={`flex justify-center p-8 w-full text-sm font-semibold leading-5 border-b border-grey-75 max-md:flex-wrap max-md:max-w-full ${theme === 'dark' ? ' border-white' : ' border-black'}`}>
                <div className={' '}>
                    {selectedCall.primaryContact && !selectedCall.internalCallee &&
                            <Link to={`../contacts/details/${selectedCall.primaryContact.contactId}`}>
                            <div className='flex   flex-col justify-center items-center hover:cursor-pointer'>


                            <Avatar className='mb-4' size="xl" type='initials' color={Colors.grey}
                                    name={selectedCall.primaryContact.firstName + ' ' + selectedCall.primaryContact.lastName}/>
                            <div
                                className="text-xl font-normal mb-1">{selectedCall.primaryContact.firstName + ' ' + selectedCall.primaryContact.lastName}</div>


                            <div className={`flex  flex-col justify-center items-center`}>
                                {selectedCall?.clientMsisdn && <>
                                    <div className="flex items-center mb-6">
                                        <FlagImage className='w-3 h-3'
                                                   iso2={getFlag(selectedCall.clientMsisdn) ?? 'us'}/>
                                        <div
                                            className="text-md font-normal ml-2 text-gray-700">{getFormatedPhoneNumber(selectedCall.clientMsisdn, true)}</div>
                                    </div>
                                    <ClickToCall noPrefix={true} className="rounded border border-black" label=" " icon
                                                 msisdn={selectedCall.clientMsisdn}/> </>}

                            </div>
                            </div>
                            </Link>
                        }
                    {selectedCall.internalCallee && <div>

                        <div className='flex   flex-col justify-center items-center'>
                            <Avatar className='mb-4' size="xl" type='initials' color={Colors.grey}
                                    name={selectedCall.internalCallee.firstName + ' ' + selectedCall.internalCallee.lastName}/>
                            <div
                                className="text-xl font-normal mb-6">{selectedCall.internalCallee.firstName + ' ' + selectedCall.internalCallee.lastName}</div>
                            <ClickToCall internal={true} noPrefix={true} className="rounded border border-black"
                                         label=" " icon msisdn={selectedCall.internalCallee.userId}/>

                        </div>


                    </div>}

                    {!selectedCall.primaryContact && !selectedCall.internalCallee &&

                        <div className='flex   flex-col justify-center items-center'>
                            <Avatar className='mb-4' icon={'warning'} size="xl" type={ selectedCall.clientMsisdn ? 'default' : 'icon'} color={Colors.grey}/>
                            <div className="flex mb-2 items-center">
                                <div
                                    className="text-xl font-normal ml-2"> { selectedCall?.clientMsisdn ? getFormatedPhoneNumber(selectedCall.clientMsisdn, true) : 'Unknown'}</div>


                            </div>
                            {selectedCall?.clientMsisdn &&  <div className="flex items-center mb-6">
                               <FlagImage className='!w-3'
                                            iso2={getFlag(selectedCall.clientMsisdn) ?? 'us'}/>
                            <div className="text-xs font-normal ml-1 text-grey-500">  {regionNames.of(getCountryCode(Number(selectedCall.clientMsisdn)) || '') || 'Unknown Region'}</div>

                            </div>}
                            {selectedCall.clientMsisdn && <ClickToCall noPrefix={true} className="rounded border border-black" label=" " icon
                                          msisdn={selectedCall.clientMsisdn}/>}


                        </div>
                    }

                </div>

                <>

                </>

            </div>

            <div className=''>
                <div
                    className={`flex flex-col self-stretch m-6  ` + clsxMerge(borderStyles({theme}), boxStyles({theme}))}>
                    <div
                        className={`flex flex-col justify-center self-stretch  ` + clsxMerge(borderStyles({theme}))}>
                        <div
                            className="flex  px-4 py-5 border border-black rounded flex-col">
                            <div className="mb-2">
                                <div className="flex items-center">
                                    <div>{getCallType(selectedCall.type, selectedCall.bridgetAt, selectedCall.forwardedTo)}</div>

                                    <h1 className="text-base font-medium leading-7 ">{getCallDescription(selectedCall)}</h1>

                                </div>
                                {Boolean(selectedCall.forwardedAt) &&
                                    <div className="flex font-medium "><FlagImage className='w-4 h-4 mr-2'
                                                                                  iso2={getFlag(selectedCall.forwardedTo) ?? 'us'}/>
                                        <span>{getFormatedPhoneNumber(selectedCall.forwardedTo, true)}</span>
                                    </div>
                                }
                            </div>
                            <time className="flex gap-2  text-sm leading-5 text-slate-900"
                                  dateTime={selectedCall.startedAt}>

                                <span>{Boolean(selectedCall.startedAt) && DateFormatter(selectedCall.startedAt)}</span>
                            </time>
                        </div>
                    </div>
                    <div className={'w-full pb-5'}>


                        {(selectedCall.bridgetAt && selectedCall.finishedAt) && <div
                            className={`flex gap-5 justify-between py-5 w-full text-sm leading-5 border-b border-gray-75`}>
                            <div className="text-gray-700 items-center">Call length</div>
                            <div
                                className={`flex gap-3 text-center items-center font-semibold ` + clsxMerge(textStyles({theme}))}>
                                {duration(selectedCall.bridgetAt, selectedCall.finishedAt)}

                            </div>
                        </div>}

                        {Boolean(selectedCall.bridgetAt || selectedCall.type !== 'INBOUND') && <>
                            <div


                                className={`flex gap-5 justify-between  py-5 w-full text-sm leading-5 border-gray-75 ${selectedCall?.exposedMsisdn ? ' border-b  border-dashed' : ''}` + clsxMerge(borderStyles({theme}))}>


                                <div className="items-center text-gray-700">Handled by</div>
                                <div className="font-semibold flex items-center ">
                                <Avatar size="xs" type='initials' className="mr-2"
                                                        color={Colors.auto}
                                                        name={selectedCall.user.firstName + ' ' + selectedCall.user.lastName}/>
                                                {selectedCall.user.firstName + ' ' + selectedCall.user.lastName}
                                </div>
        
                            </div>

                        </>}


                        {selectedCall?.exposedMsisdn && <div
                            className={`flex gap-5 justify-between py-5 w-full text-sm leading-5 border-b border-gray-75 `}>
                            <div className="text-gray-700 items-center">Phone number</div>
                            {selectedCall?.exposedMsisdn &&
                                <>
                                    <div className="flex flex-col items-end">
                                        <div className='flex gap-2 mb-2 items-center'>
                                            <Avatar icon={selectedCall?.exposedMsisdn.icon} size="xxs" type='icon'
                                                    color={selectedCall?.exposedMsisdn.iconColor}
                                                    name='phone number icon'/>
                                            <div
                                                className="font-semibold">{selectedCall?.exposedMsisdn.label ? selectedCall?.exposedMsisdn.label : getFormatedPhoneNumber(selectedCall?.exposedMsisdn.msisdn, true)}</div>
                                        </div>
                                        <div className="flex items-center">
                                            <FlagImage size={18}
                                                       iso2={getFlag(selectedCall.exposedMsisdn.msisdn) ?? 'us'}/>
                                            <div
                                                className="font-semibold ml-2 text-xs text-black">{selectedCall?.exposedMsisdn.msisdn ? getFormatedPhoneNumber(selectedCall?.exposedMsisdn.msisdn, true) : ''}</div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        }
                        {recordingUrl && (
                            <>
                                <div
                                    className={`flex gap-5 justify-between px-5 py-3.5 w-full text-sm font-semibold leading-5  border-dashed max-md:flex-wrap max-md:max-w-full` + clsxMerge(borderStyles({theme}))}>
                                    <div className="text-zinc-600 items-center">Recordings</div>


                                </div>
                                <AudioPlayer disabled={!recordingUrl} src={recordingUrl}/>
                            </>

                        )}


                    </div>
                    {!selectedCall.primaryContact && !selectedCall.internalCallee && selectedCall.clientMsisdn &&

                        <div className='flex   flex-col '>

                            <Link to={`../contacts/new?phoneNumber=${selectedCall.clientMsisdn}`}>
                                <Button className={'p-0'} size='small' buttonType='textPrimary'
                                        label='Create new contact'
                                />
                            </Link>

                        </div>
                    }
                    {selectedCall.primaryContact && !selectedCall.internalCallee &&

                        <div className='flex   flex-col '>

                            <Link to={`../contacts/${selectedCall.primaryContact.contactId}`}>
                                <Button className={'p-0'} size='small' buttonType='textPrimary'
                                        label='Edit contact'
                                />
                            </Link>

                        </div>
                    }


                </div>

            </div>
        </div>

    )
};

export default CallDetails;

import Team from "./Team";
import { mobileWidth, tabletWidth } from "../../utilis/screenWidthUtils.ts";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import {useFetchCurrentUserQuery, useFetchUsersQuery} from "../../store/features/Users/userService";
import {useGetStatusesQuery} from "../../store/features/Team/teamService";
import {Loader} from "ch3-ui-lib";
import {selectUserId} from "../../store/features/Auth/authSlice";

const TeamList = () => {
    const userId = useSelector(selectUserId)


    const { data: currentUser, isLoading: isCurrentUserLoading } = useFetchCurrentUserQuery(userId)
    const { data: users = [], isLoading: isUsersLoading } = useFetchUsersQuery();
    const userIds = useMemo(() => users.map(user => user.userId), [users]);

    const { data: statuses = [], isLoading: isStatusesLoading } = useGetStatusesQuery({ users: userIds }, {
        pollingInterval: 5000,
    });

    const teamStatuses = useMemo(() => {
        return statuses?.reduce((acc: any[], status: any) => {
            const user = users.find(user => user.userId === status.userId);
            if (user && status.userId !== currentUser?.userId) {
                acc.push({
                    ...status,
                    firstName: user.firstName || '',
                    lastName: user.lastName || '',
                    position: user.position || '',
                    email: user.email || '',
                });
            }
            return acc;
        }, []);
    }, [statuses, users, currentUser?.userId]);


    if (isUsersLoading || isStatusesLoading || isCurrentUserLoading) {
        return (
            <div className={`flex w-full `}>
                <Loader/>
            </div>


        );
    }


    return(
    <div className={`w-full mr-4 ` }>
            <div
                className={`max-h-[calc(100vh-130px)] overflow-x-hidden overflow-y-scroll flex flex-wrap ${mobileWidth || tabletWidth ? 'px-4' : 'px-6'} `}>
                {Boolean(teamStatuses?.length) && teamStatuses.map((user: any) => (
                    <Team key={user.userId} user={user} />
                ))}
            </div>
        </div>

    );
};

export default TeamList;

import {Button, Icon} from "ch3-ui-lib";
import {ButtonType, IconType} from "../../../utilis/types/Types.ts";



export const ErrorDisplay = ({
                                 iconName = "error",
                                 iconType = "destructive" as IconType.destructive,
                                 title = "Error",
                                 message = "Something went wrong.",
                                 buttonLabel =  '',
                                 buttonType = ButtonType.primary,
                                 onButtonClick = () => window.location.reload(),
                                 showBackground = true
                             }) => {
    return (
        <div className="flex flex-col items-center h-full px-4 mt-[150px]">
            <Icon
                iconType={iconType}
                background={showBackground}
                iconName={iconName}
                size="xxl"
            />
            <h6 className="font-bold mt-6">{title}</h6>
            <div className="text-base mt-4 text-center">
                {message}
            </div>
            {buttonLabel && (
                <div className='flex justify-center mt-6'>
                    <Button
                        onClick={onButtonClick}
                        buttonType={buttonType}
                        label={buttonLabel}
                    />
                </div>
            )}
        </div>
    );
};


export const MicrophoneRequired = () => {
    return (
        <ErrorDisplay
            iconName="mic_off"
            title="No microphone access."
            message="Microphone is turned off. Turn it on to use this feature."
            buttonLabel="Try again"
        />
    );
};
export const InternetRequired = () => {
    return (
        <ErrorDisplay
            iconName="cloud_off"
            title="No internet connection"
            message="Check your Wi-Fi connection or mobile data."
            buttonLabel="Try again"
        />
    );
}
export const PhoneNumbersRequired = () => {
    return (
        <ErrorDisplay
            iconName="warning"
            title="No number selected"
            message="Sorry, you can't make calls yet. Your admin hasn't assigned you a phone number."
        />
    );
}
import {getApiUrl} from "../../config";
import {useEffect} from "react";

const RecordingDownload = () => {

    useEffect(() => {
        const downloadRecording = async () => {
            const currentUrl = window.location.href;
            const urlParams = new URL(currentUrl);
            const recordingId = urlParams.pathname.split('/').pop();
            const hsParam = urlParams.searchParams.get('hs');

            if (recordingId && hsParam) {
                try {
                    const apiUrl = await getApiUrl();
                    const downloadUrl = `${apiUrl}/api/v1/recordings/shared/${recordingId}?hs=${hsParam}`;

                    const response = await fetch(downloadUrl);
                    const blob = await response.blob();

                    const blobUrl = window.URL.createObjectURL(blob);

                    const anchor = document.createElement("a");
                    anchor.href = blobUrl;
                    anchor.download = `recording-${recordingId}.mp3`
                    anchor.style.display = "none";

                    document.body.appendChild(anchor);
                    anchor.click();

                    window.URL.revokeObjectURL(blobUrl);
                    document.body.removeChild(anchor);
                } catch (error) {
                    console.error("Download failed:", error);
                }
            }
        };

        downloadRecording();
    }, []);

    return (
        <div>
            <p className={'m-4'}>Downloading recording...</p>
        </div>
    );
};

export default RecordingDownload;

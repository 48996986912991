import {Icon} from "ch3-ui-lib";

interface GoogleDescriptionProps {
    copyToClipBoard: Function;

}
export const GoogleDescription = ({copyToClipBoard}: GoogleDescriptionProps) => {

    const google = {
        startText: "On this screen, you will find information necessary to start an integration process of your company’s PhoneHQ account with Google Workspace. Please ensure you have admin rights for your Google Workspace account before proceeding. You will also need the following information:",
            idText: "Your unique PhoneHQ Client ID",
            id: "114628677700680530213",
            scopeText: "Scope of the integration",
            scopeCopyText: "https://www.googleapis.com/auth/admin.directory.user.readonly,https://www.googleapis.com/auth/admin.directory.orgunit.readonly,https://www.googleapis.com/auth/admin.directory.group.readonly,https://www.googleapis.com/auth/admin.reports.audit.readonly",
            integrationName: "Google Contacts",
            title: "Integration steps:",
            description_1:
        'Access your',
            adminPanelText:
        'Google Workspace Admin Panel',
            adminPanelLink:
        'https://admin.google.com/ac/owl/domainwidedelegation',
            description_2:
        'In the API Permissions section, input the Client ID and integration scope provided above.',
            description_3:
        'Confirm that the integration is listed as active in Google Workspace.',
            description_4:
        'Return to this screen and proceed to the next step to complete the setup.',
            attentionDescription:
        "Proceed to the next step only if you have successfully completed the instructions above!",
            helpDescription:
        "If you encounter any issues during the process, please contact PhoneHQ Support or Google Workspace Support.",
            buttonLabel: "I've completed all the steps, proceed",
            instructionText: "You can also check detailed instruction in our ",
        syncWarning: 'Note: New Google Workspace users are automatically added to PhoneHQ after integration.'
    }
    return (
        <div className="flex flex-col mb-6 w-full ">
            <p className="text-base leading-6 w-full  mb-6">{google.startText}</p>
            <ul className="list-disc pl-6 mb-8">
                <li className="mb-2.5">
                    <p className="flex items-center">{google.idText}<span
                        className="font-semibold ml-2 text-md">{google.id}</span><span
                        className="hover:text-primary-400 cursor-pointer"
                        onClick={() => copyToClipBoard(google.id)}><Icon iconName="content_copy"></Icon></span></p>
                </li>
                <li>
                    <div className="flex items-center"><p>{google.scopeText}</p><span
                        className="hover:text-primary-400 cursor-pointer"
                        onClick={() => copyToClipBoard(google.scopeCopyText)}><Icon
                        iconName="content_copy"></Icon></span></div>
                    <p className="text-wrap inline max-w-[300px]">{google.scopeCopyText}</p>
                </li>
            </ul>
            <h2 className="text-2xl font-bold leading-none l">{google.title}</h2>
            <ul className="list-decimal pl-6 mb-2">
                <li>
                    <p className="my-4 font-medium text-base leading-6  ">{google.description_1}<a target="_blank"
                                                                                                   className="ml-1 underline text-primary-400"
                                                                                                   href={google.adminPanelLink}>{google.adminPanelText}</a>
                    </p>
                </li>
                <li>
                    <p className="my-4 text-base font-medium leading-6  ">{google.description_2}</p>
                </li>
                <li>
                    <p className="my-4 text-base font-medium leading-6 ">{google.description_3}</p>
                </li>
                <li>
                    <p className="my-4 text-base font-medium leading-6  ">{google.description_4}</p>
                </li>
            </ul>
            <div
                className="mb-4 text-sm p-4 border border-primary-400 bg-primary-50 rounded">{google.attentionDescription}</div>
            <div className="mb-4">{google.helpDescription}</div>
            <div className="mb-4 flex items-center gap-2">{google.instructionText}
                <a className={'flex items-center '} target='_blank'
                   href={'https://support.phonehq.com/en/articles/53-google-workspace-integration-step-by-step'}><span className='text-primary-400 underline'>Knowledge Base Article</span> <Icon iconName={'open_in_new'}/> </a></div>
            <div
                className="mb-4 text-sm p-4 border border-primary-400 bg-primary-50 rounded">{google.syncWarning}</div>
        </div>)
}

import {useSelector} from "react-redux";
import {UserRow} from "./UserRow";
import {useEffect, useState} from "react";
import {clsxMerge} from "../../../utilis/classNameUtils";
import {borderStyles} from "../../Groups/helpers";
import {Button, ChCheckbox, ChInput, ChModal, Loader} from "ch3-ui-lib";
import {boxStyles} from "../../Contacts/helpers";
import useMediaQuery from "../../../utilis/screenWidthUtils";
import {
    GoogleIntegrationResponse,
    GoogleUser,
    useDeleteGoogleIntegrationMutation,
    useGetCurrentGoogleIntegrationQuery,
    useGetCurrentGoogleIntegrationUsersQuery,
    useImportGoogleContactsMutation, useStatusGoogleIntegrationMutation,
} from "../../../store/features/integrations/integrationsService";
import {useAppDispatch} from "../../../store/store";
import {ClosedDialerMargin, OpenDialerMargin} from "../../../utilis/dialerWidthUtils";
import {showToast} from "../../../store/features/Notifications/notificationsSlice";
import {useNavigate} from "react-router-dom";



interface UserListProps {
    integrationRes: GoogleIntegrationResponse;

}

export const UserList = ({integrationRes}:UserListProps) => {
    const navigate = useNavigate();
    const [integrationStarted, setIntegrationStarted] = useState(false);

    const {data: integrationData} = useGetCurrentGoogleIntegrationQuery();

    const {data: usersData, isLoading} = useGetCurrentGoogleIntegrationUsersQuery(
        { integrationId: integrationData?.current?.id },
        { skip: !integrationData?.current?.id }
    );

    const [integrationId, setIntegrationId] = useState<any>(integrationRes ? integrationRes.integrationId : integrationData?.current?.id);
    const [disconnectMutation] = useDeleteGoogleIntegrationMutation();


    const [getStatusMutation, { data: statusesData }] = useStatusGoogleIntegrationMutation();
    const [integrationStatuses, setIntegrationStatuses] = useState<any>(statusesData);
    const [importId, setImportId] = useState<number | null>(null);

    const [isPolling, setIsPolling] = useState(false);

    useEffect(() => {
        if (statusesData) {
            setIntegrationStatuses(statusesData);
        }
    }, [statusesData]);

    useEffect(() => {
        if (!isPolling || !importId) return;

        const interval = setInterval(async () => {
            await getStatusMutation({integrationId, emails: [], importId});
        }, 5000);

        return () => clearInterval(interval);
    }, [isPolling, getStatusMutation, integrationId, importId]);

    useEffect(() => {
        setIntegrationId(integrationRes ? integrationRes.integrationId : integrationData?.current?.id);
    }, [integrationData, integrationRes]);

    useEffect(() => {
        if (usersData) {
            setUserList(usersData.googleResponse.users);
        }
    }, [usersData]);

    const [userList, setUserList] = useState<GoogleUser[]>(integrationRes ? integrationRes.googleResponse.users : []);
    const [selected, setSelected] = useState<GoogleUser[]>([]);

    useEffect(() => {
        if (usersData?.googleResponse.users) {
            setSelected(usersData.googleResponse.users);
        }
    }, [usersData]);

    const dispatch = useAppDispatch();
    const isDialerHidden = useSelector((state: any) => state.dialer.isDialerHidden);

    const [importGoogleContacts] = useImportGoogleContactsMutation();
    const theme = useSelector((state: any) => state.themeSwitcher.theme);
    const isMobile = useMediaQuery('(max-width: 960px)');


    const handleSelect = (user: any) => {
        if (selected.includes(user)) {
            setSelected(selected.filter((u: any) => u !== user));
        } else {
            setSelected([...selected, user]);
        }
    }
    const onSelectAll = () => {
        if (selected.length === userList.length) {
            setSelected([]);
        } else {
            setSelected(userList);
        }

    }
    const isSelectedAll = () => {
        if(selected?.length) {
           return  selected.length === userList.length ? true : 'indeterminate';
        }
        return;
    }
    const handleSubmit = async () => {
        setIntegrationStarted(true);
        try {

            const importResponse = await importGoogleContacts({integration: integrationId, users: selected});

            setImportId(importResponse.data.importId);

            await getStatusMutation({
                integrationId,
                importId,
                emails: [],
            });

            setIsPolling(true);
            setIntegrationStarted(false);
            setIntegrationStatuses(statusesData);
            setUserList(selected);
        } catch (error) {
            dispatch(
                showToast({
                    description: "Failed to import users.",
                    type: "error",
                })
            );
            isPolling && setIsPolling(false);
            setIntegrationStarted(false);
        }
    };

    const handleDataChange = (value: string) => {
        if(isPolling) {
            if(!value) return setUserList(selected);
            const filtered = selected.filter((user: any) => {
                return user.firstName.toLowerCase().includes(value.toLowerCase()) || user.lastName.toLowerCase().includes(value.toLowerCase()) || user.email.toLowerCase().includes(value.toLowerCase());
            });
            setUserList(filtered);
            return;
        }
        if(!value) return setUserList(usersData?.googleResponse.users);
        const filtered = usersData?.googleResponse.users.filter((user: any) => {
            return user.firstName.toLowerCase().includes(value.toLowerCase()) || user.lastName.toLowerCase().includes(value.toLowerCase()) || user.email.toLowerCase().includes(value.toLowerCase());
        });
        setUserList(filtered);

    }
    if (!userList && !usersData?.googleResponse?.users) {
        return <div className={`flex w-full  ${isMobile ? ' px-4' : isDialerHidden ? ClosedDialerMargin : OpenDialerMargin}`}>
            <Loader/>
        </div>
    }

    const disconnectIntegration = async () => {
        try {
            await disconnectMutation({integrationId: integrationId});
            navigate('/admin/integrations/google/disconnected');
        } catch (error) {
            showToast({
                title: "Error",
                description: "Failed to disconnect integration.",
                type: "error",
            })

        }
    }

    const doesUserExists = (user: GoogleUser) => {
        if (usersData?.alreadyExists.includes(user.email)) return 'ALREADY_EXISTS';
        if(usersData?.alreadySynced.includes(user.email)) return 'ALREADY_SYNCED';

        return false;
    }

    return (
        <>
            <div className='flex justify-end w-full mr-8 relative bottom-[82px]'>
                <ChInput placeholder={'Search'} className={`${isMobile ? ' ' : ' min-w-[375px]'}`}  prefixIcon={'search'} callback={handleDataChange}/>

            </div>
            <div className='w-full  '>
                <div className={` flex flex-col border  ${isMobile ? ' mt-6' : ' mt-8 '}` + clsxMerge(borderStyles({theme}), boxStyles({theme}))}>
                    <thead className={'border-b ' + clsxMerge(borderStyles({theme}))}>
                    <div className={'font-semibold flex items-center text-lg mb-0 py-5 pr-6 pl-6 border-b ' + clsxMerge(borderStyles({theme}))}>
                        {!isPolling ?  'List of users' : 'Imported users'}
                        {isPolling  && <div className={'font-normal text-base ml-6'}>
                            Successful <span
                            className='py-1 font-normal mx-4 text-xs border border-success-500 px-2 text-success-500'>{integrationStatuses?.entitiesCreated}</span>
                            Failed <span
                            className='py-1 font-normal mx-4 text-xs border border-error-500 px-2 text-error-500'>{integrationStatuses?.entitiesRejected}</span>
                        </div>}
                    </div>
                    {!isPolling && <tr className={`border-b ` + clsxMerge(borderStyles({theme}))}>
                        <div className={'font-semibold p-3 '}>
                            <ChCheckbox value={''} checked={isSelectedAll()} label={'Select all'} callback={() => {
                                onSelectAll()
                            }}/>
                        </div>
                    </tr>}

                    </thead>
                    {!isLoading && <div className='h-[calc(100vh-600px)] overflow-y-auto '>
                        {
                            userList.map((user: GoogleUser) => {
                                return (
                                    <UserRow isPolling={isPolling} user={user} userExists={doesUserExists(user)}
                                             userStatus={integrationStatuses?.entitiesStatuses?.find((el: GoogleUser) => el.email === user.email)?.status}
                                             handleSelect={handleSelect}
                                             isSelected={selected?.some((u) => u.email === user.email)}/>
                                );
                            })
                        }
                    </div>
                    }
                    {
                        isLoading && <Loader/>
                    }

                </div>
            </div>

            <div className='flex justify-between w-full py-4 pr-4'>
                {(integrationData?.current?.platform.includes('GOOGLE_WORKSPACE') && !integrationData?.current?.details?.disabled ) &&  <div className='flex'>
                    <div className='bg-grey-50 flex items-center text-sm px-2 gap-2'>
                        <span className='text-xs'>Google account </span> <span
                        className='font-semibold'>{integrationData?.current?.details.userEmail}</span>
                    </div>
                    <div>
                        <ChModal callback={
                            (res: boolean) => {
                                if (res) {
                                    disconnectIntegration();
                                }
                            }
                        } title={'Are you sure you want to disconnect it?'}
                                 modalType={'destructive'}
                                 trigger={<Button buttonType='textError'
                                                  label={'Disconnect'}/>}
                                 content={<div className={'p-4 mb-4 w-[400px]'}>By disconnecting this integration, you
                                     will lose all the data and this action can’t be undone. </div>}
                                 secondaryLabel='Cancel'
                                 primaryLabel='Yes, disconnect'/>

                    </div>
                </div>
                }
                {!isPolling &&
                    <Button
                        className={'w-[190px] h-[47px]'}
                        loading={integrationStarted}
                        onClick={() => {
                            handleSubmit();
                        }} label={'Sync users'}/>}
                {isPolling &&
                    <Button
                        onClick={() => {
                            navigate('/admin/integrations');
                        }}
                        label={'Done'}/>}
            </div>
        </>
    );
}

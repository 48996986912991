import {Button, ChModal} from "ch3-ui-lib";
import { useNavigate  } from 'react-router-dom';
import {useParams} from "react-router-dom";
import {Queue, useDeleteQueueMutation} from "../../store/features/CallQueue/CallQueueService";

interface Msisdn {
    id: number;
    phoneNumber: number;
    icon: string;
    label: string;
    type: string;
}
interface DeleteCallQueueModalProps {
    phoneNumbers: Msisdn[];
    callQueue?: Queue;
}
const DeleteCallQueueModal = ({phoneNumbers, callQueue}:DeleteCallQueueModalProps) => {
    const {id} = useParams<{ id: string }>();

    const [useDeleteCallQueue] = useDeleteQueueMutation();
    const navigate = useNavigate ();

    const handleRemove = async (res: boolean) => {
        if(!res) return;
        await useDeleteCallQueue(Number(id));
        navigate("../admin/callQueues");
    }
    const deleteCallQueueTrigger = <Button buttonType='textDestructiveSecondary' leftIcon='delete' label='Delete this queue'/>;

    if (phoneNumbers?.length > 0) {
        return (
                <ChModal primaryLabel='Delete queue' secondaryLabel={'Cancel'} callback={handleRemove} icon={'destructive'} modalType='destructive' trigger={deleteCallQueueTrigger} title={`Are you sure you want to delete the \n "${callQueue?.name}" queue?`} content={<DeleteCallQueuePhoneNumbersModal phoneNumbers={phoneNumbers}/>}/>
        )
    }
    return (
        <div >
            <ChModal  primaryLabel='Delete' secondaryLabel={'Cancel'} callback={handleRemove} icon={'destructive'} modalType='destructive' trigger={deleteCallQueueTrigger} title='Delete Call Queue' content={<DeleteGroupContent/>}/>
        </div>
    )
}
const DeleteGroupContent = () => {


    return (
        <div>
            <div className='w-80 text-grey-500 text-sm pb-5'>
                Are you sure you want to delete this queue?
                This action cannot be undone.
            </div>
        </div>
    )
}

interface DeleteCallQueuePhoneNumbersModalProps {
    phoneNumbers: Msisdn[];
}
const DeleteCallQueuePhoneNumbersModal = ({phoneNumbers}: DeleteCallQueuePhoneNumbersModalProps) => {
    return (
        <div  className={'w-[400px]'}>
            <div className='w-80 text-grey-700 text-sm pb-2'>
                Deleting this queue will result in changes to the configuration of the following numbers:
            </div>
            <div className='w-80 text-grey-700 text-sm pb-2'>
                {phoneNumbers?.map((phoneNumber) => (
                    <div key={phoneNumber.id} className='flex items-center gap-2'>
                        <li>{phoneNumber.label}</li>
                    </div>
                ))}
            </div>
            <div className={'text-grey-700 text-sm mb-8'}>
                These numbers will be disconnected from this queue. Are you sure you want to delete this queue?
            </div>
        </div>
    )
}
export default DeleteCallQueueModal;

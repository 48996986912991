import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {ChToast} from "ch3-ui-lib";
import {getToast, hideToast} from "../../store/features/Notifications/notificationsSlice";

const ToastNotification = () => {
    const dispatch = useDispatch();
    const { title, description, isOpen, type, position, dontHide, icon } = useSelector(getToast);

    const timeout =  dontHide ? 24*60*60*1000 : 3000
    console.log(timeout)

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                dispatch(hideToast());
            }, timeout);
        }
    }, [isOpen, dispatch]);

    return (
       <ChToast title={title} description={description} type={type} open={isOpen} setOpen={() =>console.log('test')} icon={icon} position={position} />
    );
};

export default ToastNotification;

import { Avatar, Button, ChColumn, ChDropdown, ChInput, ChTooltip, Loader } from "ch3-ui-lib";
import {  useIntl } from "react-intl";
import { useCallback, useEffect, useState } from "react";
import {Link} from "react-router-dom";
import {ChDropdownGroup, Colors} from "../../utilis/types/Types";
import {useSelector} from "react-redux";
import noGroupsIcon from '../../assets/empty.svg';
import listEmpty from '../../assets/list-empty.svg';
import { debounce } from "lodash";
import { getFormatedPhoneNumber } from "../../utilis/msisdnUtilis";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import {
    FetchGroupsResponse,
    useFetchGroupsAdminQuery,
    useFetchGroupsQuery
} from "../../store/features/groups/groupsService";
import {buildQueryParams} from "../../utilis/buildQueryParams";
import {Msisdn, MsisdnSelectItem, useGetUserMsisdnsQuery} from "../../store/features/Msisdns/msisdnService";
import { borderStyles, boxStyles } from "./helpers.tsx";
import { clsxMerge } from "../../utilis/classNameUtils.ts";
import {selectUserId} from "../../store/features/Auth/authSlice";

interface GroupListProps {
    admin: boolean;
}
export default function GroupList({admin = false}: GroupListProps) {

    const [loading, setLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [isSearching, setIsSearching] = useState(false);

    const isMobile = useMediaQuery('(max-width: 960px)');

    const theme = useSelector((state:any) => state.themeSwitcher.theme);
    const userId = useSelector(selectUserId);

    const [groups, setGroups] = useState<FetchGroupsResponse[]>([]);
    const [totalGroups, setTotalCount] = useState(0);

    const [phrase, setPhrase] = useState<string>('');
    const [phonePhrase, setPhonePhrase] = useState<string>('');
    const [phoneNumbersFilter, setPhoneNumbersFilter] = useState<MsisdnSelectItem[]>([]);
    const [sort, setSort] = useState<any>({});
    const [groupsQuery, setGroupsQuery] = useState<any>({});

    const intl = useIntl();
    const name = intl.formatMessage({ id: 'groups.name' });
    const phoneNumber = intl.formatMessage({ id: 'groups.users' });
    const sharedFor = intl.formatMessage({ id: 'groups.phone-numbers' });

    const msisdns = phoneNumbersFilter.map((phoneNumber: MsisdnSelectItem) => phoneNumber.msisdn);
    const order = sort?.order;
    const sortBy = sort?.property;

    const getGroupsQuery = buildQueryParams({
        phrase,
        msisdns,
        order,
        sortBy,
        ...groupsQuery,
        userId,
        isAdmin: admin
    });


    const { data: groupsData, isLoading, isFetching: isUserFetching } = useFetchGroupsQuery(getGroupsQuery, { skip: admin });
    const { data: adminGroupsData, isLoading: isAdminLoading, isFetching:isAdminFetching } = useFetchGroupsAdminQuery(getGroupsQuery, { skip: !admin });
    console.log(adminGroupsData, 'adminGroupsData');
    const { data: userMsisdns } = useGetUserMsisdnsQuery({phrase:phonePhrase, userId} );


    useEffect(() => {
        setGroups([]);
        setTotalCount(0);
        setGroupsQuery({});
    }, [admin]);


    useEffect(() => {
        const currentData = admin ? adminGroupsData : groupsData;

        if (currentData) {
            setGroups(currentData.groups);
            setTotalCount(currentData.totalCount);
        }
        setIsSearching(false);
    }, [admin, groupsData, adminGroupsData]);


    const filterButton = <Button size={'small'} label='Phone numbers' leftIcon='filter_list' buttonType='textPrimary'/>;

    const phoneNumbers = userMsisdns ? userMsisdns?.data.map((msisdn:any) => (
        {
            label:<div className='flex items-center'> <div className='mr-2'><Avatar icon={msisdn.icon} size="sm" type='icon' color={msisdn.iconColor} name='phone number icon'/></div> { msisdn.label }</div>,


            value: msisdn,
            key: msisdn.msisdnId})) : [];




    const handleDataChange = (value: string) => {
        setIsSearching(true);
        setGroups([]);
        setGroupsQuery({});

        handlePhraseChange(value);
    };

    const handlePhraseChange = useCallback(
        debounce((phrase: string) => {
            setPhrase(phrase);
        }, 1000), // 1 second delay
        []
    );

    const handleDataSortChange = (order: string, property: string) => {
        setSort({
            order,
            property
        });
    };


    const handlePhoneNumberSelect = (value: MsisdnSelectItem) => {

        setPhoneNumbersFilter((prev: MsisdnSelectItem[]) => {
            const exists = prev.some(item => item === value);
            if (exists) {
                return prev.filter((item) => item !== value);
            } else {
                return [...prev, value];
            }
        });
    };

    const dropdownGroup: ChDropdownGroup[] = [{
        items: phoneNumbers,
        type: 'checkbox',
        callback: (value: any) => {
            handlePhoneNumberSelect(value)
        }
    }]



    const loadMore = () => {
        const currentData = admin ? adminGroupsData : groupsData;
        const lastGroup = currentData?.groups?.at(-1);

        setGroupsQuery({
            lastName: lastGroup?.group.name ,
            lastGroupId: lastGroup?.group.groupId
        });
    };

    useEffect(() => {
        setLoading(isLoading || isAdminLoading);
    }, [isLoading, isAdminLoading]);

    useEffect(() => {
        setIsFetching(isUserFetching || isAdminFetching);
    }, [isUserFetching, isAdminFetching]);

    return (
        <>
        {loading &&
            <div className={`flex w-full  ${isMobile ? ' px-4' : ''}`}>
                <Loader/>
            </div>

        }
            {!loading &&
        <div className='w-full '>
            <div className={`  ${isMobile ? ' px-4' : ' '}`}>
                <div className={`flex justify-between items-center py-3 px-8 no-wrap border-b border-black `}>
                    <ChDropdown trigger={filterButton} type={'search'} dropdownGroup={dropdownGroup}  onPhraseChange={(value: string) => setPhonePhrase(value)} selected={phoneNumbersFilter} ></ChDropdown>
                    <ChInput value={phrase}  clearButton={true} placeholder={'Search'} className={`${isMobile ? ' ' : ' min-w-[375px] h-[36px]'}`}  prefixIcon={'search'}  callback={(value: string) => handleDataChange(value)}/>
                </div>

                <div className={`h-[calc(100vh-61px-var(--header-height))] overflow-y-auto `}>
                    <div className={' w-full ' + clsxMerge(boxStyles({theme}))}>
                    {/* <p className={`text-lg font-semibold px-4 py-5 ` + clsxMerge(borderStyles({theme}))} ><FormattedMessage

                        id='groups.list'/>
                        <span className={`bg-information-400 text-white  rounded-sm px-2 text-xs mx-2 py-1 ` + clsxMerge(borderStyles({theme}))}>
        {totalGroups}
        </span>

                    </p> */}
                    {Boolean(groups.length) && <table className={` w-full border-b ` + clsxMerge(borderStyles({theme}))}>

                        <thead>
                        <tr className={`border-b ` + clsxMerge(borderStyles({theme}))} >
                            <th className='pl-8 py-3 w-full'
                            ><ChColumn propertyName='NAME' callback={handleDataSortChange} label={name}/></th>
                            <th className='min-w-[300px] '
                            ><ChColumn  noSort={true} propertyName='USERS' callback={handleDataSortChange} label={phoneNumber}/></th>
                            <th className='min-w-[200px]'
                            ><ChColumn noSort={true} propertyName='MSISDN' callback={handleDataSortChange} label={sharedFor}/></th>
                        </tr>
                        </thead>
                        <tbody>
                        {groups.map((groupData: FetchGroupsResponse, index: number) => {
                                return (
                                    <tr className={`border-t border-grey-50 hover:bg-grey-50` } key={index}>
                                        <Link to={admin ? `/admin/groups/details/${groupData.group.groupId}` : `/groups/details/${groupData.group.groupId}`}
                                              className='no-underline block min-w-[250px]'>

                                            <td className='flex gap-3 font-semibold  items-center pl-8 py-4'><Avatar
                                                size="md" type='icon' icon={'group'}
                                                color='darkGrey'
                                            />
                                            <div className="w-[200px] truncate">{groupData.group.name}</div>
                                            </td>
                                        </Link>


                                        <td >
                                            <div className=" justify-start items-center flex">
                                                {
                                                    groupData.users?.map((user, index) => (
                                                        <ChTooltip

                                                            trigger={<div  style={{right: `${index * 20}px`}}
                                                                                 className={`p-[2px] bg-white relative`}>
                                                            <Avatar key={index} size="sm" type='initials'
                                                                    color={user.iconColor ?? Colors.auto}
                                                                    name={user.firstName + ' ' + user.lastName}/>
                                                        </div>} content={user.firstName + ' ' + user.lastName} theme={'dark'}></ChTooltip>

                                                    ))}
                                            </div>
                                        </td>
                                        <td className={'flex gap-3 mr-4 items-center min-w-[320px]'}>
                                            {groupData.msisdns.slice(0, 2).map((el: Msisdn) => {
                                                return <div className='flex items-center'>
                                                    <div className='text-xl mr-2 '>                                                    
                                                        <Avatar className='!text-base' icon={el.icon} size="xxs" type='icon' color={el.iconColor} name=''/>
                                                    </div>
                                                        <div className="w-[100px] truncate">
                                                        {el.label ?? getFormatedPhoneNumber(el.msisdn)}
                                                        </div>
                                                    </div>
                                            })}
                                            {
                                                groupData.msisdns.length > 2 && `+${groupData.msisdns.length - 2}`
                                            }
                                        </td>

                                    </tr>
                                )
                            }
                        )}

                        </tbody>

                    </table>}


                    {groups.length === 0 && (!isFetching && !isSearching ) && Boolean(totalGroups) &&
                        <div className={`flex text-center  justify-center  border-b ` + clsxMerge(borderStyles({theme}))}>
                            <div className='w-[450px]  m-14'>
                                <div className='flex  justify-center w-full'>
                                    <img className='m-4' src={noGroupsIcon}/>
                                </div>

                                <h1 className='font-bold'>
                                    No groups found
                                </h1>
                                <div className='mb-6 mt-2'>
                                    No groups found matching your search. Please double-check your criteria or consider
                                    adding new groups to expand your network!
                                </div>
                                {admin && 
                                <div className='flex justify-center'>
                                    <Link to={'/admin/groups/details/new'}>
                                        <Button shadow leftIcon='add' buttonType='primary' label='Create new group'/>
                                    </Link>

                                </div>
                                }
                            </div>

                        </div>

                    }
                        {
                            (isFetching || isSearching) &&
                            <div className='flex overflow-hidden max-h-[calc(100vh-61px-var(--header-height))]  justify-center'>
                                <Loader/>
                            </div>
                        }
                        {(Boolean(groups.length) && groups.length != totalGroups) &&
                            <div className='flex justify-center my-4'>
                                <Button onClick={loadMore} leftIcon={'more_horiz'} size='small' buttonType='textSecondary'
                                        label='Load more'/>
                            </div>
                        }
                    </div>
                    {!totalGroups &&
                        <div className={`flex text-center  justify-center  border-b ` + clsxMerge(borderStyles({theme}), boxStyles({theme}))}>
                            <div className='w-[450px]  m-14'>
                                <div className='flex  justify-center w-full'>
                                    <img className='m-4' src={listEmpty}/>
                                </div>

                                <h1 className='font-bold'>
                                    No groups yet!
                                </h1>
                                <div className='mb-6 mt-2'>
                                    Click below to add one now.
                                </div>
                                {admin && 
                                <div className='flex justify-center'>
                                    <Link to={'/admin/groups/details/new'}>
                                        <Button shadow leftIcon='add' buttonType='primary' label='Create new'/>
                                    </Link>
                                </div>
                                }
                            </div>

                        </div>

                    }

                </div>

            </div>

        </div>
        }
        </>
    );
}

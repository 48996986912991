import {Button} from "ch3-ui-lib";
import {useNavigate} from "react-router-dom";

export const GoogleDisconnected = () => {
    return (
        <IntegrationDisconnected

            reconnectPath="/admin/integrations/google"
        />
    );
};


export const PipedriveDisconnected = () => {
    return (
        <IntegrationDisconnected

            reconnectPath="/admin/integrations/pipedrive"
        />
    );
};


export const IntegrationDisconnected = ({

                                            reconnectPath = '/admin/integrations',
                                            integrationsPath = '/admin/integrations'
                                        }) => {
    const navigate = useNavigate();

    return (
        <div>


            <div className="text-left mt-8 w-[577px] mx-auto">
                <h1 className="w-[680px] mx-auto mb-2 font-semibold text-left">
                    Your account has been successfully disconnected
                </h1>

                <div className="text-grey-700">
                    You can re-connect your account at any time by clicking the "Reconnect" button below.
                </div>

                <div className="flex justify-end mt-8 gap-2 mx-auto w-[577px]">
                    <Button
                        onClick={() => navigate(reconnectPath)}
                        label="Reconnect"
                        buttonType="secondary"
                    />
                    <Button
                        onClick={() => navigate(integrationsPath)}
                        label="Done"
                        buttonType="primary"
                        className="w-[120px]"
                    />
                </div>
            </div>
        </div>
    );
};

import {Button, ChInput, ChRadio, Icon, Loader, Switch, ChDropdown, ChModal, Avatar} from "ch3-ui-lib";
import {ClosedDialerMargin, OpenDialerMargin} from "../../utilis/dialerWidthUtils";
import {RootState} from "../../store/store";
import {useSelector} from "react-redux";
import {
    Action,
    useCreateQueueMutation,
    useGetCallQueueRecordingQuery,
    useGetQueueByIdQuery,
    useGetQueuePhoneNumbersQuery, useGetQueuesQuery,
    useLazyGetPlayMessageQuery,
    usePostCallQueueRecordingPreviewMutation,
    useUpdateQueueMutation,
    useUploadCallQueueSynthetizeTextMutation,
} from "../../store/features/CallQueue/CallQueueService";
import {useForm, Controller, useFieldArray, FieldError} from "react-hook-form";
import {useEffect, useMemo, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import DeleteCallQueueModal from "./DeleteCallQueue";
import WelcomeMessage from "../PhoneNumbers/Modal/WelcomeMessage";
import {
    useFetchMsisdnsAdminQuery,
    useSynthetizeTextMutation,
} from "../../store/features/MsisdnSettings/msisdnSettingsService";
import {ForwardModal} from "./ForwardModal.tsx";
import {useFetchUsersQuery, User} from "../../store/features/Users/userService.ts";
import {useFetchGroupsAdminQuery} from "../../store/features/groups/groupsService.ts";
import {Colors, dropdownItem, MessageType} from "../../utilis/types/Types.ts";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer.tsx";
import audioImg from "../../assets/audio.svg";
import {getFormatedPhoneNumber} from "../../utilis/msisdnUtilis.ts";
import {FlagImage} from "react-international-phone";
import {parsePhoneNumber} from "libphonenumber-js/max";
import {getCallingCountries} from "../../components/Dialer/services/countryDataService.ts";
import {removeEmptyFields} from "../../utilis/removeEmptyFields.ts";



const routingOptions = [
    {
        id: '1',
        label: "Ring all",
        description: "Call all agents at once. First to answer handles the call.",
        value: "RING_ALL",
    },
    {
        id: '2',
        label: "Longest Idle",
        description: "Call the agent idle the longest, considering tiers.",
        value: "LONGEST_IDLE_AGENT",
    },
    {
        id: '3',
        label: "Random",
        description: "Call agents in random order.",
        value: "RANDOM",
    }
];


type FormType = {
    name: string;
    ringStrategy: string;
    ivrMenuOption: {
        enabled: boolean;
        actions: Action[];
        messageSettings: {
            WELCOME_MESSAGE: {
                originalFileName: string;
                bucketFileName: string;
            };
            MENU_MESSAGE: {
                originalFileName: string;
                bucketFileName: string;
            };
        };
    };
};
export const CallQueues = () => {
    const {id} = useParams<{ id: string }>();
    const isNewQueue = id === 'new';
    const [phrase] = useState('');
    const userId = useSelector((state: RootState) => state.auth.userId);

    const [audioFiles, setAudioFiles] = useState<any>({});
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const [pendingUpload, setPendingUpload] = useState<MessageType | string>('');

    const [modalValue, setModalValue] = useState<any>({type: 'ForwardToInternalNumber'});

    const isDialerHidden = useSelector((state: RootState) => state.dialer.isDialerHidden);

    const {data: callQueue, isLoading: callQueueLoading} = useGetQueueByIdQuery(Number(id), {skip: isNewQueue});
    const {data: callQueuePhoneNumbers} = useGetQueuePhoneNumbersQuery(Number(id), {skip: isNewQueue});
    const { data: callQueueList } = useGetQueuesQuery();


    const { data: adminGroupsData , isLoading: groupsLoading} = useFetchGroupsAdminQuery({userId, phrase});
    const { data: usersData = [], isLoading: userLoading } = useFetchUsersQuery();
    const {data: phoneNumberData, isLoading: phoneNumbersLoading} = useFetchMsisdnsAdminQuery({phrase});

    const isLoading = callQueueLoading || groupsLoading || userLoading || phoneNumbersLoading

    const {data: greetingsAudioUrl} = useGetCallQueueRecordingQuery({id, type: MessageType.WELCOME_MESSAGE});
    const {data: menuAudioUrl,  } = useGetCallQueueRecordingQuery({id, type: MessageType.MENU_MESSAGE});



    const [postRecordingPreview, {data: tempFile, reset: resetTempFile}] = usePostCallQueueRecordingPreviewMutation();
    const [synthetizeText, {data: tempTtsFile}] = useSynthetizeTextMutation();
    const [postSynthTextPreview, {data: synthText, reset: resetSynthText}] = useUploadCallQueueSynthetizeTextMutation();

    const [trigger] = useLazyGetPlayMessageQuery();

    const {
        control,
        handleSubmit,
        getValues,
        setValue,
        setError,
        clearErrors,
        watch,
        formState: {errors},
    } = useForm<FormType>({
        mode: 'onBlur',
        defaultValues: {
            name: '',
            ringStrategy: 'RING_ALL',
            ivrMenuOption: {
                enabled: false,
                actions: [],
                messageSettings: {
                    WELCOME_MESSAGE: {
                        originalFileName: "",
                        bucketFileName: ""
                    },
                    MENU_MESSAGE: {
                        originalFileName: "",
                        bucketFileName: ""
                    }
                }
            }
        },
    });

    const {fields, append, remove, replace} = useFieldArray({
        control,
        name: 'ivrMenuOption.actions',
    });
    if (fields.length === 0) {
        append({ type: 'Receive', label: 'Receive call', order: '0' });
    }

    useEffect(() => {
        if (!callQueue || isLoading) return;

        const actions = callQueue?.ivrMenuOption.actions;
        const playMessages = actions
            .map((action, index) => ({ ...action, originalIndex: index }))
            .filter((action) => action.type === "PlayMessage");

        const fetchMessages = async () => {
            if (playMessages.length > 0) {
                await Promise.all(
                    playMessages.map(async (el) => {
                        const { data: message } = await trigger({ id, order: Number(el?.order) });
                        setAudioFiles((prev: any) => ({
                            ...prev,
                            PLAY_MESSAGE: {
                                ...prev.PLAY_MESSAGE,
                                [el.originalIndex]: message?.data,
                            },
                        }));
                    })
                );
            }
        };

        fetchMessages();
    }, [callQueue, isLoading]);

    const [createQueue] = useCreateQueueMutation();
    const [updateQueue] = useUpdateQueueMutation();

    const forwardModalRef  = useRef<{ onSubmit: () => void, openModal: () => void }>(null);
    const playMessageRef  = useRef<{ onSubmit: () => void, openModal: () => void }>(null);




    const navigate = useNavigate();


    const greetingsMessageRef = useRef<{ onSubmit: () => void, openModal: () => void }>(null);
    const menuMessageRef = useRef<{ onSubmit: () => void, openModal: () => void }>(null);


    const getSelected = (item: dropdownItem | undefined) => {
        if (!item) return null;
        return (
            <div className={'flex items-center gap-2'}>
                <span>
                      {item?.prefixElement}
                </span>
                {item?.label}

            </div>
        )
    }

    const getPhoneNumber = (val: string) => {
        const parsedPhoneNumber = parsePhoneNumber(`+${val}`);
        const country = getCallingCountries().find(
            (country) => country.alpha2 === parsedPhoneNumber?.country);
        return (
            <div className={'flex items-center gap-2'}>
                <span>
                    <FlagImage size={30} iso2={country?.alpha2.toLowerCase()} className={'px-2'}/>
                </span>
                {getFormatedPhoneNumber(val)}
            </div>
        )
    }
    const phoneNumbersItems = useMemo(() => phoneNumberData?.data.map((phoneNumber) => ({
        label: phoneNumber.label,
        value: phoneNumber.msisdnId.toString(),
        key: `phoneNumber${phoneNumber.msisdnId}`,
        prefixElement: (
            <div className='ml-2'>
                <Avatar size={'sm'} type={'icon'} icon={phoneNumber.icon} color={phoneNumber.iconColor ?? Colors.auto} name={phoneNumber.label} />
            </div>
        ),
    })) || [], [phoneNumberData]);

    const userItems = useMemo(() => usersData?.map((user: User) => ({
        label: `${user.firstName.toString()} ${user.lastName.toString()}`,
        value: user.userId.toString(),
        key: `user${user.userId}`,
        prefixElement: (
            <div className='ml-2'>
                <Avatar size={'sm'} type={'initials'} color={user.iconColor ?? Colors.auto} name={`${user.firstName} ${user.lastName}`} />
            </div>
        ),
    })) || [], [usersData]);

    const groupItems = useMemo(() => adminGroupsData?.groups?.map((group: any) => ({
        label: group.group.name,
        value: group.group.groupId?.toString(),
        key: `group${group.group.groupId}`,
        prefixElement: (
            <div>
                <Avatar size={'sm'} type={'icon'} icon={group?.icon} color={group.group.iconColor} name={group.group.name} />
            </div>
        ),
    })) || [], [adminGroupsData]);


    useEffect(() => {
        if (callQueue && !isLoading) {
            setValue('name', callQueue.name || '');
            setValue('ringStrategy', callQueue.ringStrategy || 'RING_ALL');
            setValue('ivrMenuOption.enabled', callQueue.ivrMenuOption.enabled || false);

            setValue(
                'ivrMenuOption.messageSettings.WELCOME_MESSAGE.originalFileName',
                callQueue.ivrMenuOption?.messageSettings?.WELCOME_MESSAGE?.originalFileName || ''
            );
            setValue(
                'ivrMenuOption.messageSettings.MENU_MESSAGE.originalFileName',
                callQueue.ivrMenuOption?.messageSettings?.MENU_MESSAGE?.originalFileName || ''
            );
            setValue(
                'ivrMenuOption.messageSettings.MENU_MESSAGE.bucketFileName',
                callQueue.ivrMenuOption?.messageSettings?.MENU_MESSAGE?.bucketFileName || ''
            );
            setValue(
                'ivrMenuOption.messageSettings.WELCOME_MESSAGE.bucketFileName',
                callQueue.ivrMenuOption?.messageSettings?.WELCOME_MESSAGE?.bucketFileName || ''
            );
        }


        replace(
            ivrActions.map((action) => {
                let label: null | string | JSX.Element = '';
                if (action.type === 'ForwardToInternalNumber') {
                    label = getSelected(
                        phoneNumbersItems.find((phone) => action.phoneNumberId?.toString() === phone.value)
                    );
                } else if (action.type === 'ForwardToSpecificUser') {
                    label = getSelected(
                        userItems.find((user) => action.userId?.toString() == user.value)
                    );
                } else if (action.type === 'ForwardToGroup') {
                    label = getSelected(
                        groupItems.find((group) => action.groupId == group.value)
                    );
                } else if (action.type === 'ForwardToExternalNumber') {
                    label = getPhoneNumber(action.phoneNumber || '');
                } else if (action.type === 'PlayMessage') {
                    label = 'Play an audio and back to main menu';
                } else if (action.type === 'Receive') {
                    label = 'Receive call';
                }
                return { ...action, label };
            })
        );
    }, [callQueue, isLoading, isNewQueue, getValues, replace, setValue, adminGroupsData]);

    const ivrActions = callQueue?.ivrMenuOption.actions || [];
    useEffect(() => {
        const index = activeIndex as number;
        if (!synthText) return;
        updateAudioFiles(audioFiles, tempTtsFile, pendingUpload);
        if (pendingUpload === MessageType.MENU_MESSAGE) {
            setValue('ivrMenuOption.messageSettings.MENU_MESSAGE.bucketFileName', synthText?.bucketFileName);
            setValue('ivrMenuOption.messageSettings.MENU_MESSAGE.originalFileName', synthText?.originalFileName);
            setPendingUpload('');
            resetSynthText();
        }
        if (pendingUpload === MessageType.WELCOME_MESSAGE) {
            setValue('ivrMenuOption.messageSettings.WELCOME_MESSAGE.bucketFileName', synthText?.bucketFileName);
            setValue('ivrMenuOption.messageSettings.WELCOME_MESSAGE.originalFileName', synthText?.originalFileName);
            setPendingUpload('');
            resetSynthText();
        } if(pendingUpload.includes(MessageType.PLAY_MESSAGE)) {
            setValue(`ivrMenuOption.actions.${index}.messageSettings.bucketFileName`, synthText?.bucketFileName);
            setValue(`ivrMenuOption.actions.${index}.messageSettings.originalFileName`, synthText?.originalFileName);
            setValue(`ivrMenuOption.actions.${index}.label`, 'Play an audio and back to main menu');
            setValue(`ivrMenuOption.actions.${index}.type`, 'PlayMessage');
            setPendingUpload('');
            resetSynthText();
        }
    }, [pendingUpload]);


    const updateAudioFiles = (audioFiles: any, fileData: any, pendingUpload: string) => {
        if (!pendingUpload) return audioFiles;

        const updatedAudioFiles = { ...audioFiles };
        const audioUrl = fileData?.audioURL || fileData?.data;

        if (!fileData) return updatedAudioFiles;

        if (pendingUpload === MessageType.MENU_MESSAGE) {
            updatedAudioFiles.MENU_MESSAGE = audioUrl;
        } else if (pendingUpload === MessageType.WELCOME_MESSAGE) {
            updatedAudioFiles.WELCOME_MESSAGE = audioUrl;

        } else if (pendingUpload.startsWith(MessageType.PLAY_MESSAGE)) {
            const index = pendingUpload.split('_')[2];
            updatedAudioFiles.PLAY_MESSAGE = updatedAudioFiles.PLAY_MESSAGE || {};
            updatedAudioFiles.PLAY_MESSAGE[index] = audioUrl;
        }

        setAudioFiles(updatedAudioFiles);
    };

    useEffect(() => {
        const updatedAudioFiles = { ...audioFiles };
        if(greetingsAudioUrl) {
            updatedAudioFiles.WELCOME_MESSAGE = greetingsAudioUrl.data;
        }
        if(menuAudioUrl) {
            updatedAudioFiles.MENU_MESSAGE = menuAudioUrl.data;
        }
        setAudioFiles(updatedAudioFiles);
    }, [greetingsAudioUrl, menuAudioUrl]);


    useEffect(() => {
        if (!tempFile) return;
        const index = activeIndex as number;
        updateAudioFiles(audioFiles, tempFile, pendingUpload);

        if (pendingUpload == MessageType.MENU_MESSAGE) {
            setValue('ivrMenuOption.messageSettings.MENU_MESSAGE.bucketFileName', tempFile?.bucketFileName);
            setValue('ivrMenuOption.messageSettings.MENU_MESSAGE.originalFileName', tempFile?.originalFileName);
            setPendingUpload('');
            resetTempFile();
        }
        if (pendingUpload == MessageType.WELCOME_MESSAGE) {
            setValue('ivrMenuOption.messageSettings.WELCOME_MESSAGE.bucketFileName', tempFile?.bucketFileName); 
            setValue('ivrMenuOption.messageSettings.WELCOME_MESSAGE.originalFileName', tempFile?.originalFileName);
            setPendingUpload('');
            resetTempFile();
        }
        if(pendingUpload.includes(MessageType.PLAY_MESSAGE)) {
            setValue(`ivrMenuOption.actions.${index}.messageSettings.bucketFileName`, tempFile?.bucketFileName);
            setValue(`ivrMenuOption.actions.${index}.messageSettings.originalFileName`, tempFile?.originalFileName);
            setValue(`ivrMenuOption.actions.${index}.label`, 'Play an audio and back to main menu');
            setValue(`ivrMenuOption.actions.${index}.type`, 'PlayMessage');
            setPendingUpload('');
            resetTempFile();
        }
    }, [tempFile, pendingUpload]);


    const onSubmit = async (data: FormType) => {
        if(callQueueList?.find((queue) => queue.name == data.name && Number(queue.id) !== Number(id))) {
            setError('name', {
                type: 'manual',
                message: 'A queue with this name already exists.'
            });
            return;
        }
        if (data.ivrMenuOption.enabled && !data.ivrMenuOption.messageSettings.MENU_MESSAGE.originalFileName) {
            setError('ivrMenuOption.messageSettings.MENU_MESSAGE.originalFileName', {
                type: 'manual',
                message: 'Complete Phone tree configuration. Add a menu message.'
            });
            return;
        }
        const sanitizedData = removeEmptyFields({
            ...data,
            ivrMenuOption: {
                ...data.ivrMenuOption,
                actions: data.ivrMenuOption.actions.map(({ label, ...rest }) => ({
                    ...rest,
                })),
            },
        });

        if (isNewQueue) {
            await createQueue(sanitizedData);
        } else {
            await updateQueue({ id: Number(id), data: sanitizedData });
        }

        navigate('/admin/callQueues');
    };

    const handleSelect = (index: number) => {
        setActiveIndex(index);
    }


    const keyItems = [
            { "key": "1", "value": "1", "label": "1" },
            { "key": "2", "value": "2", "label": "2" },
            { "key": "3", "value": "3", "label": "3" },
            { "key": "4", "value": "4", "label": "4" },
            { "key": "5", "value": "5", "label": "5" },
            { "key": "6", "value": "6", "label": "6" },
            { "key": "7", "value": "7", "label": "7" },
            { "key": "8", "value": "8", "label": "8" },
            { "key": "9", "value": "9", "label": "9" },

    ]
    const handleAddOption = () => {
        append({ type: '', label: '', order: keyItems[fields.length - 1].value });
        clearErrors(`ivrMenuOption.actions.${fields.length}`); // Clear validation errors for the new field
    };
    const keysDropdownGroup = (index: number) => {
        return(
        [{
            callback: (value: any) => {
                setValue(`ivrMenuOption.actions.${index}.order`, value);
            },
            items: keyItems
        }])
    }

    const keyErrors = (order: string): string => {
        if (order === undefined || order === null || order === '') return 'Order is required';

        const orders = getValues('ivrMenuOption.actions')?.map((item) => item.order) || [];

        const occurrences = orders.filter((item) => item === order).length;
        return occurrences > 1 ? 'Option already exists' : '';
    };

    const dropdownItems = [
        {
            key: 'forward',
            label: 'Forward call',
            value: 'Forward call',
        },
        {
            key: 'receive',
            label: 'Receive call',
            value: 'Receive',
        },
        {
            key: 'message',
            label: 'Play an audio and back to main menu',
            value: 'PlayMessage',
        },
        ];

    const destinationDropdownGroup = (index: number) => {
        return ([{
            callback: (value: any) => {
                handleSelect(index);
                clearErrors(`ivrMenuOption.actions.${index}.type`);

                switch (value) {
                    case 'Forward call':
                        forwardModalRef.current?.openModal();
                        setModalValue(getValues(`ivrMenuOption.actions.${index}`));
                        break;
                    case 'Receive':
                        setValue(`ivrMenuOption.actions.${index}.label`, dropdownItems.find(item => item.value === 'Receive')?.label);
                        setValue(`ivrMenuOption.actions.${index}.type`, 'Receive');
                        break;
                    case 'PlayMessage':
                        playMessageRef.current?.openModal();

                        break;

                }
            },
            items: dropdownItems
        }])
    }




    const handleMenuMessage = (res: boolean) => {
        if(res) {
         setPendingUpload(MessageType.MENU_MESSAGE);
        }
    }

    const removeAction = (index: number) => {
        remove(index);
    }
    const handleGreetingsMessage = (res: boolean) => {
        if(res) {
         setPendingUpload(MessageType.WELCOME_MESSAGE);
        }
    }

        const handleForwardSelect = (res: any) => {
            if (res && activeIndex !== null) {
                Object.keys(modalValue).forEach((key) => {
                    setValue(`ivrMenuOption.actions.${activeIndex}.${key as keyof Action}`, modalValue[key]);
                });
            }
        }

    const handlePlayMessage = (res: boolean) => {
        if(res) {
            setPendingUpload(MessageType.PLAY_MESSAGE + '_' + activeIndex);
        }
    };

    const removeAudioFile = (type: string) => {
        setAudioFiles((prev: any) => ({
            ...prev,
            [type]: null,
        }));
    }

    if (isLoading) {
        return (
            <div className='flex w-full'>
                <Loader/>
            </div>
        );
    }


    return (
        <div className={`w-full h-[calc(100vh-var(--header-height))] overflow-y-auto flex flex-col w-full ${isDialerHidden ? ClosedDialerMargin : OpenDialerMargin}`}>
            <div className="flex flex-col w-full max-md:max-w-full">
                <div className="flex gap-8 pl-8 w-full border-b border-solid border-b-black max-md:pl-5">
                    <div className="flex flex-justify-center py-5   min-w-[280px] w-[280px]">
                        <div className="flex flex-col w-full">
                            <div className="text-sm font-medium leading-none text-zinc-950 max-md:max-w-full">
                                <label htmlFor="queueName"
                                       className="text-base font-semibold tracking-tight text-black">
                                    Queue name
                                </label>
                            </div>
                            <div className="mt-1.5 text-sm leading-5 text-neutral-500">
                                Enter a name to identify this call queue (e.g., Support Line, Sales Queue)
                            </div>

                        </div>
                    </div>
                    <div
                        className="flex  w-full  p-6 border-l border-solid border-l-zinc-200 border-zinc-200 min-w-[240px]">
                        <div className="flex flex-col min-h-[68px] w-full">
                            <Controller
                                name="name"
                                control={control}
                                rules={{required: 'Name is required'}}
                                render={({field}) => (
                                    <ChInput
                                        label={'Name'}
                                        placeholder="Enter queue name"
                                        value={field.value}
                                        callback={(value: any) => field.onChange(value)}
                                        error={errors.name ? errors.name.message : undefined}
                                        className="min-w-full py-3 px-4"/>
                                )}
                            />

                        </div>
                    </div>
                </div>
                <div
                    className="flex flex-wrap gap-8 pl-8 w-full border-b border-solid border-b-black max-md:pl-5 max-md:max-w-full">
                    <div className="flex flex-col justify-center py-5 min-w-[240px] w-[280px]">
                        <div className="flex flex-col w-full">
                            <div className="text-base font-semibold text-black">
                                Call routing strategy
                            </div>
                            <div className="mt-1.5 text-sm leading-5 text-neutral-500">
                                Select how calls are routed to agents in this queue.
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex overflow-hidden flex-col flex-1 shrink self-start border-l border-solid basis-0 border-l-zinc-200 border-zinc-200 min-w-[240px] max-md:max-w-full">
                        <div className="flex flex-col justify-center p-6 w-full max-md:px-5 max-md:max-w-full">
                            <div className="flex flex-col justify-center w-full max-md:max-w-full">
                                <ChRadio
                                    alignLeft={true}
                                    value={watch('ringStrategy')}
                                    callback={(value: string) => {
                                        setValue('ringStrategy', value)
                                    }} className={'flex-col'} radioGroup={routingOptions}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="flex flex-wrap gap-8 pl-8 w-full border-b border-solid border-b-black max-md:pl-5 max-md:max-w-full">
                    <div className="flex flex-col justify-center py-5 min-w-[240px] w-[280px]">
                        <div className="flex flex-col w-full">
                            <div className="text-base font-semibold text-black">
                                Phone tree
                            </div>
                            <div className="mt-1.5 text-sm leading-5 text-neutral-500">
                                Customize your phone tree menu.
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex overflow-hidden flex-col flex-1 shrink self-start border-l border-solid basis-0 border-l-zinc-200 border-zinc-200 min-w-[240px] max-md:max-w-full">
                        <div className="flex flex-row  ">
                            <div className='p-6 pl-3 grid w-full'>
                                <Controller
                                    name="ivrMenuOption.enabled"
                                    control={control}
                                    render={({field}) => (
                                        <Switch size='lg'
                                                checked={field.value}
                                                callback={() => {
                                                    setValue('ivrMenuOption.enabled', !field.value)

                                                }}
                                                label={'Activate phone menu'}
                                                description={'Enable to set up a phone menu fot this queue. '}/>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                { watch('ivrMenuOption.enabled') &&
                    <>
                        <div
                            className="flex flex-wrap gap-8 pl-8 w-full border-b border-solid border-b-black max-md:pl-5 max-md:max-w-full">
                            <div className="flex flex-col justify-center py-5 min-w-[240px] w-[280px]">

                            </div>
                            <div
                                className="flex overflow-hidden flex-col flex-1 shrink self-start border-l border-solid basis-0 border-l-zinc-200 border-zinc-200 min-w-[240px] max-md:max-w-full">
                                <div className="flex flex-row  ">
                                    <div className='p-6 pl-3 grid w-full'>
                                        <Controller
                                            name="ivrMenuOption.messageSettings.WELCOME_MESSAGE.originalFileName"
                                            control={control}
                                            render={({field}) => (
                                                <Switch size='lg'
                                                        checked={Boolean(field.value)}
                                                        callback={() => {
                                                            if (field.value) {
                                                                setValue('ivrMenuOption.messageSettings.WELCOME_MESSAGE.originalFileName', '')
                                                                removeAudioFile(MessageType.WELCOME_MESSAGE)
                                                            } else {
                                                                greetingsMessageRef.current?.openModal()
                                                            }
                                                        }}
                                                        label={'Greeting message'}
                                                        description={'Create a greeting message.'}/>
                                            )}
                                        />
                                    </div>
                                </div>
                                {(audioFiles?.WELCOME_MESSAGE && watch('ivrMenuOption.messageSettings.WELCOME_MESSAGE.originalFileName') )&&
                                    <div className='border border-black rounded-sm m-6 '>
                                        <div className={'flex justify-between items-center p-3'}>
                                            <div className="font-semibold text-md text-black flex items-center gap-2">
                                                <img
                                                    src={audioImg}/> Greeting message
                                            </div>
                                            <Button onClick={() => greetingsMessageRef?.current?.openModal()}
                                                    buttonType={'textSecondary'} label={'Change'}/>
                                        </div>

                                        <AudioPlayer src={audioFiles?.WELCOME_MESSAGE}/>

                                    </div>}
                            </div>
                        </div>

                        <div
                            className="flex flex-wrap items-start gap-8 pl-8 w-full border-b border-solid border-b-black max-md:pl-5 max-md:max-w-full">
                            <div className="flex flex-col justify-center py-5 min-w-[240px] w-[280px]">
                                <div className="flex flex-col w-full">
                                    <div className="text-base font-semibold text-black flex gap-2 mb-2 items-center">
                                        { Boolean(errors?.ivrMenuOption?.messageSettings?.MENU_MESSAGE?.originalFileName?.message) &&    <Icon iconName={'warning'} iconType={'destructive'} size={'md'}/>}
                                        Numeric options
                                    </div>
                                    { Boolean(errors?.ivrMenuOption?.messageSettings?.MENU_MESSAGE?.originalFileName?.message) &&
                                        <div className={'text-error-500'}>
                                            {errors?.ivrMenuOption?.messageSettings?.MENU_MESSAGE?.originalFileName?.message}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div
                                className="flex overflow-hidden flex-col flex-1 shrink self-start border-l border-solid basis-0 border-l-zinc-200 border-zinc-200 min-w-[240px] max-md:max-w-full p-6 pl-3">
                                <div className="flex flex-row  ">
                                    <div className='mb-6 grid w-full'>
                                        <ChModal
                                            closeButton={true}
                                            ref={greetingsMessageRef}
                                            callback={handleGreetingsMessage}
                                            title={'Create a welcome message'}
                                            content={<WelcomeMessage uploadFile={postRecordingPreview}
                                                                     synthetizeText={synthetizeText}
                                                                     uploadSynthText={postSynthTextPreview}
                                                                     welcomeMessageWorkingRef={greetingsMessageRef}
                                            />}
                                        />
                                        <ChModal
                                            closeButton={true}
                                            ref={menuMessageRef}
                                            callback={handleMenuMessage}
                                            title={'Create a welcome message'}
                                            content={<WelcomeMessage uploadFile={postRecordingPreview}
                                                                     synthetizeText={synthetizeText}
                                                                     uploadSynthText={postSynthTextPreview}
                                                                     welcomeMessageWorkingRef={menuMessageRef}
                                            />


                                            }
                                        /> <ChModal
                                        closeButton={true}
                                        ref={playMessageRef}
                                        callback={handlePlayMessage}
                                        title={'Create a welcome message'}
                                        content={<WelcomeMessage uploadFile={postRecordingPreview}
                                                                 synthetizeText={synthetizeText}
                                                                 uploadSynthText={postSynthTextPreview}
                                                                 welcomeMessageWorkingRef={playMessageRef}
                                        />}
                                    />
                                        <Controller
                                            name="ivrMenuOption.messageSettings.MENU_MESSAGE.originalFileName"
                                            control={control}
                                            render={({field}) => (
                                                <Switch size='lg'
                                                        checked={Boolean(field.value)}
                                                        callback={() => {
                                                            if (field.value) {
                                                                setValue('ivrMenuOption.messageSettings.MENU_MESSAGE.originalFileName', '')
                                                                removeAudioFile(MessageType.MENU_MESSAGE)
                                                            } else {
                                                                menuMessageRef.current?.openModal()
                                                            }
                                                        }}
                                                        error={Boolean(errors?.ivrMenuOption?.messageSettings?.MENU_MESSAGE?.originalFileName?.message)}
                                                        label={'Menu message'}
                                                        description={'Create a message that will be played to callers. For example: For customer service, press 1. For sales, press 2.'}/>
                                            )}
                                        />
                                    </div>
                                </div>
                                {(audioFiles?.MENU_MESSAGE && watch('ivrMenuOption.messageSettings.MENU_MESSAGE.originalFileName') ) &&
                                    <div className='border border-black rounded-sm ml-3'>
                                        <div className={'flex justify-between items-center p-3'}>
                                            <div className="font-semibold text-md text-black flex items-center gap-2">
                                                <img src={audioImg}/> Menu
                                                message
                                            </div>
                                            <Button onClick={() => menuMessageRef.current?.openModal()}
                                                    buttonType={'textSecondary'}
                                                    label={'Change'}/>
                                        </div>

                                        <AudioPlayer src={audioFiles?.MENU_MESSAGE}/>
                                    </div>}
                                <div className="flex flex-col pb-5 pl-3">
                                    <div className="font-semibold text-md text-black my-6">Menu option</div>
                                    {watch('ivrMenuOption.actions').map((_, index) => (
                                        <div>
                                            <div className="flex gap-6 items-baseline relative">
                                                <Controller
                                                    name={`ivrMenuOption.actions.${index}.order`}
                                                    control={control}
                                                    rules={{required: ''}}
                                                    render={({field}) => {
                                                        if(Number(field.value) == 0 && index == 0) {
                                                            return(
                                                                <>
                                                                <div className="flex flex-col relative pb-8">
                                                                <ChInput
                                                                    label={'Key'}
                                                                    tooltip={'The "0" keypad option is mandatory. It handles calls where the user doesn\'t select any other Menu option.'}
                                                                    placeholder="0"
                                                                    value={field.value}
                                                                    disabled={true}
                                                                    prefixElement={<Icon iconName="dialpad" className="w-5 h-5 p-0"></Icon>}
                                                                    callback={(value: any) => {
                                                                        field.onChange(value);
                                                                    }}
                                                                    error={errors?.ivrMenuOption?.actions?.[index]?.order?.message}
                                                                    className="w-full py-2 px-4 w-[100px]"
                                                                />
                                                                <div className="w-[300px] text-sm text-grey-500 absolute bottom-0 left-0">The option under number 0 is irremovable.
                                                                </div>
                                                                </div>
                                                                </>
                                                            )}
                                                        return(
                                                            <div className={'w-[100px]'}>
                                                                <label >Key</label>
                                                                <ChDropdown
                                                                    dropdownGroup={keysDropdownGroup(index)}
                                                                    icon={'dialpad'}
                                                                    width={100}
                                                                    errors={keyErrors(field.value)}
                                                                    selected={[watch(`ivrMenuOption.actions.${index}.order`)]}
                                                                    className="w-full py-2 px-4 max-w-[100px]"/>
                                                            </div>

                                                        )
                                                        
                                                    }}
                                                />
                                                

                                                <div className="w-full">
                                                    <div>Destination</div>
                                                    <div className="w-full">

                                                        <Controller
                                                            name={`ivrMenuOption.actions.${index}.type`}
                                                            control={control}
                                                            rules={{required: 'Destination is required'}}
                                                            render={() => (
                                                                <ChDropdown
                                                                    fit={true}
                                                                    placeholder={'Select action'}
                                                                    dropdownGroup={destinationDropdownGroup(index)}
                                                                    selected={[watch(`ivrMenuOption.actions.${index}.label`)]}
                                                                    errors={typeof errors?.ivrMenuOption?.actions?.[index]?.type === 'object'
                                                                        ? (errors?.ivrMenuOption?.actions?.[index]?.type as FieldError).message
                                                                        : undefined}
                                                                />
                                                            )}
                                                        />
                                                    </div>

                                                </div>

                                                {(fields.length > 1 && (index !== 0)) && <div onClick={() => removeAction(index)}>
                                                    <div className='flex relative top-8'>
                                                        <Icon iconName='delete' className='text-primary-400 cursor-pointer'
                                                              background={false}
                                                              iconType={'destructive'}/>
                                                    </div>


                                                </div>}

                                            </div>
                                            {audioFiles?.PLAY_MESSAGE?.[index] &&
                                                <div className='border border-black rounded-sm mt-4'>
                                                    <div className={'flex justify-between items-center p-3'}>
                                                        <div
                                                            className="font-semibold text-md text-black flex items-center gap-2">
                                                            <img
                                                                src={audioImg}/> An audio
                                                        </div>
                                                        <Button
                                                            onClick={() => greetingsMessageRef?.current?.openModal()}
                                                            buttonType={'textSecondary'} label={'Change'}/>
                                                    </div>

                                                    <AudioPlayer  src={audioFiles?.PLAY_MESSAGE?.[index]}/>

                                                </div>}
                                        </div>


                                    ))}

                                   
                                </div>

                                <div className='flex'>
                                    <Button className='pr-0' onClick={handleAddOption} size='small'
                                            buttonType='textPrimary'
                                            label='Add another option'
                                            leftIcon='add'/>
                                </div>
                            </div>

                        </div>
                    </>
                }
            </div>
            <div>

            </div>
            <div className='flex justify-between gap-5 m-5'>
                {
                    !isNewQueue && <DeleteCallQueueModal phoneNumbers={callQueuePhoneNumbers?.data} callQueue={callQueue}/>
                }
                <div className='flex gap-5 ml-auto'>
                    <Button buttonType={'secondary'} onClick={() => navigate('/admin/callQueues')} label={'Cancel'}/>
                    <Button buttonType={'primary'} onClick={handleSubmit(onSubmit)} label={isNewQueue ? 'Create queue' : 'Save queue'}/>
                </div>

            </div>
            <ChModal
                trigger={null}
                secondaryLabel='Cancel'
                primaryLabel='Confirm'
                callback={handleForwardSelect}
                ref={forwardModalRef}
                title={'Send calls to'}

                content={<ForwardModal phoneNumbersItems={phoneNumbersItems} userItems={userItems} groupItems={groupItems} modalData={modalValue}
                                       setModalData={setModalValue}/>}
            />
        </div>

    );
};
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from "../../baseQuery";
import { showToast } from "../Notifications/notificationsSlice";

export interface Queue {
    id: string | null;
    name: string;
    ringStrategy?: string;
    ivrMenuOption: Config;
}
export interface Action {
    order: string;
    type: string;
    userId?: number;
    groupId?: number;
    phoneNumberId?: number;
    messageSettings?: MessageFile;
    label?: any;
    phoneNumber?: string;
}

interface MessageFile {
    originalFileName: string;
    bucketFileName: string;
}

interface MessageSettings {
    WELCOME_MESSAGE: MessageFile;
    MENU_MESSAGE: MessageFile;
}

interface Config {
    enabled: boolean;
    actions: Action[];
    messageSettings: MessageSettings;
}
interface CreateQueueRequest {
    name: string;
    ringStrategy?: string;
    ivrMenuOption: Config;
}

export const callQueueApi = createApi({
    reducerPath: 'callQueueApi',
    baseQuery,
    tagTypes: ['Queue'],
    endpoints: (builder) => ({

        getQueues: builder.query<Queue[], void>({
            query: () => ({
                url: '/api/v1/queues',
                method: 'GET',
            }),
            transformResponse: (response: { data: Queue[] }) => response.data,
            providesTags: ['Queue'],
        }),

        getQueueById: builder.query<Queue, number>({
            query: (id) => ({
                url: `/api/v1/queues/${id}`,
                method: 'GET',
            }),
            providesTags: (_result, _error, id) => [{ type: 'Queue', id }],
        }),

        createQueue: builder.mutation<Queue, CreateQueueRequest>({
            query: (data) => ({
                url: '/api/v1/queues',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Queue'],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(showToast({ description: 'Call queue created successfully!', type: 'success' }));
                } catch {
                    dispatch(showToast({ description: 'Failed to create call queue!', type: 'error' }));
                }
            },
        }),

        updateQueue: builder.mutation<Queue, { id: number; data: Partial<CreateQueueRequest> }>({
            query: ({ id, data }) => ({
                url: `/api/v1/queues/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: (_result, _error, { id }) => [{ type: 'Queue', id }, 'Queue'],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(showToast({ description: 'Call queue updated successfully!', type: 'success' }));
                } catch {
                    dispatch(showToast({ description: 'Failed to update call queue!', type: 'error' }));
                }
            },

        }),
        uploadCallQueueSynthetizeText: builder.mutation<any, { text: string, gender: string, language: string }>({
            query: ({text, gender, language}) => ({
                url: `/api/v1/tts-speech`,
                method: 'PUT',
                body: {text, gender, language},
            }),
        }),
        deleteQueue: builder.mutation<{ success: boolean }, number>({
            query: (id) => ({
                url: `/api/v1/queues/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_result, _error, id) => [{ type: 'Queue', id }, 'Queue'],
        }),
        getQueuePhoneNumbers: builder.query<any, number>({
            query: (id) => ({
                url: `/api/v1/queues/${id}/phone-numbers`,
                method: 'GET',
            })
        }),
        postCallQueueRecordingPreview: builder.mutation<any, { id?: string; data: FormData; audioURL: string }>({
            query: ({id, data}) => ({
                url: `/api/v1/files`,
                method: 'POST',
                body: data,
                params: {id},
            }),
            transformResponse: (response: any, _meta: any, arg: { id: string; data: FormData; audioURL: string }) => {
                return {
                    ...response,
                    audioURL: arg.audioURL,
                };
            },
        }),
        getCallQueueRecording: builder.query<{ data: string; type: string }, { id: string | undefined; type: string }>({
            query: ({ id, type }) => ({
                url: `/api/v1/queues/${id}/message`,
                params: { type },
                responseHandler: async (response: any) => {
                    const arrayBuffer = await response.arrayBuffer();
                    return {
                        buffer: arrayBuffer,
                        headers: response.headers,
                    };
                },
            }),
            transformResponse: (response: { buffer: ArrayBuffer; headers: Headers }) => {
                const contentType = response.headers.get('content-type') || 'audio/mpeg';
                const blob = new Blob([response.buffer], { type: contentType });
                const audioUrl = URL.createObjectURL(blob);

                return {
                    data: audioUrl,
                    type: contentType,
                };
            },
            keepUnusedDataFor: 0,
        }),
        getPlayMessage: builder.query<{ data: string; type: string }, { id: string | undefined; order: number }>({
            query: ({ id, order }) => ({
                url: `/api/v1/queues/${id}/message?type=PLAY_MESSAGE_OPTION_${order}`,
                responseHandler: async (response: Response) => {
                    const arrayBuffer = await response.arrayBuffer();
                    return {
                        buffer: arrayBuffer,
                        headers: response.headers,
                    };
                },
            }),
            transformResponse: (response: { buffer: ArrayBuffer; headers: Headers }) => {
                if (!response.buffer || !response.headers) {
                    throw new Error('Invalid response structure');
                }
                const contentType = response.headers.get('content-type') || 'audio/mpeg';
                const blob = new Blob([response.buffer], { type: contentType });
                const audioUrl = URL.createObjectURL(blob);

                return {
                    data: audioUrl,
                    type: contentType,
                };
            },
            keepUnusedDataFor: 0,
        }),
    }),
});

export const {
    useUploadCallQueueSynthetizeTextMutation,
    usePostCallQueueRecordingPreviewMutation,
    useGetQueuesQuery,
    useGetQueueByIdQuery,
    useCreateQueueMutation,
    useUpdateQueueMutation,
    useDeleteQueueMutation,
    useGetQueuePhoneNumbersQuery,
    useGetCallQueueRecordingQuery,
    useLazyGetPlayMessageQuery
} = callQueueApi;

import { useMemo, useState} from "react";
import { ChDropdown } from "ch3-ui-lib";
import {PhoneNumberInput} from "../../components/Dialer/components/PhoneInput.tsx";
import {dropdownItem} from "../../utilis/types/Types.ts";
import {FlagImage} from "react-international-phone";
import {parsePhoneNumber} from "libphonenumber-js/max";
import {getCallingCountries} from "../../components/Dialer/services/countryDataService.ts";
import {getFormatedPhoneNumber} from "../../utilis/msisdnUtilis.ts";


export const forwardOptions = [
    { key: 'ForwardToInternalNumber', label: 'Forward to an internal number', value: 'ForwardToInternalNumber' },
    { key: 'ForwardToSpecificUser', label: 'Forward to a specific user', value: 'ForwardToSpecificUser' },
    { key: 'ForwardToGroup', label: 'Forward to a group', value: 'ForwardToGroup' },
    { key: 'ForwardToExternalNumber', label: 'Forward to an external number', value: 'ForwardToExternalNumber' },
    ]
interface ForwardModalProps {
    setModalData: Function;
    modalData: any;
    groupItems: dropdownItem[];
    userItems: dropdownItem[];
    phoneNumbersItems: dropdownItem[];
}
type ModalData =
    | { type: 'ForwardToGroup'; groupId?: string; label?: string }
    | { type: 'ForwardToSpecificUser'; userId?: string; label?: string }
    | { type: 'ForwardToInternalNumber'; phoneNumberId?: string }
    | { type: 'ForwardToExternalNumber', phoneNumber?: string }
    | { type: string };

export const ForwardModal = ({setModalData, modalData, userItems, groupItems, phoneNumbersItems}: ForwardModalProps) => {

    const [forwardOption, setForwardOption] = useState<{label: string, key: string, value: any} >(forwardOptions.find(el => modalData?.type === el.key) ||  ( { key: 'ForwardToInternalNumber', label: 'Forward to an internal number', value: 'ForwardToInternalNumber' }));

    function getCorrectData(value: any, selectedOption: { key: string }) {

        setModalData((prevModalData: ModalData) => {
            switch (selectedOption.key) {
                case 'ForwardToGroup':
                    return { ...prevModalData, type: forwardOption.value , groupId: value, label: getSelected(groupItems.find(group => value == group.value)) };
                case 'ForwardToSpecificUser':
                    return { ...prevModalData, type: forwardOption.value, userId: value, label: getSelected(userItems.find(user => value == user.value)) };
                case 'ForwardToInternalNumber':
                    return { ...prevModalData, type: forwardOption.value, phoneNumberId: value, label: getSelected(phoneNumbersItems.find(phone => value == phone.value)) };
                default:
                    return prevModalData;
            }
        });
    }

    const handleSelectSendTo = (value: any, selectedOption: { key: string }) => {

        getCorrectData(value, selectedOption);
    };

    const groupsDropdownGroup = useMemo(() => [{
        title: 'Groups',
        items: groupItems,
        callback: (value: any) => handleSelectSendTo(value, forwardOption),
    }], [groupItems, forwardOption]);

    const usersDropdownGroup = useMemo(() => [{
        title: 'Users',
        items: userItems,
        callback: (value: any) => handleSelectSendTo(value, forwardOption),
    }], [userItems, forwardOption]);

    const phoneNumbersDropdownGroup = useMemo(() => [{
        title: 'Phone numbers',
        items: phoneNumbersItems,
        callback: (value: any) => handleSelectSendTo(value, forwardOption),
    }] , [phoneNumbersItems, forwardOption]);

    const handleSelect = (value: any) => {
        setForwardOption((prevState) =>( forwardOptions.find(option => option?.value == value) || prevState) );
        setModalData({type: value});

    };

    const destinationDropdownGroup = useMemo(() => [{
        callback: handleSelect,
        items: [
            { key: 'ForwardToInternalNumber', label: 'Forward to an internal number', value: 'ForwardToInternalNumber' },
            { key: 'ForwardToSpecificUser', label: 'Forward to a specific user', value: 'ForwardToSpecificUser' },
            { key: 'ForwardToGroup', label: 'Forward to a group', value: 'ForwardToGroup' },
            { key: 'ForwardToExternalNumber', label: 'Forward to an external number', value: 'ForwardToExternalNumber' },
        ],
    }], []);

    const handleChange = (val: number) => {
        if (val.toString().length < 3)  {
            setModalData({phoneNumber: '', type: 'ForwardToExternalNumber'});
            return;
        }
        const parsedPhoneNumber = parsePhoneNumber(`+${val}`);
        const country = getCallingCountries().find(
            (country) => country.alpha2 === parsedPhoneNumber?.country);
        setModalData({phoneNumber: val, type: 'ForwardToExternalNumber', label:
                <div className={'flex items-center gap-2'}>
                    <span>
                   <FlagImage  size={30} iso2={country?.alpha2.toLowerCase()} className={'px-2'} />
                    </span>
                    {getFormatedPhoneNumber(val)}
                </div>
        });
    }

    const getSelected = (item: dropdownItem | undefined) => {
        return (
            <div className={'flex items-center gap-2'}>
                <span>
                      {item?.prefixElement}
                </span>
                {item?.label}

            </div>
        )
    }

    return (
        <div className={'w-[352px] mb-8'}>
            <div>
                <div className="text-sm text-gray-500 mt-2 mb-5">
                    Determine where calls should be directed
                </div>
                <div className="items-center mb-5">
                    <label className="text-sm mr-4">Send calls to</label>
                    <ChDropdown selected={[forwardOption.label]} width={352} placeholder={'Send calls to'} dropdownGroup={destinationDropdownGroup} />
                </div>
                {forwardOption.value === 'ForwardToGroup' && <div>
                    <label className="text-sm mr-4">Forward to</label>
                    <ChDropdown width={352} placeholder={'Choose group'} dropdownGroup={groupsDropdownGroup}
                                selected={[getSelected(groupItems.find((group)=> (modalData.groupId == group.value))) ]}/>
                </div>}
                {forwardOption.value === 'ForwardToSpecificUser' &&    <div>
                    <label className="text-sm mr-4">Forward to</label>
                    <ChDropdown
                        width={352}
                        placeholder={'Choose user'}
                        dropdownGroup={usersDropdownGroup}
                        selected={[getSelected(userItems.find((user) => modalData.userId == user.value))]}
                    />
                </div>}
                {forwardOption.value === 'ForwardToInternalNumber' &&    <div>
                    <label className="text-sm mr-4">Forward to</label>
                    <ChDropdown
                        width={352}
                        placeholder={'Choose phone number'}
                        dropdownGroup={phoneNumbersDropdownGroup}
                        selected={[getSelected(phoneNumbersItems.find((phone) => modalData.phoneNumberId == phone.value))]}
                    />
                </div>}
                { forwardOption.value === 'ForwardToExternalNumber' &&
                    <div>
                        <div className='text-sm text-gray-500 mt-2 mb-5'>
                            All incoming calls will be forwarded to this number.
                        </div>
                        <PhoneNumberInput phoneNumber={modalData?.phoneNumber}
                                          onPhoneNumberChange={(val) => handleChange(val)}/>
                    </div>
                }
            </div>
        </div>
    );
};

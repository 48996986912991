import {Controller, useForm} from "react-hook-form";
import {Button, ChModal, ChSearch, Icon, Switch} from "ch3-ui-lib";
import {getCallingCountries} from "../../../components/Dialer/services/countryDataService.ts";
import {FlagImage} from "react-international-phone";
import {useEffect, useState} from "react";
import {selectorItem} from "../../../utilis/types/Types.ts";
import { useSearchParams } from 'react-router-dom';
import {
    ContactsImportConfig, useDeleteIntegrationMutation,
    useGetPipedriveSettingsQuery, usePatchPipedriveIntegrationMutation
} from "../../../store/features/integrations/integrationsService.ts";
import {useNavigate} from "react-router-dom";
import {showToast} from "../../../store/features/Notifications/notificationsSlice.tsx";
import {useAppDispatch} from "../../../store/store.ts";

export const PipedriveSettings = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [phrase, setPhrase] = useState('');
    const [prefixItems, setPrefixItems] = useState<selectorItem[]>([]);
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');

    const {data: settings} = useGetPipedriveSettingsQuery({integrationId: Number(id)});
    const  [patchPipedriveIntegration] = usePatchPipedriveIntegrationMutation();
    const [deleteIntegrationMutation] = useDeleteIntegrationMutation();

    const {
        control,
        handleSubmit,
        setValue,
    } = useForm<ContactsImportConfig>({
        mode: 'onBlur',
        defaultValues: {

        id: 4,
        type: "CONTACTS_IMPORT",
        platform: "PIPEDRIVE",
        details: {
            type: "",
            companyName: "",
            companyId: ""
    },
        configuration:
        {
            type: "",
            sendRecordings: false,
            sendCallSummary: false,
            sendMissedCalls: true,
            defaultNumbersPrefix: "",
            sendCallTranscription: false
        }
    }
        },
    );

    useEffect(() => {

        setValue('configuration.sendRecordings', settings?.configuration.sendRecordings);
        setValue('configuration.sendCallSummary', settings?.configuration.sendCallSummary);
        setValue('configuration.type', settings?.platform.toLowerCase());
        setValue('configuration.sendMissedCalls', settings?.configuration.sendMissedCalls);
        setValue('configuration.defaultNumbersPrefix', settings?.configuration.defaultNumbersPrefix);
        setValue('configuration.sendCallTranscription', settings?.configuration.sendCallTranscription);

        setValue('details.type',  settings?.platform.toLowerCase());
        setValue('type', settings?.type);


    }, [settings]);

    const filteredCountries = getCallingCountries()
        .filter(
            (callingCountry) =>
                callingCountry.name &&
                (callingCountry.name.toLowerCase().includes(phrase.toLowerCase()) ||
                    callingCountry.countryCallingCodes[0].includes(phrase))
        )
        .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0));

    const items: selectorItem[] = filteredCountries.map((country) => {
        return {
            label: country.name,
            value: country,
            key: country.alpha2,
            prefixElement: <FlagImage size={24} iso2={country.alpha2.toLowerCase()} className={'ml-3'} />,
        };
    });
    const handlePhraseChange = (phrase: string) => {
        setPhrase(phrase);
    };

    useEffect(() => {
      const filteredItems =  items.filter((item) => item.label?.toLowerCase().includes(phrase.toLowerCase()));
        setPrefixItems(filteredItems);
    }, [phrase]);

    const  onSubmit = async (data: ContactsImportConfig) => {
        try {
            await patchPipedriveIntegration({integrationId: Number(id), body: data});
            dispatch(showToast({
                title: "Success",
                description: "Integration settings saved successfully.",
                type: "success",
            }));
            navigate('/admin/integrations/');
        } catch (error) {
            dispatch(showToast({
                title: "Error",
                description: "Failed to save settings.",
                type: "error",
            }));
        }

    }
    const disconnectIntegration = async () => {
        if (!id) return;

         try {
                await deleteIntegrationMutation({ integrationId: Number(id) });
                navigate('/admin/integrations/pipedrive/disconnected');

        } catch (error) {
            dispatch(showToast({
                title: "Error",
                description: "Failed to disconnect integration.",
                type: "error",
            }));
        }
    }

    return (
        <>
        <div className={'mx-auto max-w-[840px] my-10 flex flex-col gap-6 border-b'}>
            <div className='my-2 '>
                <Controller
                    name="configuration.sendRecordings"
                    control={control}
                    render={({field}) => (
                        <Switch size='lg' checked={field.value}
                                callback={() => field.onChange(!field.value)}
                                description={'Save audio recordings of calls in Pipedrive.'}
                                label={'Send recordings to Pipedrive'}
                                alignLeft={false}></Switch>
                    )}
                />

            </div>
            <div className='my-2'>
                <Controller
                    name="configuration.sendCallTranscription"
                    control={control}
                    render={({field}) => (
                        <Switch size='lg' checked={field.value}
                                callback={() => field.onChange(!field.value)}
                                description={'Automatically save the full conversation text in Pipedrive.'}
                                label={'Send transcript to Pipedrive'}
                                alignLeft={false}></Switch>
                    )}
                />

            </div>
            <div className='my-2'>
                <Controller
                    name="configuration.sendCallSummary"
                    control={control}
                    render={({field}) => (
                        <Switch size='lg' checked={field.value}
                                callback={() => field.onChange(!field.value)}
                                description={'Automatically save a short summary of the conversation in Pipedrive.'}
                                label={'Send summary to Pipedrive'}
                                alignLeft={false}></Switch>
                    )}
                />

            </div>
            <div className='my-2'>
                <Controller
                    name="configuration.sendMissedCalls"
                    control={control}
                    render={({field}) => (
                        <Switch size='lg' checked={field.value}
                                callback={() => field.onChange(!field.value)}
                                label={'Add missed calls to pipedrive'}
                                alignLeft={false}></Switch>
                    )}
                />

            </div>
            <div className={'flex gap-6 justify-between mb-6'}>
                <div className='font-semibold px-3'>
                    Default country prefix for imported person's phone numbers
                </div>
                <Controller
                    name="configuration.defaultNumbersPrefix"
                    control={control}
                    render={({field}) => (
                <ChSearch onInputChange={handlePhraseChange} onChange={(item) => field.onChange(item.key)} type={'text'} disabled={false} value={items.find((el) => el.key === field.value)?.label || ''} fit
                          label='Prefix'  placeholder='Search' items={prefixItems} prefixIcon={''}/>)} />

            </div>
        </div>
            <div className={'mx-auto max-w-[840px]'}>
                <div className='flex justify-between items-center'>
                    <div className='font-semibold text-lg'>
                        Pipdrive account
                    </div>
                    <ChModal
                        callback={(res: boolean) => {
                            if (res) {
                                disconnectIntegration();
                            }
                        }}
                        title={'Are you sure you want to disconnect it?'}
                        modalType={'destructive'}
                        trigger={
                            <Button
                                className='my-4'
                                buttonType='textError'
                                label={'Uninstall'}
                            />
                        }
                        content={
                            <div className={'py-4 mb-4 w-[400px] text-grey-500'}>
                                By disconnecting this integration, you will lose all the data and this action can't be undone.
                            </div>
                        }
                        secondaryLabel='Cancel'
                        primaryLabel='Yes, disconnect'
                    />
                </div>
                <div className={'bg-gray-100 py-2  px-4 inline-block'}>
                    {settings?.details.companyName}  {settings?.details.companyId}
                </div>
                <div className={'my-6 flex items-center '}>
                    <div className={'min-w-[500px]'}>
                        You can also read about integration in ou Knowledge Base Article
                    </div>
                    <div className={'w-full'}>
                        <a className={'flex items-center justify-between'} target='_blank'
                           href={'https://support.phonehq.com/en/collections/5-pipedrive'}><span
                            className='text-primary-400 underline'>Knowledge Base Article</span> <Icon
                            iconName={'open_in_new'}/> </a>
                    </div>

                </div>
                <div className='flex justify-end'>
                    <Button label={'Done'} buttonType={'primary'} onClick={handleSubmit(onSubmit)}/>

                </div>

            </div>
        </>
    )
}
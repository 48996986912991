import { createSlice } from '@reduxjs/toolkit';
import {RootState} from "../../store";


type ToastType = 'info' | 'success' | 'warning' | 'error';
interface ToastState {
    title: string;
    description: string;
    type: ToastType;
    isOpen: boolean;
    dontHide: boolean;
    position: 'center' | 'default';
    icon: string | undefined
}

const initialState: ToastState = {
    title: '',
    description: '',
    type: 'info',
    isOpen: false,
    dontHide: false,
    position: 'default',
    icon: undefined
};

const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        showToast: (state, action) => {
            state.title = action.payload.title;
            state.description = action.payload.description;
            state.type = action.payload.type;
            state.isOpen = true;
            state.dontHide = action.payload.dontHide || false;
            state.position = action.payload.position || 'default';
            state.icon = action.payload?.icon
        },
        hideToast: (state) => {
            state.isOpen = false;
        },
    },
});

export const { showToast, hideToast } = toastSlice.actions;
export const getToast = (state: RootState) =>
    state.toast;
export default toastSlice.reducer;

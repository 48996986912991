import {getCallTypeDescription} from "../../utilis/callsUtilis";
import {useEffect, useState} from "react";
import {Call} from "../../store/features/calls/callsService";


interface CallTimerProps {
    call: Call;
}

const CallTimer = ({ call }: CallTimerProps) => {
    const [elapsedTime, setElapsedTime] = useState(0);

    const formattedTime = (timer: number) => {
        const minutes = Math.floor(timer / 60);
        const seconds = timer % 60;
        return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    };

    useEffect(() => {
        let interval: number;

        if (call.bridgetAt) {
            const bridgetAtTime = new Date(call.bridgetAt).getTime();

            const now = new Date().getTime();
            const initialElapsedTime = Math.floor((now - bridgetAtTime) / 1000);
            setElapsedTime(initialElapsedTime);

            interval = setInterval(() => {
                const updatedNow = new Date().getTime();
                const updatedElapsedTime = Math.floor((updatedNow - bridgetAtTime) / 1000);
                setElapsedTime(updatedElapsedTime);
            }, 1000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [call.bridgetAt]);

    return (
        <p className="text-xs text-error-600">
            {getCallTypeDescription(call.type, call.bridgetAt, call.forwardedTo)} · {formattedTime(elapsedTime)}
        </p>
    );
};

export default CallTimer;

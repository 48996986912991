import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
    token: string | null;
    tenant: string | null;
    userId: string | null;
    role: string | null;
}

const initialState: AuthState = {
    token: null,
    tenant: null,
    userId: null,
    role: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthData: (state, action: PayloadAction<{ token: string; tenant: string; userId: string, role:string }>) => {
            state.token = action.payload.token;
            state.tenant = action.payload.tenant;
            state.userId = action.payload.userId;
            state.role = action.payload.role;
        },
        clearAuthData: (state) => {
            state.token = null;
            state.tenant = null;
            state.userId = null;
        },
    },
});

export const { setAuthData, clearAuthData } = authSlice.actions;
export const selectUserId = (state: { auth: { userId: string | null } }) => state.auth.userId;
export const selectTenant = (state: { auth: { tenant: string | null } }) => state.auth.tenant;
export default authSlice.reducer;

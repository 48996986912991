import {useKeycloak} from "@react-keycloak/web";
import React, {useEffect, useState} from "react";
import {
    BrowserRouter,
    Route,
    Routes,
    useLocation,
} from "react-router-dom";
import ChSidebar from "./components/sidebar/Sidebar.tsx";
import {logo, translateSections} from "./utilis/menuItems.tsx";
import Calls from "./pages/Calls/Calls.tsx";
import ContactsList from "./pages/Contacts/Contacts.tsx";
import AddEditContact from "./pages/Contacts/AddEditContact.tsx";
import ContactDetails from "./pages/Contacts/ContactDetails.tsx";
import TeamList from "./pages/Team/TeamList.tsx";
import GroupList from "./pages/Groups/GroupList.tsx";
import GroupDetails from "./pages/Groups/GroupDetails.tsx";
import {useDispatch, useSelector} from "react-redux";
import {getIsDialerHidden, setIsInCallsAction, toggleDialerHidden} from "./store/features/calls/dialerSlice.ts";
import {jwtDecode} from "jwt-decode";
import {DialerWrapper} from "./components/Dialer/DialerWrapper";
import PhoneNumberSettings from "./pages/PhoneNumbers/PhoneNumberSettings";
import {PhoneNumbers} from "./pages/PhoneNumbers/PhoneNumbers";
import {sipUserAgent} from "./components/Dialer/services/sipService";
import useMediaQuery from "./utilis/screenWidthUtils.ts";
import ToastNotification from "./components/Notifications/Notifications";
import ProfileSettings from "./pages/ProfileSettings/ProfileSettings";
import {clearAuthData, setAuthData} from "./store/features/Auth/authSlice";
import RecordingDownload from "./pages/Recordings/GetRecording";
import {ThemeProvider} from "ch3-ui-lib";
import {setGlobalTheme} from "./store/features/ThemeSwitcher/themeSwitcherSlice";
import {useAppDispatch} from "./store/store";
import Integrations from "./pages/Integrations/Integrations";
import {Integration} from "./pages/Integrations/integration";
import {CallQueues} from "./pages/CallQueues/CallQueue";
import {CallQueuesList} from "./pages/CallQueues/CallQueueList";
import Header from "./components/header/Header";
import {ClosedDialerMargin, OpenDialerMargin} from "./utilis/dialerWidthUtils";
import {BillingReport} from "./pages/Report/BIllingReport";
import OnlineStatusDetector from "./components/OnlineOfflineDetector/OnlineOfflineDetector.tsx";
import Gleap from "gleap";


function App() {
    const dispatch = useAppDispatch();
    const [isInCalls, setIsInCalls] = useState(false);
    const [isAuthSaved, setIsAuthSaved] = useState(false);
    const isMobile = useMediaQuery('(max-width: 960px)');

    const {keycloak, initialized} = useKeycloak();
    dispatch(setGlobalTheme('light')); // TODO: Change after implementing dark mode

    // const theme = useSelector((state: any) => state.themeSwitcher.theme)   // TODO: Change after implementing dark mode

    if (import.meta.env.MODE == 'production' && window.__ENV__?.GLEAP_ID) {
        Gleap.initialize(window.__ENV__?.GLEAP_ID)
    }

    useEffect(() => {
        if (keycloak.authenticated && initialized) {
            console.log("Authenticated");
            const decodedToken: { tenant: string, userId: string, given_name: string, family_name: string,  resource_access: any  } = jwtDecode(keycloak.token!);
            dispatch(setAuthData({ token:`${keycloak.token}`, tenant: decodedToken.tenant, userId: decodedToken.userId, role: decodedToken.resource_access['backend-service']?.roles[0] }));
            localStorage.setItem("role", decodedToken.resource_access['backend-service']?.roles[0]);


            console.log('decoded token', decodedToken);
            setIsAuthSaved(true)


        }
        keycloak.onAuthRefreshSuccess = () => {
            console.log("Auth refresh success");
            const decodedToken: { tenant: string, userId: string,  resource_access: any } = jwtDecode(keycloak.token!);
            console.log('decoded token', decodedToken);
            dispatch(setAuthData({ token:`${keycloak.token}`,userId: decodedToken.userId ,  tenant: decodedToken.tenant, role: decodedToken.resource_access['backend-service']?.roles[0] }));
            sipUserAgent?.set('authorization_jwt', keycloak.token);
        }
        keycloak.onTokenExpired = () => {
            console.log("Token expired, refreshing...");
            keycloak.updateToken(30).then(() => {
                console.log("Token refreshed");
                const decodedToken: { tenant: string, userId: string,  resource_access: any } = jwtDecode(keycloak.token!);
                console.log('decoded token', decodedToken);
                dispatch(setAuthData({ token:`${keycloak.token}`,userId: decodedToken.userId, tenant: decodedToken.tenant, role: decodedToken.resource_access['backend-service']?.roles[0] }));
                sipUserAgent?.set('authorization_jwt', keycloak.token);
            }).catch(() => {
                console.log("Token refresh failed");
                keycloak.logout();
            });
        }

        keycloak.onAuthLogout = () => {
            dispatch(clearAuthData());
            localStorage.removeItem("userId");

        }
    }, [keycloak, initialized]);

    window.addEventListener("beforeunload", () => {
        if (sipUserAgent) {
            sipUserAgent.stop();
        }
    })

    if (!initialized) {
        return <div className="h-screen flex justify-center items-center">
            <div
                className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
                <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
            </div>
        </div>;
    }

    if (!keycloak.authenticated) {
        return <div>Not authenticated</div>;
    }

    if (!isAuthSaved) {
        return <div>Saving auth...</div>;
    }

    return (
        <>

            <ThemeProvider globalTheme={'light'}>
                        <React.StrictMode>
                            <div  className="flex flex-col min-h-screen overflow-hidden">
                                <OnlineStatusDetector/>
                                <BrowserRouter>
                                    <Header/>
                                    <ChSidebar
                                    logo={logo}
                                    sections={translateSections()}
                                />
                                <MainContentWrapper
                                    className={` flex flex-1  ` + `${isMobile ? ' ml-[80px]' : ' ml-[272px]'} `}
                                    setIsInCalls={setIsInCalls}
                                >
                                    <div className='w-full flex-grow relative '>
                                    <Routes>
                                        <Route path="/calls" element={<Calls/>}/>
                                        <Route path="/contacts" element={<ContactsList/>}/>
                                        <Route path="/contacts/:id" element={<AddEditContact/>}/>
                                        <Route path="/contacts/new" element={<AddEditContact/>}/>
                                        <Route
                                            path="/contacts/details/:id"
                                            element={<ContactDetails/>}
                                        />
                                        <Route path="/team" element={<TeamList/>}/>
                                        <Route path="/groups" element={<GroupList admin={false}/>}/>
                                        <Route
                                            path="/groups/details/:id"
                                            element={<GroupDetails admin={false}/>}
                                        />
                                        <Route
                                            path="/groups/details/new"
                                            element={<GroupDetails admin={false}/>}
                                        />
                                        <Route
                                            path="/phoneNumbers"
                                            element={<PhoneNumbers/>}
                                        />
                                        <Route
                                            path="/phoneNumbers/:id"
                                            element={<PhoneNumberSettings admin={false} />}
                                        />
                                        <Route
                                            path="/profile"
                                            element={<ProfileSettings/>}
                                        />
                                        <Route
                                            path="/recordings/:id"
                                            element={<RecordingDownload/>}
                                        />
                                        <Route
                                            path="/admin/integrations"
                                            element={<Integrations/>}/>
                                        <Route
                                            path="/admin/integrations/:id"
                                            element={<Integration/>}/>
                                        <Route
                                            path="/admin/integrations/:id/:step"
                                            element={<Integration/>}/>

                                        <Route
                                            path="/admin/report"
                                            element={ <BillingReport/>}/>
                                        <Route
                                            path="/admin/callQueues"
                                            element={<CallQueuesList/>}/>
                                        <Route
                                            path="/admin/callQueues/:id"
                                            element={<CallQueues/>}/>
                                        <Route
                                            path="/admin/phoneNumbers"
                                            element={<PhoneNumbers admin={true}/>}
                                        />
                                        <Route
                                            path="/admin/phoneNumbers/:id"
                                            element={<PhoneNumberSettings admin={true} />}
                                        />
                                        <Route path="/admin/groups" element={<GroupList admin={true} />}/>
                                        <Route
                                            path="/admin/groups/details/:id"
                                            element={<GroupDetails admin={true}/>}
                                        />
                                        <Route
                                            path="/admin/groups/details/new"
                                            element={<GroupDetails admin={true}/>}
                                        />


                                        <Route path="*" element={<Calls/>}/>

                                    </Routes>
                            </div>
                                </MainContentWrapper>
                                </BrowserRouter>
                                <div
                                    id="dialer"
                                    className="absolute top-[var(--header-height)] right-0 "
                                >
                                <DialerWrapper isInCalls={isInCalls}/>
                                </div>
                            </div>
                        </React.StrictMode>
                <ToastNotification/>
            </ThemeProvider>


        </>
    );
}

function MainContentWrapper({
                                children,
                                setIsInCalls,
                                className,
                            }: {
    children: React.ReactNode;
    setIsInCalls: React.Dispatch<React.SetStateAction<boolean>>;
    className: string;
}) {
    const dispatch = useDispatch();
    const location = useLocation();

    const isDialerHidden = useSelector(getIsDialerHidden);

    React.useEffect(() => {
        const isCallsPath = location.pathname.includes('calls');

        if (isCallsPath && isDialerHidden) {
            dispatch(toggleDialerHidden());
        }
        dispatch(setIsInCallsAction(isCallsPath));
        setIsInCalls(isCallsPath);
    }, [location]);


    return <div className={className + `mr-3  ${isDialerHidden ? ClosedDialerMargin : OpenDialerMargin}`}>{children}</div>;

}

export default App;

export const removeEmptyFields = (data: any): any => {
    if (Array.isArray(data)) {
        const filteredArray = data
            .map(removeEmptyFields)
            .filter(item => item !== "" && item !== null && item !== undefined);

        return filteredArray.length > 0 ? filteredArray : undefined;
    } else if (typeof data === "object" && data !== null) {
        const cleanedData: any = Object.fromEntries(
            Object.entries(data)
                .map(([key, value]) => [key, removeEmptyFields(value)])
                .filter(([_, value]) => value !== undefined && value !== null && value !== "")
        );

        return Object.keys(cleanedData).length > 0 ? cleanedData : undefined;
    }

    return data !== "" && data !== null && data !== undefined ? data : undefined;
};
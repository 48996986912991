import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {navigateDialer, setIsOnlineOffline} from "../../store/features/calls/dialerSlice.ts";
import {hideToast, showToast} from "../../store/features/Notifications/notificationsSlice.tsx";

const OnlineStatusDetector = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const handleOffline = () => {
            dispatch(setIsOnlineOffline(true));
            dispatch(showToast({
                description: 'You are using the application offline',
                type: 'error',
                dontHide: true,
                position: 'center',
                icon: 'cloud_off'
            }));
            dispatch(navigateDialer('/error/no-internet'));
        };

        const handleOnline = () => {
            dispatch(setIsOnlineOffline(false));
            dispatch(hideToast());
        };

        window.addEventListener('offline', handleOffline);
        window.addEventListener('online', handleOnline);

        return () => {
            window.removeEventListener('offline', handleOffline);
            window.removeEventListener('online', handleOnline);
        };
    }, [dispatch]);

    return null;
};

export default OnlineStatusDetector;
import {Icon} from "ch3-ui-lib";


export const    PipedriveDescription = () => {

    const pipedrive = {
        startText: "Phonehq for Pipedrive is all about making your sales call process more efficient and convenient.",
        description: "This integration will reduce all the mundane tasks to the bare minimum while making your chances of closing every sales call soar.",
        features: [
            'Have every call saved as a new event to a specific person with all corresponding details',
            'Handle one-click calls right from Pipedrive using Channels Chrome extension',
            'Build a reliable calling system to effortlessly close leads',
            'Identify each caller using the Customer Card right at the beginning of each call',
            'Record all of your phone calls'],
      issues: 'If you encounter any issues, Contact PhoneHQ Support.',
        knowladgeBase: 'You can also check detailed instruction in our Knowledge Base Article.',

    }
    return (
        <div className="flex flex-col mb-8 w-full ">
            <p className="text-base leading-6 w-full  mb-6">{pipedrive.startText}</p>
            <p className="text-base leading-6 w-full  mb-6">{pipedrive.description}</p>
            <ul className="list-disc pl-6 mb-8">
                {pipedrive.features.map((feature, index) => (
                    <li key={index} className="mb-2.5">
                        <p className="flex items-center">{feature}</p>
                    </li>
                ))}
            </ul>
            <p className="text-base leading-6 w-full  mb-6">{pipedrive.issues}</p>
            <div className="mb-4 flex items-center gap-2">{pipedrive.knowladgeBase}
                <a className={'flex items-center '} target='_blank'
                   href={'https://support.phonehq.com/en/collections/5-pipedrive'}><span
                    className='text-primary-400 underline'>Knowledge Base Article</span> <Icon
                    iconName={'open_in_new'}/> </a></div>
        </div>
    );
}

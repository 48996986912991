import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useStartIntegrationPipedriveMutation} from "../../../store/features/integrations/integrationsService.ts";
import {Button, Loader, Icon} from "ch3-ui-lib";
import {useNavigate} from "react-router-dom";

export const PipdriveAutomation = () => {

    const [searchParams] = useSearchParams();
    const code = searchParams.get('code') || '';
    const [startIntegration, {error: integrationError}] = useStartIntegrationPipedriveMutation();
    const [result, setResult] = useState<number | undefined>(undefined);
    const [error, setError] = useState<string | null>(null);

    const startIntegrationPipedrive = async (code: string ) => {
        if (!code) {
            setError('Invalid code');
        }
        try {
            const res = await startIntegration({code});
            setResult(res?.data?.integrationId);
        } catch (e) {
            console.error(e);
        }


    }

    const navigate = useNavigate();
    useEffect(() => {

        startIntegrationPipedrive(code);
    }, [code, startIntegration]);

    useEffect(() => {
        if (integrationError && 'data' in integrationError) {
            setError((integrationError.data as Error)?.message || 'Server error occurred');
        }
    }, [integrationError]);

    return (
        <>
            {
                error && <div
                    className={'flex  justify-between  mx-auto h-full bg-error-50 max-w-[577px] p-4 gap-1 border border-error-400 mb-8'}>
                    <div className={'flex gap-4 items-center'}>
                        <Icon size='lg' iconName={'error'} className='text-red-500'/>
                        <div>
                            <p className='font-medium flex justify-between items-center'>Unexpected error</p>
                            <p className={'text-sm '}>
                                {error}
                            </p>
                        </div>

                    </div>
                    <div className='cursor-pointer' onClick={() => {
                        startIntegrationPipedrive(code);
                        setError(null)
                    }}>
                        <Icon iconName={'close'}/>
                    </div>


                </div>
            }
            {!result && <div className='flex flex-col items-center  h-full'>
                <h5 className='font-semibold mb-4'>Pipedrive Integration in Progress</h5>
                <p className='mb-8'>Your Pipedrive integration is being set up. You'll be notified when it's
                    complete.</p>
                {!error &&
                    <>
                        <div className={'flex items-center h-10'}>
                            <Loader className={'h-10 my-5 text-primary-500'}/>
                        </div>

                        <p> Loading...</p>
                    </>
                }
            </div>}

            {result && <div className='flex flex-col items-center  h-full'>
                <h5 className='font-semibold mb-4'>Pipedrive Integration Successful!</h5>
                <div className="max-w-[400px] mb-14 flex flex-col items-center">
                    <p >Your accounts are now connected.</p>
                        <p>You can begin using the integrated features.</p>
                    </div>
                <Button label={'Go to settings'} buttonType={'primary'}
                        onClick={() => navigate(`/admin/integrations/pipedrive/settings?id=${result}`)}/>
            </div>}
        </>

    )
}
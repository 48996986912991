import {Button} from "ch3-ui-lib";
import {Link} from "react-router-dom";
import {GoogleDescription} from "./Google/GoogleDescription";
import {useParams} from "react-router-dom";
import {PipedriveDescription} from "./Pipedrive/PipedriveDescription";
import pipedriveIntegration from "../../assets/pipedriveIntegration.svg";
import googleIntegration from "../../assets/googleIntegration.svg";
import {IntegrationStep} from "./integration";

interface Integration {
    component: JSX.Element;
    img: string;
    link: string;
    target?: string;
}
function copyToClipBoard(text: any) {
    navigator.clipboard.writeText(text);
}



export const IntegrationDescription = ({
                                           buttonLabel,
                                       }: IntegrationStep) => {
    const {id} = useParams<{ id: string }>();

    const integrations: Record<string, Integration> = {
        google: {
            component: <GoogleDescription copyToClipBoard={copyToClipBoard}/>,
            img: googleIntegration,
            link: 'connect'
        },
        pipedrive: {
            component: <PipedriveDescription />,
            img: pipedriveIntegration,
            link: window.__ENV__?.PIPEDRIVE_INSTALL_URL || ''

        }
    };

    if (!id || !integrations[id]) {
        return null;
    }

    return (
        <>
            {id && <div className='flex gap-8'>
                <img src={integrations[id].img} alt={id} className="object-contain w-[40%] max-w-[400px]" />
                <section className="flex flex-col min-w-[240px] pr-8">
                    <div className="flex flex-col w-full text-black">
                        {integrations[id].component}
                        <div className='flex justify-end'>
                            <Link target={integrations[id]?.target} to={integrations[id].link}>
                                <Button className='' buttonType='primary' label={buttonLabel} />
                            </Link>
                        </div>
                    </div>
                </section>
            </div>}
        </>
    );
}
import { IntegrationDescription } from "./IntegrationDescription";
import { useParams } from "react-router-dom";
import useMediaQuery from "../../utilis/screenWidthUtils";
import {Button, Icon} from "ch3-ui-lib";
import google from "../../assets/google.svg";
import pipedrive from "../../assets/pipedrive.svg";
import { IntegrationGoogle } from "./Google/IntegrationGoogle";
import { UserList } from "./Users/UserList";
import {useNavigate} from 'react-router-dom';
import {
    GoogleIntegrationResponse,
    useStartIntegrationGoogleMutation
} from "../../store/features/integrations/integrationsService";
import {PipdriveAutomation} from "./Pipedrive/PipedriveAutomation.tsx";
import {PipedriveSettings} from "./Pipedrive/PipedriveSettings.tsx";
import {GoogleDisconnected, PipedriveDisconnected} from "./Google/IntegrationDisconnected.tsx";

type IntegrationKey = "google" | "pipedrive";

export interface IntegrationStep {
    integrationName?: string;
    instructionText?: string | JSX.Element;
    buttonLabel?: string;
}
interface IntegrationDescriptionProps extends IntegrationStep {
    google: IntegrationStep;
    pipedrive: IntegrationStep;
}
const syncSteps: IntegrationDescriptionProps = {
    google: {
        integrationName: "Google Contacts",
        buttonLabel: "I've completed all the steps, proceed",
        instructionText: "You can also check detailed instruction in our Knowledge Base Article."
    },
    pipedrive: {
        integrationName: "Pipedrive",
        buttonLabel: "Install now",
        instructionText: <div>You can also check detailed instruction in our."<span className='text-primary-400'>Knowledge Base Article</span><Icon size='xxs' iconName='open_in_new'/>,</div>
    }
};

export const Integration = () => {

    const { id, step } = useParams<{ id?: IntegrationKey; step?: "info" | "connect" | "list" | "disconnected" | "settings"  }>();
    const isMobile = useMediaQuery("(max-width: 960px)");

    const navigate = useNavigate();
    const [startIntegrationGoogle, { data, isLoading}] = useStartIntegrationGoogleMutation();


    const steps: { pipedrive: { info: JSX.Element, connect: JSX.Element, settings: JSX.Element, disconnected: JSX.Element }; google: { list: JSX.Element; connect: JSX.Element; info: JSX.Element, disconnected: JSX.Element } } = {
        google: {
            info: <IntegrationDescription {...syncSteps["google"]} />,
            connect: <IntegrationGoogle  startIntegrationGoogle={startIntegrationGoogle} isLoading={isLoading}  />,
            list: <UserList integrationRes={data as GoogleIntegrationResponse}/>,
            disconnected: <GoogleDisconnected />
        },
        pipedrive: {
            info: <IntegrationDescription {...syncSteps["pipedrive"]} />,
            connect: <PipdriveAutomation />,
            settings: <PipedriveSettings />,
            disconnected: <PipedriveDisconnected />


        }
    };

    const currentId = id ?? "google";
    const currentStep = step ?? "info";

    return (
        <div className={`w-full h-[calc(100vh-var(--header-height))] overflow-y-auto`}>
            <div className="flex gap-4 items-center z-40 relative pt-8 px-8 w-[30%]"  onClick={() => navigate('/admin/integrations')}>
                {/* {currentId == 'google' && <img src={google} alt="Google Logo"/>} */}
                <Button size="small" className="pl-1" buttonType="secondary" rightIcon="arrow_back_ios" />
                {currentId == 'google' && <img src={google} alt="Google Logo"/>}
                {currentId == 'pipedrive' && <img src={pipedrive} alt="Pipedrive Logo"/>}

                <div className={"font-bold text-xl"}>{syncSteps[currentId].integrationName}</div>
            </div>
            <div
                className={
                    ` px-8 py-10  ` +
                    `${isMobile ? "px-4 flex-col" :  " flex-row" } ` +
                    "  gap-8 items-start "
                }
            >
                {steps[currentId]?.[currentStep as keyof typeof steps[typeof currentId]]}
            </div>
        </div>
    );
};

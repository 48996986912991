import {Button, ChInput} from "ch3-ui-lib";
import {Controller, useForm} from "react-hook-form";
import {validateEmail} from "../../../utilis/commonValidators";
import { useNavigate } from "react-router-dom";
import {useAppDispatch} from "../../../store/store.ts";
import {showToast} from "../../../store/features/Notifications/notificationsSlice.tsx";

interface IntegrationGoogleProps {
    startIntegrationGoogle: Function;
    isLoading: boolean;

}

export const IntegrationGoogle = ({startIntegrationGoogle, isLoading}: IntegrationGoogleProps) => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onBlur',
        defaultValues: {
            email: '',
           },
    });



    const onSubmit = async (data: any) => {

        try {
            await startIntegrationGoogle({ email: data.email }).unwrap();
            navigate('/admin/integrations/google/list');
        } catch (err: any) {
            dispatch(showToast({title:'Unable to display integrations.' , description:'Please try again later, or contact support if the issue persists.', type: 'error' }))
            navigate('/admin/integrations');
        }
    };
    return (
        <main className='mx-auto max-w-[580px]'>
                <h2 className="text-2xl font-bold leading-none">Connect Your Google Account</h2>
                <p className="mt-4 text-base leading-6 mb-8">
                    Enter your Google email to sync contacts with phoneHQ and keep your address book up-to-date.
                </p>
            <Controller
                name="email"
                control={control}
                rules={{
                    required: 'Email is required',
                    validate: {
                        isValid: (value: any) => validateEmail(value) || 'Invalid email address',
                    },
                }}
                render={({ field }) => (
                    <ChInput
                        {...field}
                        value={field.value}
                        callback={(value:any) => field.onChange(value)}
                        className='max-w-full p-4'
                        prefixIcon="mail_outline"
                        label='E-mail'
                        error={errors.email ? errors.email.message : undefined}
                    />
                )}
            />

            <Button loading={isLoading} onClick={handleSubmit(onSubmit)} label={'Begin Sync'} buttonType={'primary'} className='ml-auto mt-8 min-w-[140px] h-11' />

        </main>
    );
}

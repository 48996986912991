import * as JsSIP from "jssip";
import {v4 as uuidv4} from 'uuid';
import {RTCSession} from "jssip/lib/RTCSession";
import ringtoneSound from '../../../assets/sounds/ring.mp3';
import {setCallFrom, setCallState, setIncomingCall, setSipInstanceId} from "../../../store/features/calls/dialerSlice";
import {CallStateEnum} from "../Dialer";
import logo from '../../../assets/logo.svg';
import {AppDispatch, store} from "../../../store/store";
import disconnectSound from '../../../assets/sounds/disconnected.mp3';

export let sipUserAgent: JsSIP.UA | null = null;
let session: RTCSession | null = null;
const audioRef = new Audio();
const ringingAudioRef = new Audio(ringtoneSound);
const disconnectAudioRef = new Audio(disconnectSound);

const getSipInstanceId = (savedSipInstanceId: string | null, dispatch: AppDispatch) => {

    if (savedSipInstanceId) {
        return savedSipInstanceId;
    }

    const newSipInstanceId = `${uuidv4()}`.toLowerCase();

    dispatch(setSipInstanceId({
        sipInstanceId: newSipInstanceId,
    }));

    return newSipInstanceId;
};

const setOutputDevice = async (audioElement: HTMLAudioElement, audioDeviceId: string) => {
    if (typeof audioElement.setSinkId !== 'undefined') {
        try {
            await audioElement.setSinkId(audioDeviceId);
            console.log(`Success, audio output device attached: ${audioDeviceId}`);
        } catch (error) {
            console.error('Error attaching output device:', error);
        }
    } else {
        console.warn('Browser does not support output device selection.');
    }
};

const initializeSip = (dispatch: any, audioDeviceId: string) => {
    const socket = new JsSIP.WebSocketInterface(window.__ENV__?.URL_WSS_VOIP);

    JsSIP.debug.enable("JsSIP:*");

    const tenant = store.getState().auth.tenant;
    const user = store.getState().auth.userId
    const token = store.getState().auth.token;
    const sipInstanceId = store.getState().dialer.sipInstanceId;

    console.log({
        tenant,
        user,
        token,
        sipInstanceId
    });


    let retryCount = 0;
    const maxRetries = 5;
    const retryDelay = 1000;

    const configuration = {
        sockets: [socket],
        uri: `sip:${user}@${tenant}`,
        password: user,
        session_timers: false,
        instance_id: getSipInstanceId(sipInstanceId, dispatch),
        authorization_jwt: token,
        user_agent: `JsSIP ${JsSIP.version} | ${navigator.userAgent}`,
    };

    const ua = new JsSIP.UA(configuration as any);
    sipUserAgent = ua;

    const startRegistration = () => {
        console.log('Attempting to register...');
        ua.start();
    };

    startRegistration()

    ua.on('registrationFailed', async e => {
        console.log('registrationFailed', e, e.cause, e.response);

        if (
            e.cause === JsSIP.C.causes.AUTHENTICATION_ERROR ||
            (e.cause === JsSIP.C.causes.REJECTED &&
                (e.response?.status_code === 401 ||
                    e.response?.status_code === 403))
        ) {
            if (ua.isRegistered()) {
                ua.unregister();
            }

            setTimeout(() => {
                ua.register();
            }, 1000);
        } else if (retryCount < maxRetries) {
            retryCount++;
            console.log(
                `Retrying registration in ${retryDelay / 1000} seconds... (Attempt ${retryCount} of ${maxRetries})`,
            );
            setTimeout(() => {
                startRegistration();
            }, retryDelay);
        } else {
            console.error(
                'Max retry attempts reached. Please check your configuration and network.',
            );
        }

        session = null;
    });

    ua.on("newRTCSession", (data: any) => {
        session = data.session;
        const request = data.request;

        let associatedContacts = null;

        try {
            const contactsHeader = request.getHeader('P-Contacts');
            associatedContacts = JSON.parse(contactsHeader);
            console.log('Associated contacts:', associatedContacts);
        } catch (e) {
            console.warn(e);
        }

        let callFrom: string | undefined;
        if (associatedContacts?.primary) {
            callFrom = `${associatedContacts.primary?.firstName} ${associatedContacts.primary?.lastName}`
            dispatch(setCallFrom(callFrom));
        } else if (!store.getState().dialer.callFrom) {
            callFrom = session?.remote_identity.uri.user
            dispatch(setCallFrom(callFrom));
        }

        if (session?.direction === "incoming") {
            // Show browser notification
            if (Notification.permission === "granted") {
              const notification =  new Notification("Incoming Call", {
                    body: `Incoming call from ${callFrom}`,
                    icon: logo
                });
              notification.onclick = () => {
                  window.focus();
                    notification.close();

              }
            } else if (Notification.permission !== "denied") {
                Notification.requestPermission().then(permission => {
                    if (permission === "granted") {
                        new Notification("Incoming Call", {
                            body: `Incoming call from ${callFrom}`,
                            icon: logo
                        });
                    }
                });
            }

            ringingAudioRef.play();
            dispatch(setIncomingCall(true));


            session.on("accepted", async () => {
                ringingAudioRef.pause();
                await setOutputDevice(audioRef, audioDeviceId);
                dispatch(setCallState(CallStateEnum.IN_CALL));
            });
            session.on("confirmed", async () => {
                ringingAudioRef.pause();
                await setOutputDevice(audioRef, audioDeviceId);
                dispatch(setCallState(CallStateEnum.IN_CALL));
            });
            session.on("ended", () => {
                dispatch(setCallState(CallStateEnum.IDLE));
                dispatch(setIncomingCall(false));
                dispatch(setCallFrom(null));
                disconnectAudioRef.play();
            });
            session.on("failed", () => {
                ringingAudioRef.pause();
                dispatch(setCallState(CallStateEnum.IDLE));
                dispatch(setIncomingCall(false));
                dispatch(setCallFrom(null));

            });
        }
    });

// Request notification permission on application start
    if (Notification.permission !== "granted" && Notification.permission !== "denied") {
        Notification.requestPermission();
    }
};

const call = async (phoneNumber: string | undefined, options: any, audioDeviceId: string) => {
    if (!phoneNumber) {
        console.log('Phone number is required');
        return;
    }
    if (!sipUserAgent) {
        console.log('UA is not ready');
        return;
    }

    const tenant = store.getState().auth.tenant;
    session = sipUserAgent.call(`sip:${phoneNumber}@${tenant}`, options);

    if (session?.connection) {
        console.log('Call initiated', session)
        session.connection.addEventListener('track', async (e: any) => {
            audioRef.srcObject = e.streams[0];
            await setOutputDevice(audioRef, audioDeviceId);
            audioRef.play();
        });

        session.connection.addEventListener('peerconnection', async (e: any) => {
            audioRef.srcObject = e.stream;
            await setOutputDevice(audioRef, audioDeviceId);
            audioRef.play();
        });

        session.on('addstream' as any, async (e: any) => {
            audioRef.src = window.URL.createObjectURL(e.stream);
            await setOutputDevice(audioRef, audioDeviceId);
            audioRef.play();
        });

        session.on("icecandidate", (e) => {
            if (e.candidate.type && ["srflx", "rely"].includes(e.candidate.type)) {
                e.ready();
            }
        });
    }
};

const answerCall = async (dispatch: any, options: any, audioDeviceId: string) => {
    if (session) {
        session.answer(options);

        if (session.connection) {
            session.connection.addEventListener('track', async (e: any) => {
                audioRef.srcObject = e.streams[0];
                await setOutputDevice(audioRef, audioDeviceId);
                audioRef.play();
            });

            session.connection.addEventListener('peerconnection', async (e: any) => {
                audioRef.srcObject = e.stream;
                await setOutputDevice(audioRef, audioDeviceId);
                audioRef.play();
            });

            session.on('addstream' as any, async (e: any) => {
                audioRef.src = window.URL.createObjectURL(e.stream);
                await setOutputDevice(audioRef, audioDeviceId);
                audioRef.play();
            });
        }
        dispatch(setIncomingCall(false));
        dispatch(setCallState(CallStateEnum.IN_CALL));

    }
};
const setVolume = (volume: number) => {
    if (volume < 0 || volume > 1) {
        console.warn('Volume should be between 0.0 and 1.0');
        return;
    }

    if (audioRef) {
        audioRef.volume = volume;
        console.log(`Call audio volume set to: ${volume}`);
    }
};
const rejectCall = (dispatch: any) => {
    if (session) {
        session.terminate({
            status_code: 603,
            reason_phrase: 'Decline',
        });
    }
    dispatch(setIncomingCall(false));
    dispatch(setCallFrom(null));
};

const endCall = (dispatch: any) => {
    if (session) {
        dispatch(setCallFrom(null));
        session.terminate();
    }
};
const toggleHold = () => {
    if(!session) return;
    if (session.isOnHold().local) {
        session.unhold();
    } else {
        session.hold();
    }
};

const toggleMuted = () => {
    if(!session) return;
    if (session.isMuted().audio) {
        session.unmute();
    } else {
        session.mute();
    }
};
const sendDtmf = (character:string) => {
    if (session) {
        session.sendDTMF(character);
    }

};


export {
    initializeSip,
    call,
    answerCall,
    rejectCall,
    endCall,
    setVolume,
    toggleHold,
    toggleMuted,
    sendDtmf
};

import {ChColumn, Loader, Button} from "ch3-ui-lib";
import {sortBy} from "lodash";
import {Queue, useGetQueuesQuery} from "../../store/features/CallQueue/CallQueueService";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import listEmpty from "../../assets/list-empty.svg";

export const CallQueuesList = () => {
    const { data: callQueue, isLoading } = useGetQueuesQuery();
    const [sortedQueues, setSortedQueues] = useState<Queue[]>([]);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

    useEffect(() => {
        if (callQueue) setSortedQueues(callQueue);
    }, [callQueue]);

    const sortByName = () => {
        const sorted = sortOrder === 'asc'
            ? sortBy(sortedQueues, 'name')
            : sortBy(sortedQueues, 'name').reverse();

        setSortedQueues(sorted);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    if (isLoading) {
        return (
            <div className="flex w-full">
                <Loader />
            </div>
        );
    }

    return (
        <div className="w-full">
            <table className="w-full">
            {Boolean(sortedQueues.length) &&
            <>
                <thead>
                    <tr>
                        <th className="px-8 py-3 w-full">
                            <ChColumn
                                // className="w-full"
                                propertyName="NAME"
                                callback={sortByName}
                                label={'Name' }
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sortedQueues.map((queue: Queue) => (
                    
                    <tr
                        key={queue.id}
                        className="hover:bg-grey-50 w-full block border-b border-grey-75"
                    >
                        <Link
                            className="px-8 w-full block font-medium font-sm"
                            to={`/admin/callQueues/${queue.id}`}
                        >
                            <td className="py-8 w-full">{queue.name}</td>
                        </Link>
                    </tr>
                    ))}  
                </tbody>
                 </>
                }

                {sortedQueues.length === 0 &&
                    <div className='flex text-center  justify-center '>
                    <div className='w-[450px]  m-14'>
                        <div className='flex  justify-center w-full'>
                            <img className='m-4' src={listEmpty}/>
                        </div>

                        <h1 className='font-bold'>
                        No Queues Yet
                        </h1>
                        <div className='mb-6 mt-2'>
                        Looks like you haven't created any queues yet. 
                        Click "Create new" to build your first queue 
                        and manage calls efficiently.
                        </div>
                        <div className='flex justify-center'>
                                    <Link to={'/admin/callQueues/new'}>
                                        <Button buttonType='primary' label='Create new'/>
                                    </Link>
                                </div>
                    </div>

                </div>
                }

            </table>
        </div>
    );
};

import { mobileWidth, tabletWidth } from "../../utilis/screenWidthUtils";
import { Button, ChModal } from "ch3-ui-lib";
import { Link } from "react-router-dom";
import google from '../../assets/google.svg';
import pipedrive from '../../assets/pipedrive.svg';
import {
    useDeleteGoogleIntegrationMutation, useDeleteIntegrationMutation,
    useGetIntegrationsQuery
} from "../../store/features/integrations/integrationsService";
import { showToast } from "../../store/features/Notifications/notificationsSlice.tsx";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const integrations = [
    {
        id: 'GOOGLE_WORKSPACE',
        name: "Google Contacts",
        description: "Sync your Google Workspace user with PhoneHQ",
        image: google,
        link: "/admin/integrations/google",
        integratedLink: "/admin/integrations/google/list"
    },
    {
        id: 'PIPEDRIVE',
        name: "Pipedrive",
        description: "Sync contacts and sales to boost team efficiency. Easily connect PhoneHQ with Pipedrive.",
        image: pipedrive,
        link: "/admin/integrations/pipedrive",
        // This will be dynamically replaced with the actual integration ID
        integratedLink: "/admin/integrations/pipedrive/settings"
    }
];

type Platform = 'GOOGLE_WORKSPACE' | 'PIPEDRIVE';

interface IntegrationCardProps {
    isEnabled: boolean;
    name: string;
    description: string;
    image: string;
    link: string;
    integratedLink: string;
    integrationId?: number;
    platform: string;
}

function IntegrationCard({ integrationId, isEnabled, name, description, image, link, integratedLink, platform }: IntegrationCardProps) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [disconnectGoogleMutation] = useDeleteGoogleIntegrationMutation();
    const [deleteIntegrationMutation] = useDeleteIntegrationMutation();

    const getActualIntegratedLink = () => {
        if (platform === 'PIPEDRIVE' && integrationId) {
            return `${integratedLink}?id=${integrationId}`;
        }
        return integratedLink;
    };

    const disconnectIntegration = async () => {
        if (!integrationId) return;

        try {



            if (platform === 'GOOGLE_WORKSPACE') {
                await disconnectGoogleMutation({ integrationId });
                navigate('/admin/integrations/google/disconnected');
            } else if (platform === 'PIPEDRIVE') {
               await deleteIntegrationMutation({ integrationId });
                navigate('/admin/integrations/pipedrive/disconnected');
            }
        } catch (error) {
            dispatch(showToast({
                title: "Error",
                description: "Failed to disconnect integration.",
                type: "error",
            }));
        }
    }

    return (
        <div className='w-[317px] flex flex-col border-black border h-[250px]'>
            <div><img className='my-6 px-4' src={image} alt={name} /></div>
            <div className=' px-4 pb-4'>
                <div className='font-medium'>{name}</div>
                <div className='text-xs text-grey-700'>{description}</div>
            </div>
            <div className={'flex  mt-auto border-t border-black ' + (isEnabled ? 'justify-between' : 'justify-end')}>
                {isEnabled && <div className={'flex items-center'}>
                    <ChModal
                        callback={(res: boolean) => {
                            if (res) {
                                disconnectIntegration();
                            }
                        }}
                        title={'Are you sure you want to disconnect it?'}
                        modalType={'destructive'}
                        trigger={
                            <Button
                                size={'small'}
                                className='m-4'
                                buttonType='textError'
                                label={'Disconnect'}
                            />
                        }
                        content={
                            <div className={'py-4 mb-4 w-[400px] text-grey-500'}>
                                By disconnecting this integration, you will lose all the data and this action can't be undone.
                            </div>
                        }
                        secondaryLabel='Cancel'
                        primaryLabel='Yes, disconnect'
                    />
                </div>}
                <Link to={isEnabled ? getActualIntegratedLink() : link}>
                    <Button
                        className='m-4'
                        size={'small'}
                        leftIcon={isEnabled ? 'settings' : ''}
                        buttonType={isEnabled ? 'secondary' : 'primary'}
                        label={isEnabled ? 'View details' : 'Connect'}
                    />
                </Link>
            </div>
        </div>
    );
}

export default function Integrations() {
    const { data: integrationsData } = useGetIntegrationsQuery();

    const findIntegrationByPlatform = (platform: Platform) => {
        if (!integrationsData?.data) return null;

        const integrationsArray = Array.isArray(integrationsData.data)
            ? integrationsData.data
            : integrationsData.data.current
                ? [integrationsData.data.current]
                : [];

        return integrationsArray.find((integration: any) =>
            integration.platform === platform &&
            (!integration.details?.disabled)
        );
    };

    return (
        <div className='w-full'>
            <div className='h-[calc(100vh-130px)] overflow-y-auto'>
                <div className='ml-8'>
                </div>
                <div
                    className={`max-h-[calc(100vh-130px)] overflow-x-hidden overflow-y-scroll gap-8 flex flex-wrap ${mobileWidth || tabletWidth ? 'px-4' : 'p-6'}`}>
                    {integrations.map((integration, index) => {
                        const activeIntegration = findIntegrationByPlatform(integration.id as Platform);

                        return (
                            <IntegrationCard
                                integrationId={activeIntegration?.id}
                                isEnabled={!!activeIntegration}
                                platform={integration.id}
                                key={index}
                                {...integration}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
import {Colors} from "../../utilis/types/Types";
import {FlagImage} from "react-international-phone";
import {getFlag, getFormatedPhoneNumber} from "../../utilis/msisdnUtilis";
import { getCallTypeDescription} from "../../utilis/callsUtilis";
import { dateFromNow} from "../../utilis/timeUtilis";
import {Avatar, Icon} from "ch3-ui-lib";
import {Call} from "../../store/features/calls/callsService";
import logo from "../../assets/logo.svg";
import recordedMessage from "../../assets/voice-note.svg"


export interface CallRowProps {
    call: Call;
    isMobile: boolean;
    handleCallClick: (call: Call) => void;
    selectedCall: Call | undefined;
}

const CallRow = ({ call, isMobile, handleCallClick, selectedCall }: CallRowProps) => {
    const isSelected = selectedCall === call;
    const hasPrimaryContact = Boolean(call.primaryContact && !call.internalCallee);
    const hasInternalCallee = Boolean(call.internalCallee);
    const hasClientMsisdn = Boolean(!call.internalCallee && !call.primaryContact && call?.clientMsisdn);

    const renderContactInfo = () => {
        if (hasPrimaryContact) {
            const { firstName, lastName } = call.primaryContact!;
            return (
                <>
                    <Avatar size="md" type="initials" color={Colors.grey} name={`${firstName} ${lastName}`} />
                    <div className="flex flex-col ml-4">
                        <p className={`text-sm font-medium ${!call.bridgetAt && !call.forwardedAt ? 'text-error-500' : ''}`}>{`${firstName} ${lastName}`}</p>
                        <p className="text-xs">{getCallTypeDescription(call.type, call.bridgetAt, call.forwardedTo)}</p>
                    </div>
                </>
            );
        }

        if (hasInternalCallee) {
            const { firstName, lastName } = call.internalCallee!;
            return (
                <>
                    <Avatar size="md" type="initials" color={Colors.grey} name={`${firstName} ${lastName}`} />
                    <div className="flex flex-col ml-4">
                        <p className={`text-sm font-medium mb-1 ${!call.bridgetAt && !call.forwardedAt ? 'text-error-500' : ''}`}>{`${firstName} ${lastName}`}</p>
                        <div className="flex">
                            <div className="text-xs flex border border-grey-200 h-[20px] rounded-md justify-center items-center p-2">
                                <Icon iconName="lock" size="xs" className="p-0 w-4 h-4 text-grey-400" />
                                <p className="text-grey-400">Internal</p>
                            </div>
                            <p className="text-xs ml-2">{getCallTypeDescription(call.type, call.bridgetAt, call.forwardedTo)}</p>
                        </div>
                    </div>
                </>
            );
        }

        if (hasClientMsisdn) {
            return (
                <>
                    <Avatar size="md" type="default" color={Colors.grey} />
                    <div className="flex flex-col ml-4">
                        <div className="flex items-center">
                            <FlagImage size={16} className="w-3 h-3" iso2={getFlag(call.clientMsisdn) ?? 'us'} />
                            <div className={`ml-2 text-sm font-medium ${!call.bridgetAt && !call.forwardedAt ? 'text-error-500' : ''}`}>{getFormatedPhoneNumber(call?.clientMsisdn, true)}</div>
                        </div>
                        <p className="text-xs">{getCallTypeDescription(call.type, call.bridgetAt, call.forwardedTo)}</p>
                    </div>
                </>
            );
        }

        if (!hasClientMsisdn && !hasPrimaryContact && !hasInternalCallee) {
            return (
                <>

                    <Avatar size="md" type="icon" icon='warning' color={Colors.grey} />
                    <div className="flex flex-col ml-4">
                        <p className="text-sm font-medium">Unknown</p>
                        <p className="text-xs">{getCallTypeDescription(call.type, call.bridgetAt, call.forwardedTo)}</p>
                    </div>
                </>
            );
        }

        return null;
    };

    const renderAdditionalInfo = () => (
        <div className={`flex ${isMobile ? 'flex-col items-start px-2' : 'flex-row items-center px-0 w-1/8 min-w-[195px] justify-end'}`}>
            <div className="flex items-center">
                <div className="flex flex-col items-end">
                    
                 <p className="text-xs text-gray-500">{dateFromNow(new Date(call.startedAt))}</p>
                 {Boolean(call.recordingId) &&
                        <img className="h-[20px] w-[20px]   mt-1 " src={recordedMessage} alt="recording icon" />
                    }

                </div>
                {call?.exposedMsisdn && (
                    <Avatar className={'w-1/10  ml-4'} icon={call.exposedMsisdn.icon} size="xxs" type="icon" color={call.exposedMsisdn.iconColor} name="phone number icon" />
                )}
                {call.internalCallee && (
                    <img className="h-[20px] w-[20px] w-1/10  ml-4 rounded" src={logo} alt="PhoneHQ logo" />
                )}
            </div>
        </div>
    );

    return (
        <div
            key={call.callId}
            onClick={() => handleCallClick(call)}
            className={`flex justify-between rounded hover:bg-grey-50 py-3 px-6 m-2 ${isSelected ? 'bg-grey-50' : ''} ${isMobile ? 'flex-col' : 'flex-row'}`}
        >
            <div className="flex items-center">{renderContactInfo()}</div>
            {renderAdditionalInfo()}
        </div>
    );
};

export default CallRow;

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CallControls from "./components/CallControls";
import Keypad from "./components/Keypad";
import Call from "./Call";
import {RootState, useAppDispatch} from "../../store/store";
import { isValidPhoneNumber} from 'libphonenumber-js/max';
import {
    getAutoGainControl, getMicRequired,
    getSelectedMicrophone, getSelectedSpeaker, getShowKeypad,
    selectSelectedPhoneNumber, setMicRequired,
    setPhoneNumber,
    setSelectedPhoneNumber
} from "../../store/features/calls/dialerSlice";

import {
    initializeSip,

    answerCall as acceptCall,
    rejectCall,
    endCall,
    sendDtmf
} from './services/sipService';
import {CallInput} from "./components/CallInput";
import {ChInput} from "ch3-ui-lib";
import {useFetchMsisdnsQuery} from "../../store/features/Msisdns/msisdnService";
import {useCallService} from "../../services/callService";
import {selectUserId} from "../../store/features/Auth/authSlice";
import {showToast} from "../../store/features/Notifications/notificationsSlice.tsx";

export enum CallStateEnum {
    CONNECTING = 'CONNECTING',
    IDLE = 'IDLE',
    IN_CALL = 'IN_CALL',
}

interface DialerProps {
    isInCalls: boolean;
}

function Dialer({ isInCalls }: DialerProps) {
    const { makeCall } = useCallService();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const pathname = window.location.pathname;
        const [_, path, number] = pathname.split("/");

        if (path === "call") {
            dispatch(setPhoneNumber(number));
        }
    }, []);


    const [timer, setTimer] = useState(0);
    const [error, setError] = useState<string>('');
    const [dtmf, setDtmf] = useState<string>('');
    const selectedPhoneNumber = useSelector(selectSelectedPhoneNumber);
    const userId = useSelector(selectUserId);

    const {data: availablePhoneNumbers, isFetching} =  useFetchMsisdnsQuery(userId);
    const isDialerHidden = useSelector((state: RootState) => state.dialer.isDialerHidden);
    const incomingCall = useSelector((state: RootState) => state.dialer.incomingCall);
    const callFrom = useSelector((state: RootState) => state.dialer.callFrom);
    const callState = useSelector((state: RootState) => state.dialer.callState);
    const selectedMicrophone = useSelector(getSelectedMicrophone);
    const autoGainControl = useSelector(getAutoGainControl);
    const micRequired = useSelector(getMicRequired);

    const phoneNumber = useSelector((state: RootState) => state.dialer.phoneNumber);
    const audioDevice = useSelector(getSelectedSpeaker);
    const selectedPrefix = useSelector((state: RootState) => state.dialer.selectedPrefix);

    const showKeypad = useSelector(getShowKeypad);

    const permissionName = "microphone" as PermissionName;
    navigator.permissions.query({name: permissionName} as PermissionDescriptor)
        .then((permissionStatus) => {
            console.log('Permission', permissionStatus.state);
            if(permissionStatus.state === 'denied') {
                dispatch(setMicRequired(true));
                dispatch(showToast({
                    description: 'Microphone is turned off. Turn it on to use this feature.',
                    type: 'error',
                    dontHide: true,
                    position: 'center',
                    icon: 'mic_off'
                }));
            }
        });

    useEffect(() => {
        askNotificationPermission();
        initializeSip(dispatch, audioDevice);
    }, [dispatch]);

    useEffect(() => {
        if (!selectedPhoneNumber && availablePhoneNumbers?.length) {
            dispatch(setSelectedPhoneNumber(availablePhoneNumbers[0]));
        }
    }, [selectedPhoneNumber, availablePhoneNumbers, dispatch]);



    useEffect(() => {
        let interval: number;
        if (callState === CallStateEnum.IN_CALL) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer + 1);
            }, 1000);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
                setTimer(0);
            }
        };
    }, [callState]);

    useEffect(() => {
        if (incomingCall && micRequired) {
            dispatch(showToast({
                description: 'Microphone is turned off. Turn it on to use this feature.',
                type: 'error',
                dontHide: true,
                position: 'center',
                icon: 'mic_off'
            }));
        }
    }, [incomingCall]);

    const isPhoneValid = (phone: string) => {

        try {
            return isValidPhoneNumber(phone);

        } catch (error) {
            return false;
        }
    };

    const handleCall = () => {
        setDtmf('');
        let number = phoneNumber.startsWith('00') ? phoneNumber.replace(/^00/, '+') : phoneNumber;
        if(!number.toString().includes('+'))
        {
                 number = selectedPrefix.prefix + phoneNumber.toString();
        }

        if (!isPhoneValid(number)) {
            setError('Invalid phone number');
            return;
        }


            if (callState !== 'IDLE') return;

            makeCall({
                msisdn: number,
                fromName: number,
                isInternal: false,
            });

        setError('');
    };
    const keypadClicked = (key: string) => {
        if (callState === CallStateEnum.IDLE) {
            const newPhoneNumber = phoneNumber + key;
            dispatch(setPhoneNumber(newPhoneNumber));
        } else {
            setDtmf((prevDtmf) => prevDtmf + key);
            sendDtmf(key);
        }
    };
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            // Prevent handling if an input field other than the dialer is focused
            if (document.activeElement?.tagName === "INPUT" && document.activeElement?.getAttribute("name") !== "dialer") {
                return;
            }

            if (event.key === "Backspace") {
                if (callState === CallStateEnum.IDLE) {
                    const updatedNumber = phoneNumber.slice(0, -1);
                    dispatch(setPhoneNumber(updatedNumber));
                }
                return;
            }

            if (event.key === "Enter") {
                handleCall();
                return;
            }

            if (/^[0-9+*]$/.test(event.key)) {
                keypadClicked(event.key);
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [phoneNumber, callState, keypadClicked, handleCall]);



    const askNotificationPermission = () => {
        if (!("Notification" in window)) {
            console.log("This browser does not support notifications.");
            return;
        }
        Notification.requestPermission().then((permission) => {
            console.log('Permission', permission);
        });
    }

    return (
        <div className={`z-10 ${isDialerHidden ? 'w-[80px] ' : 'w-[320px] '} `}>
            {((callState === CallStateEnum.IDLE && !incomingCall ) || showKeypad) && (
                <>
                    <div className="flex gap-4 justify-center px-8 py-4 text-sm leading-5 text-neutral-500">
                        {Boolean(!isDialerHidden && !showKeypad) && (
                            <CallInput
                                phoneNumber={phoneNumber}
                                error={error}
                               />
                        )}
                    </div>
                    {Boolean(!isDialerHidden) && (
                        <>
                            {showKeypad &&
                                <div className="flex gap-4 justify-center px-10 py-6 text-sm leading-5 text-neutral-500b">
                                <ChInput inputClassName='text-center text-xl font-semibold !border-none' name='dialer' callback={()=> {}}  className="!border-none" value={dtmf}/>
                                </div>}

                            <Keypad keypadClicked={keypadClicked} />
                        </>

                    )}
                </>
            )}
            <div>
                <Call isDialerHidden={isDialerHidden} callFrom={callFrom} phoneNumber={phoneNumber} timer={timer} isIncomingCall={incomingCall} callState={callState} />
            </div>
            <CallControls
                showKeypad={showKeypad}
                callState={callState}
                isIncomingCall={incomingCall}
                isReady={!isFetching}
                call={handleCall}
                rejectCall={() => rejectCall(dispatch)}
                answerCall={() => acceptCall(dispatch, {
                    mediaConstraints: {
                        audio: {
                            autoGainControl: autoGainControl,
                            ...(selectedMicrophone ? { deviceId: selectedMicrophone } : {})
                        },
                        video: false
                    }
                }  , audioDevice)}
                endCall={() => endCall(dispatch)}
                isInCalls={isInCalls}
                removeChar={() =>  dispatch(setPhoneNumber(phoneNumber.slice(0, -1)))}
            />
        </div>
    );}

export default Dialer;

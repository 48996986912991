import {Avatar, ChCheckbox, Icon, Loader} from "ch3-ui-lib";
import {clsxMerge} from "../../../utilis/classNameUtils";
import {borderStyles} from "../../Groups/helpers";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";

interface UserRowProps {
    user: any,
    isSelected: boolean,
    handleSelect: any
    userStatus: string
    isPolling: boolean
    userExists: 'ALREADY_EXISTS' | 'NOT_EXISTS' | 'ALREADY_SYNCED' | false
}

export const UserRow = ({user,userExists, isSelected, handleSelect, userStatus, isPolling}: UserRowProps) => {

    const [currentUser, setCurrentUser] = useState(user);
    useEffect(() => {
        setCurrentUser(user);
    }, [user]);

    const getUserStatus = (currentStatus: string) => {
        console.log('currentStatus', currentStatus);
        switch (currentStatus) {

            case 'WAITING':
                return <Loader className={'!h-[36px] w-full'}/>;

            case 'FAILED':
                return <div className={'flex text-error-500 items-center'}><Icon size='sm' iconName='error'
                                                                                 className='text-red-500'/>Failed</div>
            case 'SUCCESS':

                return <div className={'flex text-success-500 items-center'}><Icon size='sm' iconName='check'
                                                                                   className='text-green-300'/> Success
                </div>;
            case 'ALREADY_EXISTS':

                return <div className={'flex text-black items-center text-sm'}><Icon size='xxs' background={true} iconName='check'
                                                                                   className='text-white bg-black mx-2 w-4 h-4 justify-center'/> Already Exists
                </div>;
            case 'ALREADY_SYNCED':

                return <div className={'flex text-black items-center text-sm'}><Icon size='xxs' background={true} iconName='check'
                                                                                   className='text-white bg-black mx-2 w-4 h-4 justify-center'/> Already Synced
                </div>;
            default:
                return <Loader className={'!h-[36px] w-full'}/>;
        }
    }

    const theme = useSelector((state: any) => state.themeSwitcher.theme);

    return (
        <>
            <td className={'flex items-center justify-between  border-b ' + clsxMerge(borderStyles({theme}))}>
                <div className={'flex items-center gap-3 p-3'}>
                    {(!isPolling && !userExists )  && <ChCheckbox callback={() => handleSelect(currentUser)}
                                               checked={isSelected}
                                               value={currentUser}/>}
                    <Avatar size={'md'} type={currentUser?.avatarUrl ?  'image' : 'default'} icon={currentUser?.avatarUrl ? currentUser.avatarUrl : 'person'} color={'default'}/>
                    <div>
                        <div className='font-semibold'>{currentUser.firstName + ' ' + currentUser.lastName}</div>
                        <div>{currentUser.email}</div>
                    </div>
                </div>

                <div className={'px-7'}>
                    {(isPolling || userExists )&& getUserStatus(userExists ? userExists : userStatus)}
                </div>
            </td>

        </>

    );
}

import {Link} from "react-router-dom";
import {Avatar, Button, ChInput, ChModal, ChTooltip, Icon, Switch} from "ch3-ui-lib";
import {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import AddContactModal from "../Contacts/AddContactModal";
import {validateEmail, validateName} from "../../utilis/commonValidators";
import {useNavigate} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import { clsxMerge } from "../../utilis/classNameUtils.ts";
import { borderStyles, boxStyles } from "./helpers.tsx";
import ManageIconModal from "./PhoneNumberIconManageModal.tsx";
import { Colors } from "../../utilis/types/Types";

import {
    useFetchMsisdnSettingsInfoQuery,
    useUpdateMsisdnSettingsInfoMutation
} from "../../store/features/MsisdnSettings/msisdnSettingsService";
import {User} from "../../store/features/Users/userService";
import {Group} from "../../store/features/groups/groupsService";


interface PhoneNumberInfoProps {
    admin: boolean;
}

export const PhoneNumberInfo = ({admin = false}:PhoneNumberInfoProps) => {
    const theme = useSelector((state:any) => state.themeSwitcher.theme);
    const [icon, setIcon] = useState('');
    const [color, setColor] = useState('');

    const {id} = useParams<{ id: string }>();
    const isMobile = useMediaQuery('(max-width: 960px)');

    const navigate = useNavigate();
    const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);

    const [updateInfo] = useUpdateMsisdnSettingsInfoMutation();
    const { data: msisdnInfo } = useFetchMsisdnSettingsInfoQuery(id as string);


    const [users, setUsers] = useState([] as User[]);
    const [groups, setGroups] = useState([] as Group[])
    const pickerRef = useRef<HTMLDivElement>(null);
    const [formData, setFormData] = useState({

        msisdn: {
            label: msisdnInfo?.msisdn?.label ?? '',
            icon: msisdnInfo?.msisdn?.icon ?? 'home',
            iconColor: msisdnInfo?.msisdn?.iconColor ?? 'blue',
            msisdn: msisdnInfo?.msisdn?.msisdn ?? 0,
            msisdnId: msisdnInfo?.msisdn?.msisdnId ?? 0,
            type: msisdnInfo?.msisdn?.type ?? '',
        },
        emailsSetting: {
            enabled: msisdnInfo?.emailsSetting?.enabled ?? false,
            email:  msisdnInfo?.emailsSetting?.email ?? null
        },
        callRecordingEnabled: msisdnInfo?.callRecordingEnabled ?? false,
        users: msisdnInfo?.users?.length ? [...msisdnInfo?.users] : [],
        groups: msisdnInfo?.groups?.length ? [...msisdnInfo?.groups] : [],
    })
    const [errors, setErrors] = useState({
        name: '',
        icon: '',
        email: '',
    });

    const handleChange = (field:string, value: string) => {
        const newErrors = { ...errors };

        if (field === 'label') {
            newErrors.name = validateName(value) ? '' : 'Name is required';
        }

        if (field === 'icon') {
            newErrors.icon = validateName(value) ? '' : 'Icon is required';
        }

        if (field === 'email') {
            newErrors.email = formData.emailsSetting.enabled ? validateEmail(value) ? '' : 'Email is required' : '';
        }

        setErrors(newErrors);
        setFormData((prev) => ({...prev, msisdn: {...prev.msisdn, label: value}}));
    }

    useEffect(() => {
        setFormData({
            msisdn: {
                label: msisdnInfo?.msisdn?.label ?? '',
                icon: msisdnInfo?.msisdn?.icon ?? 'home',
                iconColor: msisdnInfo?.msisdn?.iconColor ?? 'blue',
                msisdn: msisdnInfo?.msisdn?.msisdn ?? 0,
                msisdnId: msisdnInfo?.msisdn?.msisdnId ?? 0,
                type: msisdnInfo?.msisdn?.type ?? '',
            },
            emailsSetting: {
                enabled: msisdnInfo?.emailsSetting?.enabled ?? false,
                email: msisdnInfo?.emailsSetting?.email ?? null
            },
            callRecordingEnabled: msisdnInfo?.callRecordingEnabled ?? false,
            users: msisdnInfo?.users?.length ? [...msisdnInfo?.users] : [],
            groups: msisdnInfo?.groups?.length ? [...msisdnInfo?.groups] : [],
        })
        setIcon(msisdnInfo?.msisdn?.icon ?? 'home');
        setColor(msisdnInfo?.msisdn?.iconColor ?? 'blue');
        setGroups(msisdnInfo?.groups ?? []);
        setUsers(msisdnInfo?.users ?? []);
    }, [msisdnInfo]);

    const handleValidation = () => {
        const newErrors = {
            name: validateName(formData.msisdn.label) ? '' : 'Name is required',
            icon: validateName(formData.msisdn.icon) ? '' : 'Icon is required',
            email: formData.emailsSetting.enabled ? validateEmail(formData.emailsSetting.email) ? '' : 'Email is required' : '',
        };
        setErrors(newErrors);
        return newErrors;
    };

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (!formData.emailsSetting.enabled && formData.emailsSetting.email == '') {
            setFormData((prev) => ({...prev, emailsSetting: {...prev.emailsSetting, email: null}}));
        }
        const fieldErrors = Object.values(handleValidation());
        const hasFieldErrors = fieldErrors.some(fieldError => {
            if (Array.isArray(fieldError)) {
                return fieldError.some(error => error !== '');
            } else {
                return fieldError !== '';
            }
        });

        if (hasFieldErrors) return;
        const data = {
            ...formData,
            users: formData.users.map((user) => user.userId),
            groups: formData.groups.map((group) => group.groupId),
        }

        if (!id) return;
        await updateInfo({id , data});

        navigate( (admin ? '/admin' : '' ) +  '/phoneNumbers');
    };

    const handleClickOutside = (event: any) => {
        if (pickerRef.current && !pickerRef.current.contains(event.target as Node)) {
            setEmojiPickerOpen(false);
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [emojiPickerOpen]);

    const handleMenageAccessChange = (res: boolean) => {

        if (!res) {
            setUsers([...formData.users]);
            setGroups([...formData.groups]);
        }

        const data = {
            ...formData,
            users: users,
            groups: groups,
        };

        setFormData(data);
    };

    const menageAccessTrigger = <Button disabled={msisdnInfo?.msisdn.assigmentScope == 'PRIVATE'} className={'mb-2'}  buttonType='secondary' label='Manage access' size='small' leftIcon='settings'/>
    const modalContent = <AddContactModal selectedUsers={formData.users}
                                          selectedGroups={formData.groups} setUsers={setUsers}
                                          setGroups={setGroups}/>

    const handleNumberAvatar = (res:boolean) => {
        if(!res) return;
        setFormData((prev) => ({...prev, msisdn: {...prev.msisdn, icon: icon, iconColor:color}}));
        setErrors({...errors, icon: ''})
    };


    const manageIconContent = <ManageIconModal setIcon={setIcon}  setColor={setColor} currentIcon={formData.msisdn.icon} currentColor={formData.msisdn.iconColor}></ManageIconModal>
    const iconModalTrigger = <div className="p-2 hover:bg-primary-50 mr-1.5 mb-2 rounded"><div className={`cursor-pointer items-center hover:bg-primary-50 flex  h-[32px] w-[32px] justify-center items-center text-2xl ${errors.icon && ' !border-error-500'} ` }>

   <div>
       <Avatar icon={formData.msisdn.icon} size="sm" type='icon' color={formData.msisdn.iconColor}
                    name='John Doe'/>
   </div></div></div>;


    const handleCallRecordingChange = () => {

        setFormData((prev) => ({...prev, callRecordingEnabled: !formData.callRecordingEnabled}));
    }
    return (
        <>{ msisdnInfo && !isMobile &&
            <div className={`${isMobile ? ' px-4' : ' '}`}>
                <div className='h-[calc(100vh-63px-var(--header-height))] overflow-x-hidden   overflow-y-auto '>
                    <div className={`flex flex-row border-black border-b    ${isMobile ? ' my-6' : ''} ` + clsxMerge(borderStyles({theme}), boxStyles({theme}))}>
                        <div className={`border-r border-grey-75 min-w-[344px] px-8`}>

                      <div className='font-semibold pt-6 text-lg'>
                          General information
                      </div>
                      <div className='text-grey-500  pb-6 text-sm '>
                          Update name and general information
                      </div>
                  </div>
                  <div className='p-6 grid w-full'>
                      <div className={'flex flex-col '}>
                            <p className="text-sm mb-1">Name</p>
                          <div className={'flex items-center '}>
                                  <ChModal callback={handleNumberAvatar} title={'Edit phone number avatar'}
                                             trigger={iconModalTrigger} content={manageIconContent} secondaryLabel='Cancel'
                                             primaryLabel='Update' />

                              {/* </div> */}
                              <div className="w-full">

                                  <ChInput value={formData.msisdn.label}
                                           callback={(val: string) => {
                                               handleChange('label', val);
                                           }} error={errors.name}
                                           className='max-w-full py-3 pr-4 text-sm' />
                              </div>

                          </div>
                      </div>


                  </div>
              </div>


                    <div className={`flex flex-row  border-black border-b  ${isMobile ? ' my-6' : ' '}` + clsxMerge(borderStyles({theme}), boxStyles({theme}))}>


                        <div>

                            <div className='px-8 mb-8   text-lg border-r w-[344px] border-grey-75 h-full justify-between'>
                                <div>
                                    <div className='font-semibold pt-5'>
                                        Share access
                                    </div>
                                    <div className='pr-6 text-grey-500 text-sm py-5'>
                                        Give other members in your workspace access to this phone number
                                    </div>
                                </div>
                            <div>
                                    <ChModal callback={handleMenageAccessChange} title={'Share access'}
                                             trigger={menageAccessTrigger} content={modalContent} secondaryLabel='Cancel'
                                             primaryLabel='Confirm'/>
                                {(msisdnInfo.msisdn.assigmentScope === 'PRIVATE') && <div className={'flex gap-3 text-grey-500 text-sm'}>
                                    <div>
                                        This number is private.
                                    </div>
                                    <ChTooltip
                                        theme={'dark'}
                                        content={"Sharing is not currently \n enabled for this number."}
                                        trigger={
                                            <div>
                                                <Icon background={true} iconName='question_mark'
                                                      className={'bg-black text-white'} size={'xxs'}/>
                                            </div>
                                        }>
                                    </ChTooltip>
                                </div>}
                                </div>

                            </div>


                        </div>
                        <div className='min-h-[216px] h-[calc(100vh-500px-var(--header-height))] overflow-y-auto flex flex-col w-full'>


                            {Boolean(formData.users?.length) && <>
                                <div className={`p-6 pl-8 border-b  border-grey-75 `} >
                                    Users
                                </div>
                                <div>

                                    {(formData.users?.map((user, index) => {
                                        return (
                                            <div className={`border-b  border-grey-75` } >
                                                <div className='flex gap-3  items-center pl-8 py-4'    key={`user-${user.userId ?? index}`} >
                                                    <Avatar size="md" type='initials'
                                                            color={user.iconColor ?? Colors.orange}
                                                            name={user.firstName + ' ' + user.lastName}/>{user.firstName + ' ' + user.lastName}
                                                </div>
                                            </div>
                                        )
                                    }))
                                    }
                                </div>

                            </>}
                            {Boolean(formData.groups?.length) && <>
                                <div className={`p-6 pl-8 border-b   border-grey-75`} >
                                    Groups
                                </div>
                                <div>
                                    {formData.groups.map((group, index) => {
                                            return (
                                                <div key={`group-${group.id ?? index}`} className={`border-b  w-full  border-grey-75`}>
                                                    <div className='flex gap-3  items-center py-4 pl-8'>
                                                        <Avatar size="md" type='icon'
                                                                color={group?.iconColor ?? Colors.darkGrey}
                                                                icon='home'/>{group.name}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    )}

                                </div>
                            </>
                            }
                        </div>

                    </div>
                    <div className='mr-[384px] w-full'>
                        <div className="flex flex-row  border-black border-b ">
                            <div className='border-r border-grey-75 max-w-[344px] py-6'>
                                <div className='font-semibold px-8 text-lg'>
                                    Call  settings
                                </div>
                                <div className='text-grey-500 px-8 pb-6 text-sm'>
                                    Configure how your incoming and outgoing calls are recorded and stored.
                                </div>
                            </div>
                            {/*    <div className='mx-6 py-6 border-b border-gray-900'>*/}
                            {/*        <Switch size='lg'*/}
                            {/*                checked={formData.emailsSetting.enabled}*/}
                            {/*                callback={handleEmailNotificationChange}*/}
                            {/*                label={'Missed call'}*/}
                            {/*                description={'Set where you want to receive notifications about missed calls'}/>*/}
                            {/*        { formData.emailsSetting.enabled &&*/}
                            {/*            <div className='flex gap-3 mx-6 py-6 '>*/}
                            {/*                <ChInput value={formData.emailsSetting.email ?? undefined}*/}
                            {/*                         callback={(val: string) => handleEmailChange(val)}*/}
                            {/*                         error={errors.email}*/}
                            {/*                         className='max-w-full' label='Mails'/>*/}

                            {/*            </div>}*/}
                            {/*    </div>*/}
                            <div className='p-6 grid w-full'>
                                <Switch size='lg'
                                        checked={formData.callRecordingEnabled}
                                        callback={handleCallRecordingChange}
                                        label={'Call recording'}
                                        description={'With this option on, every call you take and make will be recorded and stored accordingly to your subscription.'}/>
                            </div>
                        </div>
                    </div>
                    <div className={`flex justify-end`}>
                        <div className='flex gap-3 justify-end m-8'>
                            <Link to={'/phoneNumbers'}><Button buttonType='secondary' size='large'
                                                               label={'Cancel'}></Button></Link>
                            <Button size='large' onClick={(e) => handleSubmit(e)} label={'Done'}></Button>
                        </div>
                    </div>
                </div>
            </div>
        }

        </>
    )
}

import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../baseQuery';

export interface GoogleUser {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    avatarUrl: string;
}

export interface GoogleIntegrationResponse {
    googleResponse: {
        users: GoogleUser[];
    };
    alreadyExists: string[];
    alreadySynced: string[];
    integrationId: number;
}
export interface ContactsImportConfig {
    id: number;
    type: "CONTACTS_IMPORT";
    platform: "PIPEDRIVE";
    details: {
        type: string;
        companyName: string;
        companyId: string;
    };
    configuration: {
        type: string;
        sendRecordings: boolean;
        sendCallSummary: boolean;
        sendMissedCalls: boolean;
        defaultNumbersPrefix: string;
        sendCallTranscription: boolean;
    };
}
export const integrationApi = createApi({
    reducerPath: 'integrationApi',
    tagTypes: ['Integration', 'Settings'],
    baseQuery,
    endpoints: (builder) => ({
        startIntegrationGoogle: builder.mutation<GoogleIntegrationResponse, { email: string }>({
            query: ({ email }) => ({
                url: '/api/v1/integrations/google',
                method: 'POST',

                body: { email },
            }),
            invalidatesTags: ['Integration']
        }),
        startIntegrationPipedrive: builder.mutation<GoogleIntegrationResponse, { code: string }>({
            query: ({ code }) => ({
                url: '/api/v1/integrations/pipedrive',
                method: 'POST',
                body: { code },
            }),
            invalidatesTags: ['Integration']
        }),

        importGoogleContacts: builder.mutation<any, { integration: number; users: GoogleUser[] }>({
            query: ({ integration, users }) => ({
                url: `/api/v1/integrations/google/${integration}/users`,
                method: 'POST',
                body:{ integration, users },
            }),
        }),
        deleteGoogleIntegration: builder.mutation<any, { integrationId: number }>({
            query: ({ integrationId }) => ({
                url: `/api/v1/integrations/google/${integrationId}`,
                method: 'DELETE',
            }),
            invalidatesTags:['Integration']
        }),
        deleteIntegration: builder.mutation<any, { integrationId: number }>({
            query: ({ integrationId }) => ({
                url: `/api/v1/integrations/${integrationId}`,
                method: 'DELETE',
            }),
            invalidatesTags:['Integration', 'Settings']
        }),
        getIntegrations: builder.query<any, void>({
            query: () => ({
                url: `/api/v1/integrations/`,
                method: 'GET',
            }),
            providesTags: ['Integration']
        }),
        getCurrentGoogleIntegration: builder.query<any, void>({
            query: () => ({
                url: `/api/v1/integrations/google/current`,
                method: 'GET',
            }),
        }),
        getCurrentGoogleIntegrationUsers: builder.query<any, {integrationId: number}>({
            query: ({integrationId}) => ({
                url: `/api/v1/integrations/google/${integrationId}/users`,
                method: 'GET',
            }),
        }),
        getPipedriveSettings: builder.query<any, {integrationId: number}>({
            query: ({integrationId}) => ({
                url: `api/v1/integrations/${integrationId}`,
                method: 'GET',
            }),
            providesTags: ['Settings']
        }),
        statusGoogleIntegration: builder.mutation<any, {integrationId: number, emails: string[], importId: number | null}>({
            query: ({integrationId, emails, importId}) => ({
                url: `/api/v1/integrations/google/${integrationId}/status`,
                method: 'POST',
                params: {importId},
                body: {emails}
            }),
        }),
        patchGoogleIntegration: builder.mutation<any, {integrationId: number, emails: string[]}>({
            query: ({integrationId, emails}) => ({
                url: `/api/v1/integrations/google/${integrationId}/patch`,
                method: 'PATCH',
                body: {emails}
            }),
        }),
        patchPipedriveIntegration: builder.mutation<any, {integrationId: number, body: ContactsImportConfig }>({
            query: ({integrationId, body}) => ({
                url: `/api/v1/integrations/${integrationId}`,
                method: 'PATCH',
                body: body
            }),
            invalidatesTags: ['Settings']
        }),
    }),
});

export const {
    useGetIntegrationsQuery,
    useGetCurrentGoogleIntegrationUsersQuery,
    useGetCurrentGoogleIntegrationQuery,
    useStartIntegrationGoogleMutation,
    useStartIntegrationPipedriveMutation,
    useImportGoogleContactsMutation,
    useDeleteGoogleIntegrationMutation,
    useDeleteIntegrationMutation,
    useStatusGoogleIntegrationMutation,
    useGetPipedriveSettingsQuery,
    usePatchPipedriveIntegrationMutation,

} = integrationApi;
